import {ChangeDetectionStrategy, Component, ElementRef, Input} from '@angular/core';
import {SafeUrl} from '@angular/platform-browser';
import {ModalSize, OverlayService} from '@aztrix/components/overlay';

import {ImagePreviewComponent} from './image-preview/image-preview.component';

@Component({
  selector: 'ax-image-preview-button',
  templateUrl: './image-preview-button.component.html',
  styleUrls: ['./image-preview-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagePreviewButtonComponent {
  @Input() source: string | SafeUrl | null;
  @Input() name?: string;

  constructor(
    private _overlay: OverlayService,
    private _elementRef: ElementRef
  ) {}

  preview(): void {
    this._overlay.createModal(this._elementRef, ImagePreviewComponent, {
      title: 'label.preview',
      modalSize: ModalSize.SMALL,
      init: (modal) => {
        modal.source = this.source;
        modal.name = this.name;
      },
    });
  }
}
