import {ChangeDetectionStrategy, Component} from '@angular/core';

import {AbstractPropertyValueViewComponent} from '../abstract-property-value-view.component';

@Component({
  selector: 'ax-default-value-view',
  templateUrl: 'default-value-view.component.html',
  styleUrls: ['../abstract-property-value-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultValueViewComponent extends AbstractPropertyValueViewComponent {}
