import {Component, Input} from '@angular/core';
import {FormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'ax-date-range-select',
  templateUrl: 'date-range-select.component.html',
  styleUrls: ['date-range-select.component.scss'],
})
export class DateRangeSelectComponent {
  @Input() group: UntypedFormGroup;

  today = new Date();

  get dateFrom(): FormControl<Date | undefined> {
    return <FormControl<Date | undefined>>this.group.get('from');
  }

  get dateTo(): FormControl<Date | undefined> {
    return <FormControl<Date | undefined>>this.group.get('to');
  }
}
