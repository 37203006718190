import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {ReplaySubject} from 'rxjs';
import {mergeMap, shareReplay, startWith} from 'rxjs/operators';

import {ValueEdit} from '../../value-edit';

@Component({
  selector: 'ax-boolean-edit',
  templateUrl: './boolean-edit.component.html',
  styleUrls: ['boolean-edit.component.scss', '../../../property-edit/show-icon.scss'],
})
export class BooleanEditComponent extends ValueEdit implements OnInit, OnChanges {
  checked = false;

  protected _form$ = new ReplaySubject<UntypedFormControl>(1);
  protected _formValueChanges$ = this._form$.pipe(
    mergeMap((form) => form.valueChanges.pipe(startWith(form.value))),
    shareReplay(1)
  );

  ngOnInit() {
    this._subscriptions.add(
      this._formValueChanges$.subscribe((value: string) => {
        this.checked = value === 'true';
      })
    );
  }

  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.form && this.form) {
      this._form$.next(this.form);
    }
  }

  changed(event: MatSlideToggleChange) {
    this.form.setValue(event.checked ? 'true' : 'false');
  }
}
