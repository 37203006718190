<ax-icon *ngIf="icon" class="icon type-icon" [name]="icon"></ax-icon>
<mat-form-field
  class="c-input"
  [ngClass]="{'remove-subtext': !errorsTemplate && !hint, readonly: readonly}"
  appearance="outline"
  [floatLabel]="prefix ? 'always' : floatLabel"
>
  <mat-label>{{ label }}</mat-label>
  <div class="input-field">
    <span *ngIf="prefix" class="custom-prefix">
      {{ prefix }}
    </span>
    <input
      matInput
      axAutofocus
      [attr.inputmode]="inputType === 'number' && !numberConfig ? 'numeric' : undefined"
      [attr.pattern]="inputType === 'number' && !numberConfig ? '[0-9]*' : undefined"
      [name]="getName()"
      [type]="inputType"
      [min]="numberConfig?.min"
      [max]="numberConfig?.max"
      [step]="numberConfig?.step"
      [formControl]="form"
      [autofocus]="autofocus"
      [placeholder]="placeholder"
      [autocomplete]="
        matAutocomplete?.options?.length
          ? 'no-autocomplete'
          : autocompleteAttr || defaultAutocompleteAttr
      "
      [matAutocomplete]="autocomplete"
      [matAutocompleteDisabled]="!matAutocomplete"
      [required]="required"
      [readonly]="readonly"
      (focus)="focused.emit()"
    />
    <span *ngIf="postfix" class="custom-prefix">
      {{ postfix }}
    </span>
  </div>

  <mat-hint
    [ngClass]="{collapsed}"
    (click)="collapsed = !collapsed"
    class="hint collapsible"
    align="end"
    #hintElement
  >
    <div><ax-icon *ngIf="isOverflowing()" class="icon" name="chevron-down"></ax-icon></div>
    <span [ngClass]="{overflowing: isOverflowing()}">
      {{ hint }}
    </span>
  </mat-hint>
  <span class="suffix" matSuffix>
    <ax-clear-button
      [form]="form"
      *ngIf="hasValue && !form.disabled && !readonly"
    ></ax-clear-button>
    <ng-content></ng-content>
  </span>

  <mat-error *ngIf="errorsTemplate">
    <ng-template [ngTemplateOutlet]="errorsTemplate"></ng-template>
  </mat-error>
</mat-form-field>
