import {Component, HostBinding} from '@angular/core';

import {AbstractPropertyEdit} from '../abstract-property-edit';

export const ORGANISATION_TYPES = ['professional', 'company', 'non-profit'];

@Component({
  selector: 'ax-organisation-type-edit',
  templateUrl: './organisation-type-edit.component.html',
  styleUrls: ['./organisation-type-edit.component.scss', '../show-icon.scss'],
})
export class OrganisationTypeEditComponent extends AbstractPropertyEdit {
  types = ORGANISATION_TYPES;

  @HostBinding('class.showIcon') get showIconClass() {
    return this.showIcon;
  }
}
