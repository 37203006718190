import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AvatarModule} from '@aztrix/components/avatar';
import {TranslatePipe} from '@aztrix/translate';

import {RowCellViewModule} from '../row-cell-view.module';
import {ProposalCellViewComponent} from './proposal-cell-view.component';
import {HelperPipesModule} from '@aztrix/helpers';

@NgModule({
  declarations: [ProposalCellViewComponent],
  imports: [CommonModule, AvatarModule, HelperPipesModule, TranslatePipe, RowCellViewModule],
  exports: [ProposalCellViewComponent],
})
export class ProposalCellViewModule {}
