<ax-default-edit
  [name]="name"
  [form]="form"
  [hint]="hint"
  [autofocus]="autofocus"
  [required]="required"
  [showIcon]="showIcon"
></ax-default-edit>
<mat-hint class="hint" *ngIf="form.valid || form.untouched" align="end">{{ hint }}</mat-hint>
<ax-property-error *ngIf="form.touched" [form]="form"></ax-property-error>
