import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ButtonModule} from '@aztrix/components/button';
import {OpeningHoursViewModule} from '@aztrix/components/property-view';
import {TranslatePipe} from '@aztrix/translate';

import {DateInputComponent} from './date-input/date-input.component';
import {DateRangeSelectComponent} from './date-range-select/date-range-select.component';
import {DatesDaysSelectComponent} from './dates-days-select.component';
import {DaysSelectComponent} from './days-select/days-select.component';
import {HelperPipesModule} from '@aztrix/helpers';

@NgModule({
  declarations: [
    DatesDaysSelectComponent,
    DateRangeSelectComponent,
    DaysSelectComponent,
    DateInputComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    ButtonModule,
    HelperPipesModule,
    TranslatePipe,
    OpeningHoursViewModule,
  ],
  exports: [DatesDaysSelectComponent],
})
export class DatesDaysSelectModule {}
