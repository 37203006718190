<ax-icon *ngIf="icon" class="icon type-icon" [name]="icon"></ax-icon>
<mat-form-field
  class="c-input"
  [ngClass]="{'remove-subtext': !errorsTemplate && !hint, readonly: readonly}"
  appearance="outline"
  [floatLabel]="floatLabel"
>
  <mat-label>{{ label }}</mat-label>
  <input
    [name]="getName()"
    (click)="!readonly ? datePicker.open() : null"
    matInput
    [matDatepicker]="datePicker"
    [min]="minDate"
    [max]="maxDate"
    axAutofocus
    [autofocus]="autofocus"
    [formControl]="form"
    [required]="required"
    readonly
    (focus)="focused.emit()"
  />
  <div class="suffix" matSuffix>
    <ax-clear-button
      *ngIf="hasValue && !form.disabled && !readonly"
      [form]="form"
    ></ax-clear-button>
    <mat-datepicker-toggle [for]="datePicker"></mat-datepicker-toggle>
  </div>
  <mat-datepicker #datePicker [startView]="startView"></mat-datepicker>
  <mat-hint
    [ngClass]="{collapsed}"
    (click)="collapsed = !collapsed"
    class="hint collapsible"
    align="end"
    #hintElement
  >
    <div><ax-icon *ngIf="isOverflowing()" class="icon" name="chevron-down"></ax-icon></div>
    <span [ngClass]="{overflowing: isOverflowing()}">{{ hint }}</span>
  </mat-hint>
  <ng-content matSuffix></ng-content>
  <mat-error *ngIf="errorsTemplate">
    <ng-template [ngTemplateOutlet]="errorsTemplate"></ng-template>
  </mat-error>
</mat-form-field>
