import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {ValueVerifiedViewComponent} from './value-verified-view.component';
import {HelperPipesModule} from '@aztrix/helpers';

@NgModule({
  declarations: [ValueVerifiedViewComponent],
  imports: [CommonModule, HelperPipesModule, IconsModule, TranslatePipe, MatTooltipModule],
  exports: [ValueVerifiedViewComponent],
})
export class ValueVerifiedViewModule {}
