<div class="ax-modal-body-content">
  <div class="input-spacer">
    <div class="c-search__input-wrapper">
      <ax-icon class="icon c-search__icon" name="magnify"></ax-icon>
      <input
        type="text"
        class="c-search__input"
        [formControl]="countryForm"
        [placeholder]="'search.placeholder.country' | translate"
        axAutofocus
        [autofocus]="true"
      />
    </div>
  </div>

  <div class="result-country-wrapper">
    <!--reset to everywhere-->
    <div *ngIf="showReset" class="result-country" (click)="selectCountry()">
      <span class="result-flag">
        <ax-icon class="icon" [name]="'flags:un'"></ax-icon>
      </span>
      <span class="result-title">{{ 'label.everywhere' | translate }}</span>
    </div>
    <!--default country-->
    <ng-container *ngIf="showDefaultCountry && (defaultCountry$ | async) as defaultCountry">
      <div class="c-search__section-title">
        <span>{{ 'label.country.default' | translate | uppercase }}</span>
      </div>
      <div class="result-country" (click)="selectCountry(defaultCountry)">
        <span class="result-flag">
          <ax-icon class="icon" [name]="'flags:' + defaultCountry.countryCode"></ax-icon>
        </span>
        <span class="result-title">{{ defaultCountry.countryCode | countryCodeLabel }}</span>
        <span *ngIf="showPrefix && showMobile" class="c-search__result-prefix">{{
          defaultCountry.phonePrefix[0]
        }}</span>
        <span
          *ngIf="
            (showPrefixCountries.length &&
              showPrefixCountries.includes(defaultCountry.countryCode)) ||
            (showPrefix && !showMobile)
          "
          class="c-search__result-prefix"
          >{{ defaultCountry.countryCode }}</span
        >
      </div>
    </ng-container>

    <!--search results-->
    <ng-container *ngIf="query$ | async | countrySearch: false : countryCodes as searchResults">
      <ng-container *ngIf="searchResults.length">
        <div class="c-search__section-title">
          <span>{{ 'label.countries' | translate | uppercase }}</span>
        </div>
        <ng-container *ngFor="let searchResult of searchResults">
          <div class="result-country" (click)="selectCountry(searchResult)">
            <span class="result-flag">
              <ax-icon class="icon" [name]="'flags:' + searchResult.countryCode"></ax-icon>
            </span>
            <span class="result-title">{{ searchResult.countryCode | countryCodeLabel }}</span>
            <span *ngIf="showPrefix && showMobile" class="c-search__result-prefix">{{
              searchResult.phonePrefix[0]
            }}</span>
            <span
              *ngIf="
                (showPrefixCountries.length &&
                  showPrefixCountries.includes(searchResult.countryCode)) ||
                (showPrefix && !showMobile)
              "
              class="c-search__result-prefix"
              >{{ searchResult.countryCode }}</span
            >
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
