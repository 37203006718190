import {ChangeDetectionStrategy, Component, HostBinding} from '@angular/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import Quill from 'quill';

import {_AztrixQuillBase} from './quill-base';

// Font sizes
const size = Quill.import('attributors/style/size');
size.whitelist = ['8px', '9px', '10px', '12px', '14px', '16px', '20px', '24px', '32px'];
Quill.register(size, true);

// Indents
const indent = Quill.import('formats/indent');
indent.whitelist = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
  28, 29, 30, 31, 32,
];
Quill.register(indent, true);

const link = Quill.import('formats/link');
link.sanitize = (url: string) => {
  if (!/^https?:/.test(url)) {
    return 'http://' + url;
  } else {
    return url;
  }
};
Quill.register(link);

// Increasing integer for generating unique ids for mat-quill components.
let nextUniqueId = 0;

const SELECTOR = 'aztrix-quill';

/* eslint-disable @angular-eslint/no-inputs-metadata-property */
@Component({
  selector: SELECTOR,
  exportAs: 'aztrixQuill',
  templateUrl: 'quill.component.html',
  styleUrls: ['quill.component.scss', 'quill-theme.scss'],
  providers: [{provide: MatFormFieldControl, useExisting: AztrixQuillComponent}],
  inputs: ['disabled', 'placeholder'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AztrixQuillComponent extends _AztrixQuillBase {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  // static ngAcceptInputType_disabled: boolean | string | null | undefined;
  // // eslint-disable-next-line @typescript-eslint/naming-convention
  // static ngAcceptInputType_required: boolean | string | null | undefined;

  controlType = SELECTOR;
  @HostBinding() id = `${SELECTOR}-${nextUniqueId++}`;
}
