<ax-avatar
  [source]="source"
  [type]="type"
  [round]="type === AvatarType.INDIVIDUAL || type === AvatarType.ALIST"
  [rounded]="type === AvatarType.GROUP"
  [icon]="icon"
  [iconColor]="iconColor"
  [badge]="badge"
  [badgeColor]="badgeColor"
  [border]="border"
></ax-avatar>

<span
  *ngIf="edit"
  class="avatar-edit-overlay__button"
  [ngClass]="[type | lowercase]"
  (click)="openEditModal()"
>
  <ax-icon class="icon" name="camera"></ax-icon>
</span>
