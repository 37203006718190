import {Component, Input} from '@angular/core';
import {Property} from '@aztrix/models';
import {PropertyRepresentation} from '@aztrix/sdk';

import {AbstractSelectSearch} from '../abstract-select-search';

@Component({
  selector: 'ax-property-select-search',
  templateUrl: './property-select-search.component.html',
  styleUrls: ['./property-select-search.component.scss'],
})
export class PropertySelectSearchComponent extends AbstractSelectSearch {
  @Input() properties: Property[] | PropertyRepresentation[];
  @Input() placeholder: string;
}
