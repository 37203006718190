<mat-autocomplete
  class="autocomplete"
  #autocompletor="matAutocomplete"
  (optionSelected)="selectedResult($event)"
>
  <ng-container *ngFor="let property of properties$ | async">
    <mat-option
      *ngIf="property | propertyDisplayValue"
      class="autocomplete-option"
      [value]="property"
    >
      <ax-icon class="icon" name="map-marker"></ax-icon>
      <span class="c-select-option__text">
        {{ property | propertyDisplayValue }}
      </span>
    </mat-option>
  </ng-container>

  <ng-container *ngIf="(results$ | async) && (loading$ | async) === false; else loading">
    <mat-optgroup *ngIf="(results$ | async)?.length" [label]="'label.search.results' | translate">
      <mat-option
        *ngFor="let result of results$ | async"
        class="autocomplete-option"
        [value]="result"
      >
        <ax-icon class="icon" name="map-marker"></ax-icon>
        <span class="c-select-option__text">
          {{ result | propertyDisplayValue }}
        </span>
      </mat-option>
    </mat-optgroup>
  </ng-container>

  <mat-option class="autocomplete-option" *ngIf="showManualOption$ | async">
    <ax-icon class="icon" name="plus"></ax-icon>
    <span class="c-select-option__text">
      {{ 'label.add.custom.address' | translate }}
    </span>
  </mat-option>
</mat-autocomplete>

<ng-template #loading>
  <ax-spinner></ax-spinner>
</ng-template>
