<mat-form-field class="c-input" appearance="outline" style="width: 100%">
  <mat-label>{{ label }}*</mat-label>
  <input
    (click)="datePicker.open()"
    matInput
    name="date"
    [formControl]="control"
    [matDatepicker]="datePicker"
    [min]="min"
    [max]="max"
    readonly
  />
  <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
  <mat-datepicker #datePicker startView="multi-year"></mat-datepicker>
</mat-form-field>
