<ax-default-edit
  [form]="legalIdForm"
  [name]="getName()"
  [hint]="hint"
  [autofocus]="autofocus"
  [required]="required"
  [matAutocomplete]="matAutocomplete"
  [autocompleteAttr]="autocompleteAttr"
  [autocomplete]="autocomplete"
  [showIcon]="showIcon"
  [showErrors]="showErrors"
  [readonly]="readonly"
  [customLabel]="customLabel === '' ? (form.getRawValue() | propertyTypeLabel) : customLabel"
>
  <button
    type="button"
    axButton
    icon-button
    [disabled]="legalIdForm.disabled"
    (click)="openSearchSelectCountryModal()"
  >
    <ax-icon class="icon" [name]="'flags:' + jurisdictionIcon"></ax-icon>
  </button>

  <ax-icon
    *ngIf="legalIdForm.enabled && vatState"
    class="icon"
    [ngClass]="vatState | lowercase"
    [name]="vatCheckIcon"
  ></ax-icon>
</ax-default-edit>
