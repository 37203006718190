<mat-form-field
  class="c-input"
  appearance="outline"
  [ngClass]="{'remove-subtext': !errorsTemplate}"
  [appearance]="appearance"
>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-select
    [formControl]="form"
    [placeholder]="placeholder"
    [multiple]="multiple"
    [disabled]="disabled"
  >
    <mat-select-trigger>
      <ng-container *ngIf="multiple">
        <ng-container *ngTemplateOutlet="chipsList"></ng-container>
      </ng-container>
      <ng-container *ngIf="!multiple">
        <ng-container
          *ngTemplateOutlet="
            selectedValueTemplate;
            context: {$implicit: form.value | optionByValue: searchedOptions : valueCallback}
          "
        ></ng-container>
      </ng-container>
    </mat-select-trigger>
    <mat-option *ngIf="(keys && keys.length) || searchCallback$">
      <ngx-mat-select-search
        [formControl]="formFilterControl"
        [placeholderLabel]="'label.search' | translate"
        [noEntriesFoundLabel]="'search.results.absence' | translate"
      >
        <ax-icon class="icon" name="close" ngxMatSelectSearchClear></ax-icon>
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngIf="!required" [value]="null" (click)="removeSelection()">
      <div class="option-wrapper">
        {{ 'label.none' | translate }}
      </div>
    </mat-option>
    <mat-option
      class="add-new"
      *ngIf="canAddNew"
      [disabled]="true"
      [value]="null"
      (click)="addNewValue.emit()"
    >
      <div class="option-wrapper">
        <ax-icon class="icon" name="plus"></ax-icon>{{ 'label.add' | translate }}
      </div>
    </mat-option>
    <mat-option
      *ngFor="let result of searchResults$ | async"
      [value]="result | valueByOption: valueCallback"
    >
      <div class="option-wrapper">
        <ng-container
          *ngTemplateOutlet="optionTemplate; context: {$implicit: result}"
        ></ng-container>
      </div>
    </mat-option>
  </mat-select>

  <button
    axButton
    icon-button
    matSuffix
    aria-label="Clear"
    *ngIf="form?.value && !disabled"
    (click)="clearValue()"
  >
    <ax-icon class="icon" name="close"></ax-icon>
  </button>

  <mat-error>
    <ng-container *ngTemplateOutlet="errorsTemplate"></ng-container>
  </mat-error>

  <span matPrefix>
    <ng-container *ngTemplateOutlet="prefixTemplate"></ng-container>
  </span>
</mat-form-field>

<ng-template #chipsList>
  <mat-chip-listbox>
    <mat-chip
      *ngFor="let value of form.value"
      [removable]="true"
      (removed)="removeSelection(value)"
    >
      <ng-container
        *ngTemplateOutlet="
          selectedValueTemplate;
          context: {$implicit: value | optionByValue: searchedOptions : valueCallback}
        "
      ></ng-container>
      <ax-icon matChipRemove class="icon" name="close"></ax-icon>
    </mat-chip>
  </mat-chip-listbox>
</ng-template>
