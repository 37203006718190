<div class="name-wrapper">
  <mat-form-field
    class="c-input name remove-subtext"
    [ngClass]="{readonly: readonly}"
    appearance="outline"
  >
    <mat-label
      >{{ customLabel === '' ? ('label.name' | translate) : customLabel
      }}<span *ngIf="required">&nbsp;*</span></mat-label
    >
    <input
      [name]="getName()"
      matInput
      autocomplete="no-autocomplete"
      [formControl]="nameForm"
      axAutofocus
      [autofocus]="!expanded && !!autofocus"
      (change)="$event.preventDefault()"
    />
  </mat-form-field>

  <mat-accordion>
    <mat-expansion-panel
      class="panel"
      (opened)="nameForm.disable()"
      (closed)="nameForm.enable()"
      [expanded]="expanded"
    >
      <mat-expansion-panel-header class="header" collapsedHeight="64.5px">
      </mat-expansion-panel-header>

      <ax-default-edit
        [name]="getName()"
        [form]="firstnameControl"
        [autofocus]="expanded && autofocus"
        [showErrors]="false"
        [readonly]="readonly"
        autocompleteAttr="given-name"
      ></ax-default-edit>
      <ax-default-edit
        [name]="getName()"
        [form]="middlenameControl"
        [showErrors]="false"
        [readonly]="readonly"
        autocompleteAttr="additional-name"
      ></ax-default-edit>
      <ax-default-edit
        [name]="getName()"
        [form]="lastnameControl"
        [readonly]="readonly"
        [showErrors]="false"
        autocompleteAttr="family-name"
      ></ax-default-edit>
    </mat-expansion-panel>
  </mat-accordion>
  <mat-hint class="hint" align="end" *ngIf="hintVisible">
    <span>{{ hint }}</span>
  </mat-hint>
  <ax-property-errors [controls]="controls"></ax-property-errors>
</div>
