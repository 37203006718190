<ng-template #option let-result>
  <div class="option-wrapper">
    <ax-icon class="icon" [name]="'flags:' + (result?.countryCode || 'world')"></ax-icon>
    <span>{{ result?.countryCode | countryCodeLabel }}</span>
  </div>
</ng-template>

<ax-select-search
  [form]="form"
  [options]="countryCodes$ | async"
  [optionTemplate]="option"
  [selectedValueTemplate]="option"
  [valueCallback]="valueCallback"
  [appearance]="appearance"
  [searchCallback$]="searchCallback$"
  [label]="label"
  [placeholder]="placeholder"
></ax-select-search>
