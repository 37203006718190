import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ClearButtonModule} from '@aztrix/components/clear-button';
import {IconsModule} from '@aztrix/icons';
import {AztrixQuillModule} from '@aztrix/quill';

import {RichTextEditComponent} from './rich-text-edit.component';
import {RichTextThemeComponent} from './rich-text-theme/rich-text-theme.component';

@NgModule({
  declarations: [RichTextEditComponent, RichTextThemeComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ClearButtonModule,
    MatAutocompleteModule,
    IconsModule,
    AztrixQuillModule,
  ],
  exports: [RichTextEditComponent],
})
export class RichTextEditModule {}
