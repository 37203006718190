<div [ngClass]="{'has-verify-icon': showVerificationLabel}">
  <ng-content></ng-content>
  <ax-icon
    [inline]="true"
    *ngIf="showVerificationLabel && (property | propertyIsVerified)"
    [ngClass]="verificationColor()"
    class="icon"
    name="custom:verified"
    [matTooltip]="parentProperty || property | propertyVerificationLabel"
  ></ax-icon>
</div>
