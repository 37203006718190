import {Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({selector: '[axAutofocus]'})
export class AutofocusDirective implements OnInit {
  private _focus = true;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    if (this._focus) {
      window.setTimeout(() => {
        this.el.nativeElement.focus();
      });
    }
  }

  @Input()
  set autofocus(condition: boolean) {
    this._focus = condition;
  }
}
