import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CategorySelectSearchModule} from '@aztrix/components/select-search';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {CategoryEditComponent} from '..';
import {HelperPipesModule} from '@aztrix/helpers';

@NgModule({
  declarations: [CategoryEditComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HelperPipesModule,
    TranslatePipe,
    IconsModule,
    CategorySelectSearchModule,
  ],
  exports: [CategoryEditComponent],
})
export class CategoryEditModule {}
