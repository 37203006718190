import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatomoService, propertyCommunicationType} from '@aztrix/helpers';

import {AbstractPropertyValueViewComponent} from '../abstract-property-value-view.component';

@Component({
  selector: 'ax-communication-value-view',
  templateUrl: 'communication-value-view.component.html',
  styleUrls: [
    '../abstract-property-value-view.component.scss',
    './communication-value-view.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunicationValueViewComponent extends AbstractPropertyValueViewComponent {
  constructor(private _matomo: MatomoService) {
    super();
  }

  trackClick() {
    this._matomo.trackEvent(
      'Communication value',
      'click',
      propertyCommunicationType(this.property)?.toLowerCase()
    );
  }
}
