import {FocusMonitor} from '@angular/cdk/a11y';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {MatFormFieldControl} from '@angular/material/form-field';
import {FormControl} from '@ngneat/reactive-forms';
import {format, isValid, parse} from 'date-fns';
import {Subject} from 'rxjs';

@Component({
  selector: 'ax-time-input',
  templateUrl: 'time-input.component.html',
  styleUrls: ['time-input.component.scss'],
  providers: [{provide: MatFormFieldControl, useExisting: TimeInputComponent}],
})
export class TimeInputComponent
  implements ControlValueAccessor, MatFormFieldControl<Date>, OnDestroy, OnInit
{
  static nextId = 0;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static ngAcceptInputType_required: boolean | string | null | undefined;

  @Input() name: string;

  private _placeholder: string;
  private _required = false;
  private _disabled = false;

  time = new FormControl<string | null>('');

  stateChanges = new Subject<void>();
  focused = false;
  controlType = 'time-input';
  @HostBinding('id') id = `time-input-${TimeInputComponent.nextId++}`;
  describedBy = '';
  onChange: (_: unknown) => unknown;
  onTouched: () => void;

  get errorState(): boolean {
    return (this.ngControl.touched || true) && !!this.ngControl.errors;
  }

  get empty() {
    return !!this.time?.value;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);

    if (this._disabled) {
      this.time.disable();
    } else {
      this.time.enable();
    }

    this.stateChanges.next();
  }

  @Input()
  get value(): Date | null {
    if (!this.time?.value) {
      return null;
    }
    return parse(this.time?.value, 'HH:mm', new Date());
  }
  set value(value: Date | null) {
    if (value && isValid(value)) {
      this.time.setValue(format(value, 'HH:mm'));
      this.stateChanges.next();
    } else {
      this.time.setValue(null);
    }
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe((origin) => {
      if (this.focused && !origin && this.onTouched) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this._handleTimeInput();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((<Element>event.target).tagName.toLowerCase() !== 'input') {
      setTimeout(() => {
        this._elementRef.nativeElement.querySelector('input')?.focus();
      });
    }
  }

  writeValue(time: Date | null): void {
    this.value = time;
  }

  registerOnChange(fn: (_: unknown) => unknown): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleTimeInput(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  isSafari() {
    const userAgrent = navigator.userAgent.toLocaleLowerCase();
    return userAgrent.includes('safari') && !userAgrent.includes('chrome');
  }
}
