import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ImageSourceModule} from '@aztrix/components/image-source';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {RowCellViewModule} from '../row-cell-view.module';
import {PageCellViewComponent} from './page-cell-view.component';
import {HelperPipesModule} from '@aztrix/helpers';

@NgModule({
  declarations: [PageCellViewComponent],
  imports: [
    CommonModule,
    HelperPipesModule,
    TranslatePipe,
    RowCellViewModule,
    ImageSourceModule,
    IconsModule,
  ],
  exports: [PageCellViewComponent],
})
export class PageCellViewModule {}
