import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {MatInput} from '@angular/material/input';
import {
  AddressFormats,
  AddressLineRepresentation,
  displayValue,
  displayValue$,
  hasValue,
  isProperty,
  PROPERTY_TYPE_REPS,
  PropertyFormControl,
  subTypeRepsForProperty,
} from '@aztrix/helpers';
import {LOCALE, TranslateService} from '@aztrix/translate';
import {AddressField, Locale, Property, PropertyType} from '@aztrix/models';
import {ControlsOf, FormArray, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {MediaQuery, ObserveResizeService} from 'angular-container-media-query';
import {BehaviorSubject, combineLatest, of, ReplaySubject, Subscription} from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  first,
  map,
  skip,
  startWith,
  switchMap,
} from 'rxjs/operators';

import {AbstractPropertyEdit} from '../abstract-property-edit';
import {AddressAutocompletionComponent} from '../autocompletion/address-autocompletion/address-autocompletion.component';
import {ZipAutocompletionComponent} from '../autocompletion/zip-autocompletion/zip-autocompletion.component';

@Component({
  selector: 'ax-address-edit',
  templateUrl: './address-edit.component.html',
  styleUrls: ['./address-edit.component.scss', '../show-icon.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressEditComponent
  extends AbstractPropertyEdit
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  @HostBinding('class.c-property-edit') propertyEditClass = true;
  @HostBinding('class.showIcon') get showIconClass() {
    return this.showIcon;
  }

  @MediaQuery('(max-width: 25rem)') @HostBinding('class.small') small = true;

  nonDummyPropertyTypes: PropertyType[];
  more = false;

  @ViewChild(AddressAutocompletionComponent)
  autocompletion: AddressAutocompletionComponent;

  @ViewChild(ZipAutocompletionComponent)
  zipAutocompletion: ZipAutocompletionComponent;

  @ViewChildren(MatInput)
  inputs: QueryList<MatInput>;

  custom$ = new BehaviorSubject<boolean>(false);

  addressControl = new FormControl<string>();

  streetControl = PropertyFormControl.createByType(PropertyType.STREET, undefined, undefined, true);
  localityControl = PropertyFormControl.createByType(
    PropertyType.LOCALITY,
    undefined,
    undefined,
    true
  );

  private _form$ = new ReplaySubject<FormGroup<ControlsOf<Property>>>(1);
  private _open$ = new BehaviorSubject(false);
  private _formDisabled$ = new BehaviorSubject(false);
  private _zipFocused$ = new ReplaySubject<void>(1);

  private _autocompleteSubscription = new Subscription();
  private _subscriptions = new Subscription();

  private _open = false;

  get open(): boolean {
    return this._open;
  }

  set open(open: boolean) {
    if (this._open !== open) {
      this._open = open;
      this._open$.next(this._open);
    }
  }

  private _property$ = this._form$.pipe(map((form) => form.value));

  private _propertyValueChanges$ = this._form$.pipe(
    switchMap((form) => form.valueChanges.pipe(startWith(form.value)))
  );

  private _propertyTypeValueChanges$ = this._form$.pipe(
    map((form) => form.get('type')),
    switchMap((form) => {
      if (!form) {
        return of(undefined);
      }
      return form.valueChanges.pipe(startWith(form?.value));
    })
  );

  private _propertiesControl$ = this._form$.pipe(
    map((form) => <FormArray<Property>>form?.get('properties'))
  );

  private _propertiesControlValueChanges$ = this._propertiesControl$.pipe(
    switchMap((form) => form.valueChanges.pipe(startWith(form.value)))
  );

  countryControl$ = this._propertiesControl$.pipe(
    map((propertiesControl) =>
      propertiesControl.controls.find((c) => c.get('type')?.value === PropertyType.COUNTRY)
    )
  );

  private _countryValueChanges$ = this.countryControl$.pipe(
    map((countryControl) => countryControl?.get('value')),
    switchMap((countryControl) => {
      if (!countryControl) {
        return of('BE');
      }
      return countryControl.valueChanges.pipe(startWith(countryControl?.value));
    }),
    distinctUntilChanged()
  );

  private _addressValueChanges$ = this.addressControl.valueChanges.pipe(
    startWith(this.addressControl.value),
    debounceTime(500),
    distinctUntilChanged()
  );

  private _addressLines$ = combineLatest([
    this._propertyTypeValueChanges$,
    this._countryValueChanges$,
  ]).pipe(
    map(([type, country]) => {
      return AddressFormats.addressFormatForPropertyType(type, country)
        .map((line: AddressLineRepresentation) =>
          line.map((lineInfo) => ({
            propertyType: lineInfo.propertyType,
            length: lineInfo.length || undefined,
          }))
        )
        .filter((line: AddressField[]) => line.length);
    })
  );

  private _dummyPropertyTypes$ = this._countryValueChanges$.pipe(
    map((country) => {
      if (country) {
        const propertyTypeReps = PROPERTY_TYPE_REPS.get(PropertyType.ADDRESS);
        return (
          propertyTypeReps?.subTypesPerCountryCode?.[country] ||
          propertyTypeReps?.subTypesPerCountryCode?.[''] ||
          []
        )
          .filter((rep) => rep.dummy)
          .map((rep) => {
            return {type: rep.name, required: rep.required};
          });
      } else {
        return [];
      }
    })
  );

  addressLineProperties$ = combineLatest([
    this._addressLines$,
    this._dummyPropertyTypes$,
    this.custom$.pipe(distinctUntilChanged()),
  ]).pipe(
    map(([addressLines, dummyPropertyTypes]) =>
      addressLines.map((addressLine) =>
        addressLine.map((addressProperty) => {
          const type = addressProperty.propertyType;
          const control = this._getPropertyControl(type);
          return {
            control,
            length: addressProperty.length,
            dummy: dummyPropertyTypes.find((t) => t.type === type),
          };
        })
      )
    )
  );

  moreIsRedundant$ = combineLatest([this._propertiesControlValueChanges$, this._property$]).pipe(
    map(([properties, property]: [Property[], Property]) => {
      const notDummyPropertyTypes = subTypeRepsForProperty(property)
        .filter((rep) => !rep.dummy)
        .map((rep) => rep.name);
      return properties
        .filter((p) => (p.type ? notDummyPropertyTypes.includes(p.type) : false))
        .every((p) => hasValue(p));
    })
  );

  constructor(
    private _translate: TranslateService,
    private _changeDetector: ChangeDetectorRef,
    elementRef: ElementRef,
    resize: ObserveResizeService,
    @Inject(LOCALE) private _locale$: BehaviorSubject<Locale>
  ) {
    super();
    resize.register(this, elementRef, this._changeDetector);
  }

  /* lifecycle hooks */

  override ngOnInit(): void {
    super.ngOnInit();

    this._subscriptions.add(
      this._propertyValueChanges$
        .pipe(
          distinctUntilChanged(
            (property1, property2) => JSON.stringify(property1) === JSON.stringify(property2)
          )
        )
        .subscribe((property) => {
          const emptyRequiredSubProperties = (property?.properties || [])
            .filter((subProperty) => this._prefillIsRequired(subProperty.type))
            .filter((subProperty) => !hasValue(subProperty));
          if (emptyRequiredSubProperties.length) {
            this.custom$.next(true);
          }

          if (property?.properties) {
            const streetProperty = property.properties.find((p) => p.type === PropertyType.STREET);
            if (streetProperty && property?.type === PropertyType.ADDRESS) {
              PropertyFormControl.setValue(this.streetControl, streetProperty);
            }
            const localityProperty = property.properties.find(
              (p) => p.type === PropertyType.LOCALITY
            );
            if (localityProperty) {
              PropertyFormControl.setValue(this.localityControl, localityProperty);
            }
          }
        })
    );

    this._subscriptions.add(
      this._propertyValueChanges$
        .pipe(switchMap((value) => displayValue$(this._translate, value, this._locale$.value)))
        .subscribe((value) => {
          if (value && value !== '') {
            this.addressControl.setValue(value, {emitEvent: false});
            this.open = true;
          }

          this._markAddressControlAsInvalid();
        })
    );

    this._subscriptions.add(
      this._propertyValueChanges$.subscribe(() => {
        this._changeDetector.markForCheck();
      })
    );

    this._subscriptions.add(
      combineLatest([
        this._formDisabled$.pipe(distinctUntilChanged()),
        this.countryControl$,
      ]).subscribe(([formDisabled, countryControl]) => {
        if (formDisabled) {
          this.addressControl.disable();
          countryControl?.disable();
        } else {
          this.addressControl.enable();
          countryControl?.enable();
        }
      })
    );

    this._subscriptions.add(
      combineLatest([this._formDisabled$.pipe(distinctUntilChanged()), this._open$])
        .pipe(
          skip(1), // skip 1, to ensure initially we do not focus the address field
          delay(0) // delay by one tick, to ensure focus is triggered after the autocomplete trigger
        )
        .subscribe(([formDisabled, open]) => {
          if (formDisabled) {
            return undefined;
          }
          if (open) {
            this.inputs.changes.pipe(first()).subscribe(() => {
              const activeInputs = this.inputs.filter((input) => !input.disabled);
              if (activeInputs.length) {
                activeInputs[0].focus();
              }
            });
          } else {
            if (this.inputs?.length) {
              this.inputs.first.focus();
            }
            this._markAddressControlAsInvalid();
          }
        })
    );

    this._subscriptions.add(
      this._form$.pipe(switchMap((form) => form.touch$)).subscribe((touched) => {
        if (touched) {
          this.addressControl.markAsTouched();
          this._changeDetector.markForCheck();
        }
      })
    );

    this._subscriptions.add(
      this.addressControl.touch$.subscribe(() => {
        this.form.markAllAsTouched();
        for (const control of this.propertiesControl.controls || []) {
          control.get('value')?.updateValueAndValidity();
        }
      })
    );

    this._subscriptions.add(
      this.addressControl.valueChanges.subscribe((value: string | Property) => {
        if (typeof value !== 'string' && value && isProperty(value)) {
          this.addressControl.setValue(displayValue(this._translate, value));
        }
      })
    );

    this._subscriptions.add(
      this._zipFocused$
        .pipe(
          map(() => displayValue(this._translate, <Property>this.form.value)),
          distinctUntilChanged()
        )
        .subscribe((query) => {
          this.zipAutocompletion.autocomplete({
            query,
            country: this.countryControl?.value.value,
          });
        })
    );
  }

  override ngOnChanges(changes: SimpleChanges) {
    if (changes.form && this.form) {
      this._form$.next(<FormGroup<ControlsOf<Property>>>this.form);
    }

    this.registerOnDisabledChange((isDisabled: boolean) => {
      this._formDisabled$.next(isDisabled);
    });

    super.ngOnChanges(changes);
  }

  ngAfterViewInit() {
    this._autocompleteSubscription = combineLatest([
      this._addressValueChanges$,
      this._countryValueChanges$,
    ])
      .pipe(distinctUntilChanged())
      .subscribe(([query, country]) => {
        this.autocompletion.autocomplete({query, country});
      });
  }

  override ngOnDestroy() {
    this._subscriptions.unsubscribe();
    this._autocompleteSubscription.unsubscribe();
    super.ngOnDestroy();
  }

  /* getters */

  get propertyControls(): AbstractControl[] {
    return this.propertiesControl.controls;
  }

  get countryControl(): AbstractControl {
    return <AbstractControl>(
      this.propertyControls.find((c) => c.get('type')?.value === PropertyType.COUNTRY)
    );
  }

  get hintVisible() {
    return !this.propertyControls.some((control) => control.touched && control.invalid);
  }

  get showManualAddressOption() {
    return (<string>this.addressControl.value)?.length > 1;
  }

  bootstrapClass(field: any): string {
    return field.length ? `field-length-${field.length}` : 'field-length-grow';
  }

  clearValue(event?: MouseEvent) {
    event?.stopPropagation(); // to prevent the click event from canceling the autocomplete
    for (const propertyControl of this.propertyControls) {
      if (propertyControl.get('type')?.value !== PropertyType.COUNTRY) {
        propertyControl.get('value')?.reset(undefined, {emitEvent: false});
      }
    }
    this.addressControl.reset();
    this.form.updateValueAndValidity();
    this.inputs.first.focus();
  }

  addressLabelByType(type?: PropertyType): string {
    if (this.countryControl.value.value === 'NL' && type === PropertyType.BOX) {
      return `property.${type}.alt.label`;
    } else {
      return `property.${type}.label`;
    }
  }

  addressAutocompleteLabelByType(type?: PropertyType): string {
    switch (type) {
      case PropertyType.STREET:
        return 'address-line1';
      case PropertyType.NUMBER:
        return 'address-line2';
      case PropertyType.BOX:
        return 'address-line3';
      case PropertyType.ZIP:
        return 'postal-code';
      case PropertyType.LOCALITY:
        return 'address-level2';
      default:
        return (type || '').toLocaleLowerCase();
    }
  }

  /* events */

  onBlur() {
    this._markAddressControlAsInvalid();
  }

  autocompleteAddress(property: Property): void {
    if (property) {
      PropertyFormControl.setValue(this.form, property);
      this.form.markAsDirty();
      this.propertiesControl.markAllAsTouched();
    }
    this.open = true;
    this.custom$.next(!property);
  }

  focused(control: FormGroup<any>) {
    if (control?.value?.type === PropertyType.ZIP) {
      this._zipFocused$.next();
    }
  }

  /* private functions */

  private _markAddressControlAsInvalid() {
    if (this.form.invalid) {
      this.addressControl.setErrors({incorrect: true});
    } else {
      this.addressControl.setErrors(null);
      this.form.markAsDirty();
    }
  }

  private _getPropertyControl(type: PropertyType): FormGroup<ControlsOf<Property>> {
    const control = this.getPropertyControl(type);
    if (this.custom$.value) {
      return control;
    }

    switch (type) {
      case PropertyType.STREET:
        return <FormGroup<ControlsOf<Property>>>this.streetControl;
      case PropertyType.LOCALITY:
        return <FormGroup<ControlsOf<Property>>>this.localityControl;
      default:
        return control;
    }
  }

  private _prefillIsRequired(type: PropertyType | undefined): boolean {
    return type ? [PropertyType.STREET, PropertyType.LOCALITY].includes(type) : false;
  }
}
