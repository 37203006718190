import {Component, Input} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'ax-clear-button',
  templateUrl: './clear-button.component.html',
  styleUrls: ['./clear-button.component.scss'],
})
export class ClearButtonComponent {
  @Input() form: UntypedFormControl;

  clearValue() {
    this.form.setValue('');
    this.form.markAsDirty();
  }
}
