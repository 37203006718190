<ax-icon *ngIf="icon" class="icon type-icon" [name]="icon"></ax-icon>

<mat-form-field
  class="c-input"
  [ngClass]="{'remove-subtext': !errorsTemplate && !hint, readonly: readonly}"
  appearance="outline"
  [floatLabel]="floatLabel"
>
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    axAutofocus
    [name]="getName()"
    [autofocus]="autofocus"
    [autocomplete]="
      matAutocomplete?.options?.length ? 'no-autocomplete' : autocompleteAttr || 'tel'
    "
    type="tel"
    [formControl]="form"
    [placeholder]="placeholder"
    [matAutocomplete]="autocomplete"
    [matAutocompleteDisabled]="!matAutocomplete"
    [required]="required"
    [readonly]="readonly"
    #MatAutocompleteTrigger
    (focus)="focused.emit()"
  />
  <mat-hint
    [ngClass]="{collapsed}"
    (click)="collapsed = !collapsed"
    class="hint collapsible"
    align="end"
    #hintElement
  >
    <div><ax-icon *ngIf="isOverflowing()" class="icon" name="chevron-down"></ax-icon></div>
    <span [ngClass]="{overflowing: isOverflowing()}">{{ hint }}</span>
  </mat-hint>
  <ax-clear-button
    matSuffix
    [form]="form"
    *ngIf="hasValue && !form.disabled && !readonly"
  ></ax-clear-button>
  <ng-content matSuffix></ng-content>
  <button
    type="button"
    axButton
    icon-button
    matSuffix
    [disabled]="form.disabled"
    (click)="openSearchSelectCountryModal()"
  >
    <ax-icon class="icon" [name]="'flags:' + countryCode"></ax-icon>
  </button>
  <mat-error *ngIf="errorsTemplate">
    <ng-template [ngTemplateOutlet]="errorsTemplate"></ng-template>
  </mat-error>
</mat-form-field>
