import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {UrlTransformerService} from '@aztrix/environment';

import {AvatarType} from '../avatar-type';
import {BadgeIconType} from '../badge-icon-type';

@Component({
  selector: 'ax-avatar',
  templateUrl: 'avatar.component.html',
  styleUrls: ['avatar.component.scss'],
})
export class AvatarComponent implements OnChanges {
  @Input() source: string | null | undefined = undefined;
  @Input() type? = AvatarType.INDIVIDUAL;

  @Input() icon: string;
  @Input() iconColor = 'avatar-bg';

  @Input() badge?: BadgeIconType | null;
  @Input() badgeColor?: string | null;

  @Input() border = true;
  @Input() round = true;
  @Input() rounded = false;

  fallbackIcon = this._fallbackIcon(this.type);
  _source: string | undefined;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  AvatarType = AvatarType;

  constructor(private _urlTransformer: UrlTransformerService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.source && this.source) {
      this._source = this._sourceAvatar(this.source);
    }

    if (!this.source) {
      this._source = undefined;
    }

    if (changes.source || changes.type) {
      this.fallbackIcon = this._fallbackIcon(this.type);
    }
  }

  private _fallbackIcon(type?: string): string {
    switch (type) {
      case AvatarType.ORGANISATION:
        return 'factory';
      case AvatarType.GROUP:
        return 'account-group';
      case AvatarType.PROPOSAL:
        return 'file-document-edit';
      case AvatarType.PAGE:
        return 'card-text';
      default:
        return 'account';
    }
  }

  private _sourceAvatar(source: string): string {
    return source.includes('data:image')
      ? source
      : this._urlTransformer.transformUrl(this._staticFilePath(source));
  }

  private _staticFilePath(source: string): string {
    return this.type === AvatarType.ALIST
      ? `staticFiles/alistFiles/${source}`
      : `staticFiles/avatars/${source}`;
  }
}
