import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DAYS_OF_WEEK} from '@aztrix/helpers';
import {getISODay} from 'date-fns';
import {Subscription} from 'rxjs';

@Component({
  selector: 'ax-dates-days-select',
  templateUrl: 'dates-days-select.component.html',
  styleUrls: ['dates-days-select.component.scss'],
})
export class DatesDaysSelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() dateRange: Date[];
  @Output() dateRangeChange = new EventEmitter<Date[]>();

  @Input() days: string[] = DAYS_OF_WEEK.slice();
  @Output() daysChange = new EventEmitter<string[]>();

  @Input() text = '';
  @Output() textChange = new EventEmitter<string>();

  @Input() open = true;
  @Output() openChange = new EventEmitter<boolean>();

  @Output() cancel = new EventEmitter<void>();
  @Output() create = new EventEmitter<void>();
  @Output() edit = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();

  editMode = false;

  choice = 'specific';

  dateRangeGroup = new UntypedFormGroup({
    from: new UntypedFormControl(null, Validators.required),
    to: new UntypedFormControl(null, Validators.required),
  });

  dateControl = new UntypedFormControl(null, Validators.required);
  today = new Date();

  textForm = new UntypedFormControl('');

  openControl = new UntypedFormControl({value: true});

  subscriptions = new Subscription();

  ngOnInit() {
    this.subscriptions.add(
      this.dateControl.valueChanges.subscribe((value) => {
        this.dateRange = [value];
        this.dateRangeChange.emit(this.dateRange);
        const index = getISODay(value);
        this.days = [DAYS_OF_WEEK[index - 1]];
        this.daysChange.emit(this.days);
      })
    );

    this.subscriptions.add(
      this.dateRangeGroup.valueChanges.subscribe(({from, to}) => {
        this.dateRange = [from, to];
        this.dateRangeChange.emit(this.dateRange);
      })
    );

    this.subscriptions.add(
      this.textForm.valueChanges.subscribe((text) => {
        this.text = text;
        this.textChange.emit(this.text);
      })
    );

    this.subscriptions.add(
      this.openControl.valueChanges.subscribe((open) => {
        this.open = open;
        this.openChange.emit(this.open);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dateRange && this.dateRange) {
      if (this.dateRange.length === 1) {
        this.choice = 'specific';
        this.dateControl.setValue(this.dateRange[0], {emitEvent: false});
        const index = getISODay(this.dateRange[0]);
        this.days = [DAYS_OF_WEEK[index - 1]];
      } else {
        this.choice = 'range';
        this.dateRangeGroup.setValue(
          {from: this.dateRange[0], to: this.dateRange[1]},
          {emitEvent: false}
        );
      }
    }

    if (changes.text && this.text) {
      this.textForm.setValue(this.text, {emitEvent: false});
    }

    if (changes.open) {
      this.openControl.setValue(this.open, {emitEvent: false});
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  _create() {
    if (this.invalid) {
      this.markAllTouched();
    } else {
      this.create.emit();
    }
  }

  _edit() {
    if (this.invalid) {
      this.markAllTouched();
    } else {
      this.edit.emit();
    }
  }

  get invalid() {
    if (this.choice === 'range') {
      return this.dateRangeGroup.invalid;
    } else {
      return this.dateControl.invalid;
    }
  }

  markAllTouched() {
    this.dateRangeGroup.markAllAsTouched();
    this.dateControl.markAllAsTouched();
  }
}
