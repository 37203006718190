<ng-template [ngIf]="!(property | propertyHasValue)" [ngIfElse]="ready">
  <ax-default-value-view
    [property]="property"
    [showVerificationLabel]="false"
    [clickable]="false"
  ></ax-default-value-view>
</ng-template>

<ng-template #ready>
  <ng-container [ngSwitch]="true">
    <ax-avatar
      *ngSwitchCase="property | isOfType: PropertyType.AVATAR"
      [type]="isIndividual ? AvatarType.INDIVIDUAL : AvatarType.ORGANISATION"
      [source]="property?.value"
      [round]="isIndividual"
    ></ax-avatar>
    <ax-bankaccount-value-view
      *ngSwitchCase="property | isOfType: PropertyType.BANK_ACCOUNT"
      [property]="property"
      [showVerificationLabel]="showVerificationLabel"
      [clickable]="clickable"
    ></ax-bankaccount-value-view>
    <ax-legalid-value-view
      *ngSwitchCase="property | isOfType: PropertyType.LEGAL_ID"
      [property]="property"
      [showVerificationLabel]="showVerificationLabel"
      [clickable]="clickable"
    ></ax-legalid-value-view>
    <ax-social-value-view
      *ngSwitchCase="property | propertyIsSocialRelated"
      [property]="property"
      [showVerificationLabel]="showVerificationLabel"
      [clickable]="clickable"
      [params]="params"
    ></ax-social-value-view>
    <ax-opening-hours-view
      *ngSwitchCase="property | isOfType: PropertyType.OPENING_HOURS"
      [property]="property"
      [showVerificationLabel]="showVerificationLabel"
      [clickable]="clickable"
    ></ax-opening-hours-view>
    <ax-image-value-view
      *ngSwitchCase="property | isOfType: PropertyType.IMAGE"
      [property]="property"
      [showVerificationLabel]="showVerificationLabel"
      [clickable]="clickable"
    ></ax-image-value-view>
    <ax-file-value-view
      *ngSwitchCase="property | isOfType: PropertyType.FILE"
      [property]="property"
      [showVerificationLabel]="showVerificationLabel"
      [clickable]="clickable"
    ></ax-file-value-view>
    <ax-document-value-view
      *ngSwitchCase="property | propertyIsDocumentRelated"
      [property]="property"
      [showVerificationLabel]="showVerificationLabel"
      [clickable]="clickable"
    ></ax-document-value-view>
    <ax-communication-value-view
      *ngSwitchCase="
        (property | propertyIsCommunicationRelated) && !(property | propertyIsSocialRelated)
      "
      [property]="property"
      [showVerificationLabel]="showVerificationLabel"
      [clickable]="clickable"
      [params]="params"
    ></ax-communication-value-view>
    <ax-category-value-view
      *ngSwitchCase="property | isOfType: PropertyType.CATEGORY"
      [property]="property"
      [showVerificationLabel]="showVerificationLabel"
      [clickable]="clickable"
    ></ax-category-value-view>
    <ax-default-value-view
      *ngSwitchDefault
      [property]="property"
      [showVerificationLabel]="showVerificationLabel"
      [clickable]="clickable"
    ></ax-default-value-view>
  </ng-container>
</ng-template>
