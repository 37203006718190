import {NgModule} from '@angular/core';
import {GroupCellViewModule} from '@aztrix/components/row-cell-view';
import {TranslatePipe} from '@aztrix/translate';

import {SelectSearchModule} from '../select-search/select-search.module';
import {GroupSelectSearchComponent} from './group-select-search.component';
import {HelperPipesModule} from '@aztrix/helpers';

@NgModule({
  declarations: [GroupSelectSearchComponent],
  imports: [SelectSearchModule, GroupCellViewModule, HelperPipesModule, TranslatePipe],
  exports: [GroupSelectSearchComponent],
})
export class GroupSelectSearchModule {}
