<ax-icon *ngIf="icon" class="icon type-icon" [name]="icon"></ax-icon>

<mat-slide-toggle
  color="primary"
  [name]="getName()"
  [checked]="checked"
  (change)="changed($event)"
  [disabled]="form.disabled || readonly"
>
  {{ label | translate }}
</mat-slide-toggle>
<mat-hint
  [ngClass]="{collapsed}"
  (click)="collapsed = !collapsed"
  class="hint collapsible"
  align="end"
  #hintElement
>
  <div><ax-icon *ngIf="isOverflowing()" class="icon" name="chevron-down"></ax-icon></div>
  <span [ngClass]="{overflowing: isOverflowing()}">{{ hint }}</span>
</mat-hint>
<mat-error *ngIf="errorsTemplate">
  <ng-template [ngTemplateOutlet]="errorsTemplate"></ng-template>
</mat-error>
