import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import {Validators} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {
  CustomValidators,
  DateFnsDateAdapter,
  enrichProperty,
  hasEqualValue,
  MAT_DATE_FNS_DATE_FORMATS,
  PropertyFormControl,
  propertyToDate,
  subPropertyOfType,
} from '@aztrix/helpers';
import {MutableProperty, PropertyType} from '@aztrix/models';
import {PropertyRepresentation} from '@aztrix/sdk';
import {FormControl} from '@ngneat/reactive-forms';
import {getDate, getMonth, getYear} from 'date-fns';
import {Subscription} from 'rxjs';
import {startWith} from 'rxjs/operators';

import {AbstractPropertyEdit} from '../abstract-property-edit';

@Component({
  selector: 'ax-date-property-edit',
  templateUrl: './date-property-edit.component.html',
  styleUrls: ['./date-property-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: DateAdapter, useClass: DateFnsDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_DATE_FORMATS},
  ],
})
export class DatePropertyEditComponent
  extends AbstractPropertyEdit
  implements OnChanges, OnDestroy
{
  @Input() minDate = new Date(1900, 0, 1);
  @Input() maxDate = new Date(2100, 0, 1);

  @HostBinding('class.showIcon') get showIconClass() {
    return this.showIcon;
  }

  dateControl = new FormControl<any>(undefined);

  date?: Date;

  private _formSubscriptions = new Subscription();

  constructor() {
    super();

    this.dateControl.valueChanges.subscribe((value) => {
      if (this.dateControl.valid) {
        const currentDate: Date = value;
        const property = this.form.value;
        const dayProperty = subPropertyOfType(property, PropertyType.DAY);
        const newDayProperty = {
          ...dayProperty,
          value: (getDate(currentDate) || '').toString() || undefined,
        };
        const monthProperty = subPropertyOfType(property, PropertyType.MONTH);
        const newMonthProperty = {
          ...monthProperty,
          value: (getMonth(currentDate) + 1 || '').toString() || undefined,
        };
        const yearProperty = subPropertyOfType(property, PropertyType.YEAR);
        const newYearProperty = {
          ...yearProperty,
          value: (getYear(currentDate) || '').toString() || undefined,
        };

        if (
          !hasEqualValue(dayProperty, newDayProperty) ||
          !hasEqualValue(monthProperty, newMonthProperty) ||
          !hasEqualValue(yearProperty, newYearProperty)
        ) {
          PropertyFormControl.setValue(this.form, {
            ...property,
            properties: [newDayProperty, newMonthProperty, newYearProperty],
          });
          this.markAsDirty();
        }
      }
    });

    this.registerOnDisabledChange((isDisabled) => {
      if (isDisabled) {
        this.dateControl.disable({emitEvent: false});
      } else {
        this.dateControl.enable({emitEvent: false});
      }
    });
  }

  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.form && this.form) {
      this._formSubscriptions?.unsubscribe();
      this._formSubscriptions = new Subscription();
      this._formSubscriptions.add(
        this.form.valueChanges
          .pipe(startWith(this.form.value))
          .subscribe((value: PropertyRepresentation) => {
            this._prefillExistingValue(<MutableProperty>value);
          })
      );

      this._formSubscriptions.add(
        this.form.touch$.subscribe((touched: boolean) => {
          if (touched) {
            this.dateControl.markAsTouched();
          } else {
            this.dateControl.markAsUntouched();
          }
        })
      );
    }

    if (changes.required) {
      const validators = [
        CustomValidators.dateWithinRange({
          minDate: this.minDate,
          maxDate: this.maxDate,
        }),
      ];
      if (this.required) {
        validators.push(Validators.required);
      }
      this.dateControl.setValidators(validators);
    }
  }

  override ngOnDestroy() {
    this._formSubscriptions.unsubscribe();
    super.ngOnDestroy();
  }

  private _prefillExistingValue(value: MutableProperty): void {
    const property = enrichProperty(value);
    this.date = propertyToDate(property);

    this.dateControl.setValue(this.date);
  }

  get startView() {
    const birthday = this.form.value?.type === PropertyType.BIRTHDAY;
    return birthday ? 'multi-year' : 'year';
  }
}
