import {Component, HostBinding, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {AbstractControl, UntypedFormControl} from '@angular/forms';
import {FULL_NAME, Property, PropertyType} from '@aztrix/models';
import {PropertyRepresentation} from '@aztrix/sdk';
import {FormArray} from '@ngneat/reactive-forms';
import {Subscription} from 'rxjs';
import {distinctUntilChanged, map, startWith} from 'rxjs/operators';

@Component({
  selector: 'ax-name-edit',
  templateUrl: 'name-edit.component.html',
  styleUrls: ['./name-edit.component.scss'],
})
export class NameEditComponent implements OnChanges, OnDestroy {
  @HostBinding('class.c-property-edit') propertyEditClass = true;

  @Input() form: FormArray<Property | PropertyRepresentation> | null;
  @Input() name?: string;
  @Input() autofocus?: boolean;
  @Input() customLabel? = '';
  @Input() required = false;
  @Input() expanded = false;
  @Input() hint?: string;
  @Input() readonly = false;

  nameForm = new UntypedFormControl();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  PropertyType = PropertyType;

  private _subscription: Subscription;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.form && this.form) {
      this._subscription?.unsubscribe();
      this._subscription = new Subscription();
      this._subscription.add(
        this.nameForm.valueChanges.pipe(distinctUntilChanged()).subscribe((value: string) => {
          if (this.nameForm.enabled) {
            const names = value.split(' ');

            const firstnameValue = names.shift();
            const middlenameValue: string | undefined = undefined;
            const lastnameValue = names.join(' ').trim() || null;

            if (this.firstnameControl && this.firstnameControl.value.value !== firstnameValue) {
              this.firstnameControl.patchValue({
                ...this.firstnameControl.value,
                value: firstnameValue,
              });
              this.firstnameControl.markAsDirty();
            }

            if (this.middlenameControl && this.middlenameControl.value.value !== middlenameValue) {
              this.middlenameControl.patchValue({
                ...this.middlenameControl.value,
                value: middlenameValue,
              });
              this.middlenameControl.markAsDirty();
            }

            if (this.lastnameControl && this.lastnameControl.value.value !== lastnameValue) {
              this.lastnameControl.patchValue({
                ...this.lastnameControl.value,
                value: lastnameValue,
              });
              this.lastnameControl.markAsDirty();
            }
          }
        })
      );

      this._subscription.add(
        this.form.valueChanges
          .pipe(
            startWith(this.form.value),
            map((values: Property[]) =>
              values
                .filter((value) => value.type)
                .filter((value) => FULL_NAME.includes(<PropertyType>value.type))
                .sort((value1, value2) =>
                  FULL_NAME.indexOf(<PropertyType>value1.type) >
                  FULL_NAME.indexOf(<PropertyType>value2.type)
                    ? 1
                    : -1
                )
                .map((property) => property?.value)
                .filter((value) => !!value)
            ),
            distinctUntilChanged()
          )
          .subscribe((values) => {
            this._updateFullName(<string[]>values);
          })
      );

      const control = <UntypedFormControl>this.firstnameControl?.get('value');
      this._disableChanges(control.disabled);
      control.registerOnDisabledChange((isDisabled) => {
        this._disableChanges(isDisabled);
      });
    }
  }

  ngOnDestroy() {
    this._subscription?.unsubscribe();
  }

  get controls() {
    return [this.firstnameControl, this.middlenameControl, this.lastnameControl];
  }

  get firstnameControl(): AbstractControl {
    return <AbstractControl>this._findControl(PropertyType.FIRST_NAME);
  }

  get middlenameControl(): AbstractControl {
    return <AbstractControl>this._findControl(PropertyType.MIDDLE_NAME);
  }

  get lastnameControl(): AbstractControl {
    return <AbstractControl>this._findControl(PropertyType.LAST_NAME);
  }

  get hintVisible() {
    return !this.form?.controls.some((control) => control.touched && control.invalid);
  }

  getName() {
    return [this.name, 'NAME'].filter((v) => !!v).join(' ');
  }

  private _findControl(type: PropertyType) {
    return this.form?.controls.find((c) => c.value?.type === type);
  }

  private _updateFullName(values: string[]): void {
    const value = values.filter((v) => !!v).join(' ');
    if (this.nameForm.value?.trim() !== value) {
      this.nameForm.patchValue(value);
    }
  }

  private _disableChanges(isDisabled: boolean) {
    if (isDisabled) {
      this.nameForm.disable({emitEvent: false});
    } else {
      this.nameForm.enable({emitEvent: false});
    }
  }
}
