import {
  AfterViewInit,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {hasEqualValue, hasValue, unique} from '@aztrix/helpers';
import {Property, PropertyType} from '@aztrix/models';
import {PropertyRepresentation} from '@aztrix/sdk';
import {ControlsOf, FormGroup} from '@ngneat/reactive-forms';
import {combineLatest, ReplaySubject, Subscription} from 'rxjs';
import {startWith} from 'rxjs/operators';

import {AbstractPropertyEdit} from '../abstract-property-edit';
import {EditAutocompletionComponent} from '../autocompletion/edit-autocompletion/edit-autocompletion.component';

@Component({
  selector: 'ax-property-edit',
  templateUrl: './property-edit.component.html',
})
export class PropertyEditComponent
  extends AbstractPropertyEdit
  implements OnChanges, AfterViewInit, OnDestroy
{
  @Input() isIndividual = false;
  @HostBinding('class.showIcon') get showIconClass() {
    return this.showIcon;
  }

  @ViewChild(EditAutocompletionComponent) autocompleteComponent: EditAutocompletionComponent;
  @Input() isProposalSubscribeForm = false;

  formSubscription = new Subscription();

  autocompleteComponent$ = new ReplaySubject<EditAutocompletionComponent>(1);
  autocompleteProperties$ = new ReplaySubject<(Property | PropertyRepresentation)[]>(1);

  override ngOnChanges(changes: SimpleChanges) {
    if (changes.autocompleteProperties && this.autocompleteProperties) {
      this.autocompleteProperties$.next(this.autocompleteProperties);
    }
    if (changes.form && this.form) {
      this.formSubscription.unsubscribe();
      this.formSubscription = combineLatest([
        this.autocompleteProperties$,
        (<FormGroup<ControlsOf<Property | PropertyRepresentation>>>this.form).valueChanges.pipe(
          startWith(this.form.value)
        ),
        this.autocompleteComponent$,
      ]).subscribe(([autocompleteProperties, property, autocompleteComponent]) => {
        if (autocompleteProperties && property && autocompleteComponent) {
          autocompleteProperties = this._removeDuplicateAutoCompleteValues(autocompleteProperties);
          autocompleteComponent.autocomplete(
            autocompleteProperties.filter((p) => {
              if (p.type === PropertyType.LEGAL_ID) {
                return !hasEqualValue(
                  p.properties?.find((pr) => pr.type === PropertyType.COMPANY_NUMBER),
                  (<Property[]>property.properties).find(
                    (pr) => pr.type === PropertyType.COMPANY_NUMBER
                  )
                );
              } else {
                return !hasEqualValue(p, property);
              }
            })
          );
        }
      });
    }
  }

  _removeDuplicateAutoCompleteValues(properties: Property[]) {
    const uniqueProperties: Property[] = [];
    for (const prop of properties) {
      if (prop && hasValue(prop) && !uniqueProperties.find((p) => hasEqualValue(p, prop))) {
        uniqueProperties.push(prop);
      }
    }
    return unique(uniqueProperties);
  }

  ngAfterViewInit() {
    this.autocompleteComponent$.next(this.autocompleteComponent);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.formSubscription.unsubscribe();
  }

  get property(): Property | PropertyRepresentation {
    return this.form?.getRawValue();
  }
}
