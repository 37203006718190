import {Component, Inject, Input, Optional} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {LANGUAGE_OVERRIDE, TranslateService} from '@aztrix/translate';
import {PropertyType} from '@aztrix/models';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'ax-property-error',
  templateUrl: 'property-error.component.html',
  styleUrls: ['property-error.component.scss'],
})
export class PropertyErrorComponent {
  @Input() form: AbstractControl;
  @Input() label?: string;

  constructor(
    private _translate: TranslateService,
    @Optional()
    @Inject(LANGUAGE_OVERRIDE)
    private _languageOverride$?: BehaviorSubject<string | undefined>
  ) {}

  get errorTypes() {
    return Object.keys(this.valueControl?.errors || {});
  }

  get propertyType(): PropertyType {
    return this.form?.value?.type;
  }

  get propertyTypeLabel(): string {
    if (!this.propertyType) {
      return '';
    }

    if (this.label) {
      return this.label;
    } else {
      return this._translate.instant(
        `property.${this.propertyType}.label`,
        undefined,
        this._languageOverride$?.value
      );
    }
  }

  parameters(errorType: string) {
    return {
      propertyType: this.propertyTypeLabel,
      ...this.valueControl?.errors?.[errorType],
    };
  }

  get valueControl() {
    return this.form ? this.form.get('value') : undefined;
  }
}
