import {ChangeDetectionStrategy, Component} from '@angular/core';
import {PropertyType} from '@aztrix/models';

import {AbstractPropertyValueViewComponent} from '../abstract-property-value-view.component';

@Component({
  selector: 'ax-bankaccount-value-view',
  templateUrl: 'bankaccount-value-view.component.html',
  styleUrls: [
    '../abstract-property-value-view.component.scss',
    'bankaccount-value-view.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankaccountValueViewComponent extends AbstractPropertyValueViewComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PropertyType = PropertyType;
}
