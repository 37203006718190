import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {ButtonModule} from '@aztrix/components/button';
import {InfoModalModule} from '@aztrix/components/info-modal';
import {OpeningHoursViewModule} from '@aztrix/components/property-view';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {PropertyErrorModule} from '../property-error/property-error.module';
import {DatesDaysSelectModule} from './dates-days-select/dates-days-select.module';
import {DayEditComponent} from './day-edit/day-edit.component';
import {DayRangeEditComponent} from './day-range-edit/day-range-edit.component';
import {DaysCombinedEditComponent} from './days-combined-edit/days-combined-edit.component';
import {OpeningHoursEditComponent} from './opening-hours-edit.component';
import {TimeEditComponent} from './time-edit/time-edit.component';
import {TimeInputComponent} from './time-edit/time-input/time-input.component';
import {TimeRangeEditComponent} from './time-range-edit/time-range-edit.component';
import {HelperPipesModule} from '@aztrix/helpers';

@NgModule({
  declarations: [
    OpeningHoursEditComponent,
    DayRangeEditComponent,
    DaysCombinedEditComponent,
    DayEditComponent,
    TimeRangeEditComponent,
    TimeEditComponent,
    TimeInputComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    HelperPipesModule,
    TranslatePipe,
    IconsModule,
    ButtonModule,
    OpeningHoursViewModule,
    PropertyErrorModule,
    DatesDaysSelectModule,
    InfoModalModule,
  ],
  exports: [OpeningHoursEditComponent],
})
export class OpeningHoursEditModule {}
