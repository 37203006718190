import {Component} from '@angular/core';
import {PropertyType} from '@aztrix/models';

import {AbstractPropertyValueViewComponent} from '../abstract-property-value-view.component';

@Component({
  selector: 'ax-image-value-view',
  templateUrl: './image-value-view.component.html',
  styleUrls: ['./image-value-view.component.scss'],
})
export class ImageValueViewComponent extends AbstractPropertyValueViewComponent {
  get key() {
    return this.propertyOfType(PropertyType.FILE_KEY)?.value;
  }

  get name() {
    return this.propertyOfType(PropertyType.FILE_NAME)?.value;
  }

  get source() {
    return '/staticFiles/agreementFiles/' + this.key;
  }
}
