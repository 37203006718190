import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'ax-time-range-edit',
  templateUrl: 'time-range-edit.component.html',
  styleUrls: ['time-range-edit.component.scss'],
})
export class TimeRangeEditComponent implements OnInit {
  @Input() group: FormGroup<any>;
  @Input() name: string;

  ngOnInit() {
    if (!this.group) {
      console.warn(`formGroup should exist`);
      return;
    }

    const fromControl = this.group.get('from');
    const toControl = this.group.get('to');

    if (!fromControl || !toControl) {
      console.warn(`formGroup should contain two controls, 'from' and 'to'`);
      return;
    }
  }

  get fromControl() {
    return <FormControl<any>>this.group.get('from');
  }

  get toControl() {
    return <FormControl<any>>this.group.get('to');
  }
}
