import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {AutofocusModule} from '@aztrix/components/autofocus';
import {AvatarModule} from '@aztrix/components/avatar';
import {ButtonModule} from '@aztrix/components/button';
import {ClearButtonModule} from '@aztrix/components/clear-button';
import {CountrySelectorModalModule} from '@aztrix/components/country-selector-modal';
import {FileSourceModule} from '@aztrix/components/file-source';
import {FileValueEditModule} from '@aztrix/components/file-value-edit';
import {ImageSourceModule} from '@aztrix/components/image-source';
import {SpinnerModule} from '@aztrix/components/spinner';
import {ValueEditModule} from '@aztrix/components/value-edit';
import {HelperPipesModule, TomtomApiService, VatCheckerApiService} from '@aztrix/helpers';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {AddressEditComponent} from './address-edit/address-edit.component';
import {AddressAutocompletionComponent} from './autocompletion/address-autocompletion/address-autocompletion.component';
import {EditAutocompletionComponent} from './autocompletion/edit-autocompletion/edit-autocompletion.component';
import {PhoneAutocompletionComponent} from './autocompletion/phone-autocompletion/phone-autocompletion.component';
import {ZipAutocompletionComponent} from './autocompletion/zip-autocompletion/zip-autocompletion.component';
import {AvatarPropertyEditComponent} from './avatar-property-edit/avatar-property-edit.component';
import {CategoryEditModule} from './category-edit/category-edit.module';
import {CountryEditComponent} from './country-edit/country-edit.component';
import {DatePropertyEditModule} from './date-property-edit/date-property-edit.module';
import {DefaultEditComponent} from './default-edit/default-edit.component';
import {DocumentEditComponent} from './document-edit/document-edit.component';
import {FileEditComponent} from './file-edit/file-edit.component';
import {GenderEditComponent} from './gender-edit/gender-edit.component';
import {ImageEditComponent} from './image-edit/image-edit.component';
import {LegalIdEditComponent} from './legalid-edit/legalid-edit.component';
import {NameEditComponent} from './name-edit/name-edit.component';
import {OpeningHoursEditModule} from './opening-hours-edit/opening-hours-edit.module';
import {OrganisationEditComponent} from './organisation-edit/organisation-edit.component';
import {OrganisationTypeEditComponent} from './organisation-type-edit/organisation-type-edit.component';
import {PropertyEditComponent} from './property-edit/property-edit.component';
import {PropertyEditFormComponent} from './property-edit-form/property-edit-form.component';
import {PropertyErrorsModule} from './property-errors/property-errors.module';
import {SocialEditComponent} from './social-edit/social-edit.component';

const COMPONENTS = [
  PropertyEditFormComponent,
  PropertyEditComponent,
  AddressEditComponent,
  CountryEditComponent,
  DefaultEditComponent,
  GenderEditComponent,
  FileEditComponent,
  ImageEditComponent,
  SocialEditComponent,
  LegalIdEditComponent,
  AvatarPropertyEditComponent,
  NameEditComponent,
  OrganisationEditComponent,
  OrganisationTypeEditComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    PhoneAutocompletionComponent,
    AddressAutocompletionComponent,
    ZipAutocompletionComponent,
    EditAutocompletionComponent,
    DocumentEditComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatRadioModule,
    IconsModule,
    HelperPipesModule,
    TranslatePipe,
    AutofocusModule,
    CountrySelectorModalModule,
    ButtonModule,
    AvatarModule,
    OpeningHoursEditModule,
    PropertyErrorsModule,
    DatePropertyEditModule,
    FileValueEditModule,
    ImageSourceModule,
    FileSourceModule,
    ValueEditModule,
    ClearButtonModule,
    SpinnerModule,
    CategoryEditModule,
  ],
  providers: [TomtomApiService, VatCheckerApiService],
  exports: [...COMPONENTS, OpeningHoursEditModule, PropertyErrorsModule, DatePropertyEditModule],
})
export class PropertyEditModule {}
