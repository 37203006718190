import {Component} from '@angular/core';
import {prefixesForNumber} from '@aztrix/helpers';
import {PhonePrefixRepresentation} from '@aztrix/models';
import {Subject} from 'rxjs';

import {AutocompletionComponent} from '../autocompletion.component';

@Component({
  selector: 'ax-phone-autocompletion',
  templateUrl: './phone-autocompletion.component.html',
  styleUrls: ['./phone-autocompletion.component.scss'],
})
export class PhoneAutocompletionComponent extends AutocompletionComponent {
  results$: Subject<PhonePrefixRepresentation[]> = new Subject();

  public autocomplete(value: string): void {
    this.results$.next(this.searchCountriesWithPhonePrefix(value));
  }

  private searchCountriesWithPhonePrefix(prefix: string): PhonePrefixRepresentation[] {
    return prefixesForNumber(prefix).sort((a, b) => a.phonePrefix.localeCompare(b.phonePrefix));
  }
}
