import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import {AvatarType} from '@aztrix/components/avatar';

import {AbstractPropertyEdit} from '../abstract-property-edit';

@Component({
  selector: 'ax-avatar-property-edit',
  templateUrl: './avatar-property-edit.component.html',
  styleUrls: ['avatar-property-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarPropertyEditComponent extends AbstractPropertyEdit {
  @HostBinding('class.c-property-edit') propertyEditClass = true;
  @Input() isIndividual = false;
  AvatarType = AvatarType;

  constructor(private _changeDetector: ChangeDetectorRef) {
    super();
  }

  updateAvatar = (image?: string) => {
    this.form.get('value').setValue(image || null);
    this.markAsDirty();
    this._changeDetector.markForCheck();
  };
}
