<ax-default-edit
  [form]="socialEditForm"
  [name]="name"
  [hint]="hint"
  [autofocus]="autofocus"
  [required]="required"
  [matAutocomplete]="matAutocomplete"
  [autocompleteAttr]="autocompleteAttr"
  [autocompleteProperties]="autocompleteProperties"
  [autocomplete]="autocomplete"
  [showIcon]="showIcon"
  [showErrors]="showErrors"
  [readonly]="readonly"
  [prefix]="type | prefixForPropertyType"
  [placeholder]="type | placeholderForPropertyType"
></ax-default-edit>
