import {ChangeDetectionStrategy, Component, Inject, Optional} from '@angular/core';
import {BACKEND_URL} from '@aztrix/environment';
import {MatomoService, documentLink} from '@aztrix/helpers';
import {CommunicationType} from '@aztrix/models';
import {BehaviorSubject, first} from 'rxjs';

import {AbstractPropertyValueViewComponent} from '../abstract-property-value-view.component';

@Component({
  selector: 'ax-document-value-view',
  templateUrl: 'document-value-view.component.html',
  styleUrls: ['../abstract-property-value-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentValueViewComponent extends AbstractPropertyValueViewComponent {
  constructor(
    @Optional() @Inject(BACKEND_URL) public backendUrl$: BehaviorSubject<string>,
    private _matomo: MatomoService
  ) {
    super();
  }

  downloadPdf() {
    this._trackClick();
    this.backendUrl$
      .pipe(first())
      .subscribe((backendUrl) => window.open(documentLink(this.property, backendUrl), '_blank'));
  }

  private _trackClick() {
    this._matomo.trackEvent('Document value', 'click', CommunicationType.URL.toLowerCase());
  }
}
