import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'ax-chip',
  templateUrl: 'chip.component.html',
  styleUrls: ['chip.component.scss'],
})
export class ChipComponent {
  @Input() icon: string;
  @Output() removed = new EventEmitter<void>();

  _removable = false;
  @Input()
  set removable(removable: any) {
    this._removable = typeof removable === 'boolean' ? removable : true;
  }
}
