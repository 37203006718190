import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatChipsModule} from '@angular/material/chips';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {ButtonModule} from '@aztrix/components/button';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';

import {OptionByValuePipe} from './pipes/option-by-value.pipe';
import {ValueByOptionPipe} from './pipes/value-by-option.pipe';
import {SelectSearchComponent} from './select-search.component';

@NgModule({
  declarations: [SelectSearchComponent, OptionByValuePipe, ValueByOptionPipe],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    TranslatePipe,
    ButtonModule,
    IconsModule,
    MatChipsModule,
    ButtonModule,
  ],
  exports: [SelectSearchComponent],
})
export class SelectSearchModule {}
