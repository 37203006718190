import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FULL_NAME, PropertyType} from '@aztrix/models';
import {PropertyRepresentation} from '@aztrix/sdk';
import {FormArray} from '@ngneat/reactive-forms';
import {combineLatest, ReplaySubject} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'ax-property-edit-form',
  templateUrl: 'property-edit-form.component.html',
  styleUrls: ['./property-edit-form.component.scss'],
})
export class PropertyEditFormComponent implements OnChanges {
  @Input() form: FormArray<PropertyRepresentation>;
  @Input() hint: string;
  @Input() required = false;
  @Input() readonly = false;
  @Input() showIcon = false;

  form$ = new ReplaySubject<FormArray<PropertyRepresentation>>(1);

  fullNameIncluded$ = this.form$.pipe(
    map((form) => {
      const types = form.controls.map((control) => control.value?.type);
      return types.filter((type) => FULL_NAME.includes(<PropertyType>type)).length >= 3;
    })
  );

  controls$ = combineLatest([this.form$, this.fullNameIncluded$]).pipe(
    map(([form, fullNameIncluded]) => {
      if (fullNameIncluded) {
        return form.controls.filter(
          (control) => !FULL_NAME.includes(<PropertyType>control.value?.type)
        );
      } else {
        return form.controls;
      }
    })
  );

  ngOnChanges(changes: SimpleChanges) {
    if (changes.form && this.form) {
      this.form$.next(this.form);
    }
  }
}
