import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {SafeUrl} from '@angular/platform-browser';
import {UrlTransformerService} from '@aztrix/environment';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'ax-image-source',
  templateUrl: 'image-source.component.html',
  styleUrls: ['image-source.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageSourceComponent implements OnChanges {
  @Input() source?: string | SafeUrl | null;
  @Input() fallbackSource: string;
  @Input() actualSize = false;
  @Input() alt = 'avatar';
  @Input() round = false;
  @Input() rounded = false;

  _source: string | SafeUrl = '';
  _fallbackSource: string | SafeUrl = '';

  @HostBinding('class.border') borderClass: boolean;

  @Input()
  set border(value: boolean | string) {
    this.borderClass = typeof value === 'boolean' ? value : true;
  }

  error$ = new BehaviorSubject(false);

  constructor(private _transformer: UrlTransformerService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.source) {
      if (!this.source) {
        this._source = '';
        this.error$.next(true);
      } else {
        if (typeof this.source === 'string') {
          this._source = this.source.includes('data:image/')
            ? this.source
            : this._transformer.transformUrl(this.source);
        } else {
          this._source = this._transformer.transformUrl(this.source);
        }
        this.error$.next(false);
      }
    }

    if (changes.fallbackSource && this.fallbackSource) {
      this._fallbackSource = this._transformer.transformUrl(this.fallbackSource);
    }
  }
}
