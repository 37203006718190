import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {PropertyErrorModule} from '../property-error/property-error.module';
import {PropertyErrorsComponent} from './property-errors.component';

@NgModule({
  declarations: [PropertyErrorsComponent],
  imports: [CommonModule, PropertyErrorModule],
  exports: [PropertyErrorsComponent, PropertyErrorModule],
})
export class PropertyErrorsModule {}
