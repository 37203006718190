import {Component, Input} from '@angular/core';
import {Agreement, Proposal} from '@aztrix/models';

import {AbstractSelectSearch} from '../abstract-select-search';

@Component({
  selector: 'ax-agreement-select-search',
  templateUrl: 'agreement-select-search.component.html',
  styleUrls: ['./agreement-select-search.component.scss'],
})
export class AgreementSelectSearchComponent extends AbstractSelectSearch {
  @Input() proposal: Proposal;
  @Input() agreements: Agreement[];
}
