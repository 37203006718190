<mat-autocomplete class="autocomplete" #autocompletor="matAutocomplete">
  <mat-option
    class="autocomplete-option"
    *ngFor="let property of properties$ | async"
    [value]="property | propertyDisplayValue: undefined:true"
  >
    {{ property | propertyDisplayValue }}
    <ax-icon
      *ngIf="property.verification === VerificationStatus.VERIFIED"
      class="post-icon green-text"
      name="custom:verified"
    ></ax-icon>
  </mat-option>
</mat-autocomplete>
