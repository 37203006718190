<ax-icon *ngIf="icon" class="icon type-icon" [name]="icon"></ax-icon>

<mat-form-field
  class="c-input"
  [ngClass]="{'remove-subtext': !errorsTemplate && !hint, readonly: readonly}"
  appearance="outline"
  [floatLabel]="floatLabel"
>
  <mat-label>{{ label }}</mat-label>
  <textarea
    matInput
    axAutofocus
    #autoSizer="cdkTextareaAutosize"
    cdkTextareaAutosize
    cdkAutosizeMinRows="1"
    cdkAutosizeMaxRows="10"
    autocomplete="off"
    [formControl]="form"
    [placeholder]="placeholder"
    [autofocus]="autofocus"
    [required]="required"
    [readonly]="readonly"
    (change)="$event.preventDefault()"
    (focus)="focused.emit()"
  ></textarea>
  <mat-hint
    [ngClass]="{collapsed}"
    (click)="collapsed = !collapsed"
    class="hint collapsible"
    align="end"
    #hintElement
  >
    <div><ax-icon *ngIf="isOverflowing()" class="icon" name="chevron-down"></ax-icon></div>
    <span [ngClass]="{overflowing: isOverflowing()}">{{ hint }}</span>
  </mat-hint>
  <ax-clear-button matSuffix [form]="form" *ngIf="hasValue && !form.disabled"></ax-clear-button>
  <ng-content matSuffix></ng-content>
  <mat-error *ngIf="errorsTemplate">
    <ng-template [ngTemplateOutlet]="errorsTemplate"></ng-template>
  </mat-error>
</mat-form-field>
