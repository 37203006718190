import {ChangeDetectionStrategy, Component, HostBinding} from '@angular/core';

import {AbstractPropertyEdit} from '../abstract-property-edit';

@Component({
  selector: 'ax-organisation-edit',
  templateUrl: './organisation-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganisationEditComponent extends AbstractPropertyEdit {
  @HostBinding('class.c-property-edit') propertyEditClass = true;
}
