import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslatePipe} from '@aztrix/translate';

import {SelectSearchModule} from '../select-search/select-search.module';
import {ProductSelectSearchComponent} from './product-select-search.component';

@NgModule({
  declarations: [ProductSelectSearchComponent],
  imports: [CommonModule, SelectSearchModule, TranslatePipe],
  exports: [ProductSelectSearchComponent],
})
export class ProductSelectSearchModule {}
