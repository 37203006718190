import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ButtonModule} from '@aztrix/components/button';
import {ImageSourceModule} from '@aztrix/components/image-source';
import {SnackbarModule} from '@aztrix/components/snackbar';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';
import {ImageCropperModule} from 'ngx-image-cropper';

import {AvatarComponent} from './avatar/avatar.component';
import {AvatarByProfileComponent} from './avatar-by-profile/avatar-by-profile.component';
import {AvatarCropperModalComponent} from './avatar-cropper-modal/avatar-cropper-modal.component';
import {AvatarEditComponent} from './avatar-edit/avatar-edit.component';
import {AvatarProfileComponent} from './avatar-profile/avatar-profile.component';
import {BadgeIconComponent} from './badge-icon/badge-icon.component';

@NgModule({
  declarations: [
    AvatarComponent,
    AvatarCropperModalComponent,
    AvatarEditComponent,
    AvatarByProfileComponent,
    BadgeIconComponent,
    AvatarProfileComponent,
  ],
  imports: [
    CommonModule,
    IconsModule,
    ImageSourceModule,
    ImageCropperModule,
    SnackbarModule,
    ButtonModule,
    TranslatePipe,
  ],
  exports: [
    AvatarComponent,
    AvatarCropperModalComponent,
    AvatarEditComponent,
    AvatarByProfileComponent,
    AvatarProfileComponent,
  ],
})
export class AvatarModule {}
