import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {PropertyErrorComponent} from './property-error.component';
import {HelperPipesModule} from '@aztrix/helpers';

@NgModule({
  declarations: [PropertyErrorComponent],
  imports: [CommonModule, IconsModule, HelperPipesModule, TranslatePipe],
  exports: [PropertyErrorComponent],
})
export class PropertyErrorModule {}
