<div *ngIf="property | propertyHasValue; else empty">
  <div class="c-card__property" *ngFor="let line of addressLineProperties; let first = first">
    <ng-container *ngIf="line | propertyDisplayValue as addressLine">
      <span>{{ addressLine }}</span>
      <span
        *ngIf="first && verificationColor()"
        [ngClass]="verificationColor()"
        matTooltip="{{ parentProperty || property | propertyVerificationLabel }}"
      ></span>
    </ng-container>
  </div>
</div>

<ng-template #empty>
  {{ 'property.value.empty' | translate }}
</ng-template>
