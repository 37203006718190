import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {OpeningDates, OpeningHoursParser} from '@aztrix/helpers';
import {MediaQuery, ObserveResizeService} from 'angular-container-media-query';

import {AbstractPropertyValueViewComponent} from '../abstract-property-value-view.component';

@Component({
  selector: 'ax-opening-hours-view',
  templateUrl: 'opening-hours-view.component.html',
  styleUrls: [
    '../abstract-property-value-view.component.scss',
    'opening-hours-view.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpeningHoursViewComponent
  extends AbstractPropertyValueViewComponent
  implements OnChanges
{
  private _size: 'SMALL' | 'MEDIUM' | 'AUTO' = 'AUTO';

  @Input()
  set size(size: 'SMALL' | 'MEDIUM' | 'AUTO') {
    switch (size) {
      case 'SMALL':
        this._smallClass = true;
        this._mediumClass = false;
        break;
      case 'MEDIUM':
        this._smallClass = false;
        this._mediumClass = true;
        break;
        break;

      default:
        break;
    }
    this._size = size;
  }
  get size(): 'SMALL' | 'MEDIUM' | 'AUTO' {
    return this._size;
  }

  @MediaQuery('(max-width: 250px)')
  set smallQuery(small: boolean) {
    if (this.size === 'AUTO') {
      this._smallClass = small;
    }
  }

  @MediaQuery('(min-width: 251px)')
  set mediumQuery(medium: boolean) {
    if (this.size === 'AUTO') {
      this._mediumClass = medium;
    }
  }

  openingDates: OpeningDates[] = [];

  @HostBinding('class.small') _smallClass = false;
  @HostBinding('class.medium') _mediumClass = false;

  constructor(
    resize: ObserveResizeService,
    elementRef: ElementRef,
    changeDetector: ChangeDetectorRef
  ) {
    super();
    resize.register(this, elementRef, changeDetector);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.property && this.property) {
      this.openingDates = OpeningHoursParser.parse(this.property?.value);
    }
  }
}
