import {Component, Input} from '@angular/core';
import type Quill from 'quill';

import {ValueEdit} from './value-edit';

@Component({
  selector: 'ax-value-edit',
  templateUrl: './value-edit.component.html',
  styleUrls: ['value-edit.component.scss'],
})
export class ValueEditComponent extends ValueEdit {
  @Input() type:
    | 'TEXT'
    | 'EMAIL'
    | 'URL'
    | 'PHONE'
    | 'DATE'
    | 'TEXT_AREA'
    | 'RICH_TEXT'
    | 'BOOLEAN'
    | 'NUMBER';
  @Input() numberConfig?: {min?: number; max?: number; step?: number};

  quillEditor?: Quill;

  quillEditorCreated(quillEditor: Quill) {
    this.quillEditor = quillEditor;
  }

  get inputType() {
    switch (this.type) {
      case 'EMAIL':
        return 'email';
      case 'TEXT':
        return 'text';
      case 'NUMBER':
        return 'number';
      default:
        console.error(`Unknown type ${this.type}`);
        return undefined;
    }
  }
}
