<ng-template #option let-result>
  <ax-proposal-cell-view [proposal]="result"></ax-proposal-cell-view>
</ng-template>

<ng-template #selected let-result>
  <span>
    {{ result | displayName }}
  </span>
</ng-template>

<ax-select-search
  [form]="form"
  [options]="proposals"
  [placeholder]="(multiple ? 'label.proposals' : 'label.proposal') | translate"
  [optionTemplate]="option"
  [selectedValueTemplate]="selected"
  [multiple]="multiple"
  [searchCallback$]="searchCallback$"
  [valueCallback]="valueCallback"
  [label]="label ? label : ((multiple ? 'label.proposals' : 'label.proposal') | translate)"
></ax-select-search>
