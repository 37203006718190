import {Component} from '@angular/core';
import {Property, VerificationStatus} from '@aztrix/models';
import {ReplaySubject, Subject} from 'rxjs';

import {AutocompletionComponent} from '../autocompletion.component';

@Component({
  selector: 'ax-edit-autocompletion',
  templateUrl: './edit-autocompletion.component.html',
  styleUrls: ['../autocompletion.component.scss'],
})
export class EditAutocompletionComponent extends AutocompletionComponent {
  properties$: Subject<Property[]> = new ReplaySubject(1);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  VerificationStatus = VerificationStatus;

  autocomplete(properties: Property[]) {
    this.properties$.next(properties);
  }
}
