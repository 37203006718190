import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AvatarModule} from '@aztrix/components/avatar';
import {TranslatePipe} from '@aztrix/translate';

import {RowCellViewModule} from '../row-cell-view.module';
import {GroupCellViewComponent} from './group-cell-view.component';
import {HelperPipesModule} from '@aztrix/helpers';

@NgModule({
  declarations: [GroupCellViewComponent],
  imports: [CommonModule, RowCellViewModule, AvatarModule, HelperPipesModule, TranslatePipe],
  exports: [GroupCellViewComponent],
})
export class GroupCellViewModule {}
