import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {SelectSearchModule} from '../select-search/select-search.module';
import {CountrySelectSearchComponent} from './country-select-search.component';
import {HelperPipesModule} from '@aztrix/helpers';

@NgModule({
  declarations: [CountrySelectSearchComponent],
  imports: [CommonModule, SelectSearchModule, HelperPipesModule, TranslatePipe, IconsModule],
  exports: [CountrySelectSearchComponent],
})
export class CountrySelectSearchModule {}
