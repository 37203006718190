import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {
  isDynamic,
  isGroup,
  isMeProfile,
  isPrivate,
  isProfile,
  isPublic,
  isPublicProfile,
} from '@aztrix/helpers';
import {DeflatedProfile, Group, Profile} from '@aztrix/models';
import {ProfileRepresentation} from '@aztrix/sdk';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ReplaySubject} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';

import {BadgeIconType} from '../badge-icon-type';

@UntilDestroy()
@Component({
  selector: 'ax-avatar-profile',
  templateUrl: 'avatar-profile.component.html',
  styleUrls: ['avatar-profile.component.scss'],
})
export class AvatarProfileComponent implements OnChanges {
  @Input() profile: Profile | ProfileRepresentation | DeflatedProfile | Group;
  @Input() edit = false;
  @Input() blocked = false;
  @Input() border = true;
  @Output() update = new EventEmitter<string>();

  _putBadge = false;
  @Input()
  set putBadge(putBadge: boolean | string) {
    this._putBadge = typeof putBadge === 'boolean' ? putBadge : true;
  }

  private _profile$ = new ReplaySubject<Profile | ProfileRepresentation>(1);
  private _putBadge$ = new ReplaySubject<boolean>(1);

  badge$ = this._profile$.pipe(
    map((profile) => this._badge(profile, this.blocked)),
    shareReplay(1)
  );

  badgeColor$ = this._profile$.pipe(
    map((profile) => this._badgeColor(profile, this.blocked)),
    shareReplay(1)
  );

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.putBadge) {
      this._putBadge$.next(this._putBadge);
    }

    if (changes.profile && this.profile && isProfile(this.profile)) {
      this._profile$.next(this.profile);
    }
  }

  private _badge(
    profile: Profile | ProfileRepresentation | DeflatedProfile | Group,
    blocked: boolean
  ): BadgeIconType | undefined {
    if (!this._putBadge) {
      return undefined;
    }
    if (!profile) {
      return undefined;
    }

    if (isProfile(profile)) {
      // Blocked badge
      if (blocked) {
        return BadgeIconType.BLOCKED;
      }
      // Default Aztrix badge
      return BadgeIconType.AZTRIX;
    } else if (isGroup(profile)) {
      if (isPublic(profile)) {
        return BadgeIconType.PUBLIC;
      } else if (
        (isPrivate(profile) && profile.visibleToMembers) ||
        (isPrivate(profile) && profile.member)
      ) {
        return BadgeIconType.CLOSED;
      } else if (isPrivate(profile) && !profile.visibleToMembers) {
        return BadgeIconType.PRIVATE;
      }
    }
    return undefined;
  }

  private _badgeColor(
    profile: Profile | ProfileRepresentation | DeflatedProfile | Group,
    blocked: boolean
  ): string | undefined {
    if (!profile || isGroup(profile) || isMeProfile(profile)) {
      return 'green';
    }
    if (blocked) {
      return 'red';
    }
    const contact: Profile = <Profile>profile;
    if (isDynamic(contact)) {
      return 'green';
    }
    if (isPublicProfile(contact)) {
      return 'grey-light1';
    }
    if (contact.invited) {
      return 'orange';
    }
    return undefined;
  }
}
