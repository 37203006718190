import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {FloatLabelType} from '@angular/material/form-field';
import {isOverflowing} from '@aztrix/helpers';
import {FormControl} from '@ngneat/reactive-forms';
import {Subscription} from 'rxjs';

let nextUniqueId = 0;

@Directive()
export abstract class ValueEdit implements OnChanges, OnDestroy {
  @Input() form: FormControl<string | number | Date | undefined>;
  @Input() name?: string;
  @Input() label?: string;
  @Input() floatLabel: FloatLabelType = 'auto';
  @Input() placeholder: string;
  @Input() icon?: string;
  @Input() hint?: string;
  @Input() autofocus: boolean;
  @Input() required = false;
  @Input() autocompleteAttr?: string = undefined;
  @Input() matAutocomplete?: MatAutocomplete;
  @Input() errorsTemplate?: TemplateRef<unknown>;
  @Input() prefix?: string;
  @Input() postfix?: string;
  @Input() published = false;
  @Input() readonly = false;
  @Output() focused = new EventEmitter<void>();

  @ViewChild('hintElement') hintElement: ElementRef;

  @HostBinding('class.showIcon') get showIconClass() {
    return this.icon;
  }

  overflowing = false;

  collapsed = false;

  protected uniqueId = nextUniqueId++;

  protected _subscriptions = new Subscription();
  private _formSubscription = new Subscription();

  constructor(protected _changeDetector: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && this.form?.touch$) {
      this._formSubscription.add(
        this.form?.touch$.subscribe(() => {
          this._changeDetector.detectChanges();
        })
      );
    }
  }

  ngOnDestroy() {
    if (!this._subscriptions?.closed) {
      this._subscriptions.unsubscribe();
    }
  }

  clearValue() {
    this.form?.setValue('');
  }

  getName() {
    return [this.name, this.uniqueId].filter((v) => !!v).join(' ');
  }

  get hasValue() {
    return !!this.form?.value;
  }

  isOverflowing() {
    if (!this.overflowing) {
      this.overflowing = isOverflowing(this.hintElement);
      return this.overflowing;
    }

    return this.overflowing;
  }

  get autocomplete() {
    return <MatAutocomplete>this.matAutocomplete;
  }
}
