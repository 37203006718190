import {Component, Input} from '@angular/core';
import {DAYS_OF_WEEK} from '@aztrix/helpers';
import {TranslateService} from '@aztrix/translate';
import {parse} from 'date-fns';

@Component({
  selector: 'ax-opening-hours-day-view',
  templateUrl: 'opening-hours-day-view.component.html',
  styleUrls: ['opening-hours-day-view.component.scss'],
})
export class OpeningHoursDayViewComponent {
  @Input() days: string[];

  constructor(private _translationService: TranslateService) {}

  activeLanguage$ = this._translationService.lang$;

  get dayRanges() {
    if (!this.days) {
      return [];
    }

    const now = new Date();

    let dayRange = [];
    const dayRanges = [];
    for (const day of this.days) {
      dayRange.push(parse(day, 'EEEEEE', now));
      if (!this.nextIsInSequence(this.days, day)) {
        dayRanges.push(dayRange);
        dayRange = [];
      }
    }

    return dayRanges;
  }

  nextIsInSequence(days: string[], day: string): boolean {
    const index1 = days.findIndex((d) => d === day);
    const index2 = DAYS_OF_WEEK.findIndex((d) => d === day);
    if (index1 + 1 >= days.length) {
      return false;
    }
    return days[index1 + 1] === DAYS_OF_WEEK[index2 + 1];
  }
}
