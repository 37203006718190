import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import {CountrySelectorModalComponent} from '@aztrix/components/country-selector-modal';
import {OverlayService} from '@aztrix/components/overlay';
import {CountryCodeRepresentation, DEFAULT_COUNTRY, hasValue} from '@aztrix/helpers';
import {BehaviorSubject, first} from 'rxjs';

import {AbstractPropertyEdit} from '../abstract-property-edit';

@Component({
  selector: 'ax-country-edit',
  templateUrl: './country-edit.component.html',
  styleUrls: ['country-edit.component.scss'],
})
export class CountryEditComponent extends AbstractPropertyEdit implements OnInit {
  @Input() countryCodes: string[] = [];

  @Output() countryUpdated = new EventEmitter();

  constructor(
    private _overlay: OverlayService,
    private _changeDetector: ChangeDetectorRef,
    private _elementRef: ElementRef,
    @Optional()
    @Inject(DEFAULT_COUNTRY)
    private _defaultCountry$: BehaviorSubject<CountryCodeRepresentation>
  ) {
    super();
  }

  openSearchSelectCountryModal(event: MouseEvent): void {
    if (this.form.disabled || this.readonly) {
      return undefined;
    }
    // Avoid ExpressionChangedAfterItHasBeenCheckedError
    const inputElement = <Element>event.target;
    (<HTMLInputElement>(inputElement.querySelector('input') || inputElement)).blur();
    event.preventDefault();
    this._overlay.createModal(this._elementRef, CountrySelectorModalComponent, {
      title: 'label.country',
      init: (countryModal) => {
        countryModal.countryCodes = this.countryCodes;
        countryModal.showDefaultCountry = false;
        countryModal.didSelectCountry = (country: CountryCodeRepresentation | undefined) => {
          if (country?.countryCode !== this.form.value) {
            this.countryUpdated.emit();
          }
          if (country?.countryCode) {
            this.form.get('value').setValue(country.countryCode);
            this.markAsDirty();
          }
          this._changeDetector.markForCheck();
        };
      },
    });
  }

  override ngOnInit(): void {
    if (this._defaultCountry$) {
      this._defaultCountry$.pipe(first()).subscribe((value) => {
        if (!hasValue(this.form.value)) {
          this.form.get('value').setValue(value.countryCode);
          this.form.markAsUntouched();
        }
      });
    }
  }
}
