<ax-name-edit
  *ngIf="fullNameIncluded$ | async"
  [form]="form$ | async"
  [hint]="hint"
  [required]="required"
  [readonly]="readonly"
></ax-name-edit>

<ng-container *ngFor="let formControl of controls$ | async">
  <ax-property-edit
    [form]="formControl"
    [hint]="hint"
    [required]="required"
    [showIcon]="showIcon"
    [readonly]="readonly"
  ></ax-property-edit>
</ng-container>
