import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {iconForPageTemplate} from '@aztrix/helpers';
import {Page} from '@aztrix/models';

@Component({
  selector: 'ax-page-cell-view',
  templateUrl: './page-cell-view.component.html',
  styleUrls: ['./page-cell-view.component.scss', '../cell-view.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageCellViewComponent {
  @Input() page: Page;

  get templateIcon() {
    return iconForPageTemplate(this.page?.templateId);
  }
}
