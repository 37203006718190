import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ButtonModule} from '@aztrix/components/button';
import {IconsModule} from '@aztrix/icons';

import {SnackbarComponent} from './snackbar.component';

@NgModule({
  declarations: [SnackbarComponent],
  imports: [CommonModule, IconsModule, ButtonModule],
  exports: [SnackbarComponent],
})
export class SnackbarModule {}
