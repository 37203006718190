<ax-image-source
  *ngIf="!noAvatar"
  class="proposal-icon"
  [source]="
    proposal?.languages?.[0]?.avatar
      ? 'staticFiles/avatars/' + proposal?.languages?.[0]?.avatar
      : undefined
  "
>
  <ax-icon class="icon" name="file-document-edit"></ax-icon>
</ax-image-source>
<div class="middle">
  <div class="name">
    {{ firstNameProperty | propertyDisplayValue }}
    {{ lastNameProperty | propertyDisplayValue }}
  </div>
  <div class="title">
    <ax-icon class="icon" name="file-document-edit"></ax-icon>
    {{ proposal | displayName }}
  </div>
</div>
<ng-content></ng-content>
