import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {CountrySelectorModalComponent} from '@aztrix/components/country-selector-modal';
import {OverlayService} from '@aztrix/components/overlay';
import {
  countryCodeForNumber,
  CountryCodeRepresentation,
  DEFAULT_COUNTRY,
  parseNumber,
  prefixForNumber,
} from '@aztrix/helpers';
import {BehaviorSubject, Subscription} from 'rxjs';
import {startWith} from 'rxjs/operators';

import {ValueEdit} from '../../value-edit';

@Component({
  selector: 'ax-phone-edit',
  templateUrl: './phone-edit.component.html',
  styleUrls: [
    './phone-edit.component.scss',
    '../../../property-edit/show-icon.scss',
    '../../placeholder.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneEditComponent extends ValueEdit implements OnInit, OnChanges, OnDestroy {
  countryCode = 'world';

  @ViewChild(MatAutocompleteTrigger)
  autocompleteTrigger: MatAutocompleteTrigger;

  private _valueChangesSubscription = new Subscription();
  private _defaultCountrySubscription = new Subscription();

  constructor(
    private _overlay: OverlayService,
    private _elementRef: ElementRef,
    @Optional()
    @Inject(DEFAULT_COUNTRY)
    private _defaultCountry$: BehaviorSubject<CountryCodeRepresentation>,
    changeDetector: ChangeDetectorRef
  ) {
    super(changeDetector);
  }

  ngOnInit(): void {
    if (this._defaultCountry$) {
      this._defaultCountrySubscription = this._defaultCountry$.subscribe((value) => {
        if (this.form.untouched && !this.form.value) {
          this.form.setValue(value.phonePrefix[0]);
        }
      });
    }
  }

  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.form && this.form) {
      this._valueChangesSubscription = this.form.valueChanges
        .pipe(startWith(this.form.value))
        .subscribe((value) => {
          value = this.phoneTransform(<string>value);
          if (value !== this.form.value) {
            this.form.setValue(value, {onlySelf: true});
          }

          const code = countryCodeForNumber(<string>value);
          this.countryCode = code.length ? code : 'world';
        });
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this._valueChangesSubscription.unsubscribe();
    this._defaultCountrySubscription.unsubscribe();
  }

  openSearchSelectCountryModal(): void {
    if (this.readonly) {
      return;
    }

    this._overlay.createModal(this._elementRef, CountrySelectorModalComponent, {
      title: 'label.country',
      init: (modal) => {
        modal.showPrefix = true;
        modal.didSelectCountry = (country: CountryCodeRepresentation | undefined) => {
          if (country?.countryCode) {
            const value = <string>this.form.value;
            const prefix = prefixForNumber(value);
            if (prefix?.phonePrefix) {
              const phone = value.substring(prefix.phonePrefix.length);
              this.form.setValue(country.phonePrefix[0] + phone);
            } else {
              this.form.setValue(country.phonePrefix[0]);
            }
          }
        };

        window.setTimeout(() => {
          this.autocompleteTrigger.closePanel();
        });
      },
    });
  }

  phoneTransform(value: string): string | undefined {
    value = value?.trim();
    if (!value?.length) {
      return this._defaultCountry$?.value?.phonePrefix[0] || '';
    } else if (value[0] === '+') {
      return `+${value?.split('+')[value?.split('+').length - 1]}`?.trim();
    } else {
      const countryCode = this._defaultCountry$?.value?.countryCode || 'BE';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const parsed = parseNumber(value, <any>countryCode);
      return parsed?.number?.toString();
    }
  }

  override clearValue() {
    this.form.setValue(this.phonePrefix);
  }

  get phonePrefix() {
    const value = this.form?.value;
    const prefix = prefixForNumber(<string>value);
    return prefix?.phonePrefix;
  }

  override get hasValue() {
    const value = <string>this.form?.value;
    const prefix = this.phonePrefix;
    if (!prefix) {
      return !!value;
    }
    return value.length > prefix.length;
  }
}
