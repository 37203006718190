<!-- image -->
<ax-image-source
  *ngIf="!icon"
  [border]="border"
  [source]="_source"
  [round]="round"
  [rounded]="rounded"
>
  <div
    class="placeholder"
    [ngClass]="{border: border}"
    [style.border-radius]="round || rounded ? (round ? '50%' : '10px') : undefined"
  >
    <ax-icon class="placeholder-icon" [name]="fallbackIcon"></ax-icon>
  </div>
</ax-image-source>

<!-- icon -->
<div *ngIf="icon" class="avatar" [ngClass]="[iconColor, type | lowercase]">
  <ax-icon class="icon avatar-icon" [name]="icon"></ax-icon>
</div>

<!-- badge -->
<div class="avatar-badge" *ngIf="badge && badgeColor">
  <ax-badge-icon [type]="badge" [color]="badgeColor"></ax-badge-icon>
</div>
