import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ButtonModule} from '@aztrix/components/button';
import {ClearButtonModule} from '@aztrix/components/clear-button';
import {DateEditModule} from '@aztrix/components/value-edit';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {PropertyErrorModule} from '../property-error/property-error.module';
import {DatePropertyEditComponent} from './date-property-edit.component';
import {HelperPipesModule} from '@aztrix/helpers';

@NgModule({
  declarations: [DatePropertyEditComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HelperPipesModule,
    TranslatePipe,
    PropertyErrorModule,
    IconsModule,
    ButtonModule,
    ClearButtonModule,
    DateEditModule,
  ],
  exports: [DatePropertyEditComponent],
})
export class DatePropertyEditModule {}
