<ax-icon
  *ngIf="showIcon"
  class="icon type-icon"
  [name]="PropertyType.CATEGORY | propertyIcon"
></ax-icon>
<ax-category-select-search
  *ngIf="form"
  [form]="form.get('value')"
  [forProperty]="true"
  [hint]="hint"
  [required]="required"
  [label]="customLabel === '' ? (type | propertyTypeLabel) : customLabel"
  [readonly]="readonly"
></ax-category-select-search>
