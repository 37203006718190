import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'valueByOption',
})
export class ValueByOptionPipe implements PipeTransform {
  transform(option: unknown, valueCallback?: (option: unknown) => unknown): unknown {
    if (valueCallback && option) {
      return valueCallback(option);
    } else {
      return option;
    }
  }
}
