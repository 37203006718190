import {ChangeDetectionStrategy, Component} from '@angular/core';

import {AbstractPropertyValueViewComponent} from '../abstract-property-value-view.component';

@Component({
  selector: 'ax-value-verified-view',
  templateUrl: 'value-verified-view.component.html',
  styleUrls: [
    '../abstract-property-value-view.component.scss',
    './value-verified-view.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValueVerifiedViewComponent extends AbstractPropertyValueViewComponent {}
