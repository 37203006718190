import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {UrlTransformerService} from '@aztrix/environment';

@Component({
  selector: 'ax-file-source',
  templateUrl: 'file-source.component.html',
  styleUrls: ['file-source.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileSourceComponent implements OnChanges {
  constructor(private _transformer: UrlTransformerService) {}

  @Input() source: string;
  @Input() fileName?: string;

  _source = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.source) {
      if (!this.source) {
        this._source = '';
      } else {
        this._source = this._transform(this.source);
      }
    }
  }

  private _transform(url: string) {
    return this._transformer.transformUrl(url);
  }
}
