import {ChangeDetectionStrategy, Component} from '@angular/core';
import {PropertyType} from '@aztrix/models';

import {AbstractPropertyValueViewComponent} from '../abstract-property-value-view.component';

@Component({
  selector: 'ax-file-value-view',
  templateUrl: './file-value-view.component.html',
  styleUrls: ['./file-value-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileValueViewComponent extends AbstractPropertyValueViewComponent {
  get key() {
    return this.propertyOfType(PropertyType.FILE_KEY)?.value;
  }

  get name() {
    return this.propertyOfType(PropertyType.FILE_NAME)?.value;
  }

  get source() {
    return '/staticFiles/agreementFiles/' + this.key;
  }
}
