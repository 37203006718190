<ng-container *ngFor="let day of DAYS_OF_WEEK">
  <button
    axButton
    type="button"
    (click)="toggle(day)"
    [outline]="!selected(day)"
    [filled]="selected(day)"
    color="primary"
    smallButton
  >
    {{ getDayDateFormat(day) | formatDay }}
  </button>
</ng-container>
