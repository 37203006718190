import {ChangeDetectionStrategy, Component} from '@angular/core';

import {AbstractPropertyValueViewComponent} from '../abstract-property-value-view.component';

@Component({
  selector: 'ax-category-value-view',
  templateUrl: 'category-value-view.component.html',
  styleUrls: [
    '../abstract-property-value-view.component.scss',
    './category-value-view.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryValueViewComponent extends AbstractPropertyValueViewComponent {}
