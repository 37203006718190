import {Component, Input} from '@angular/core';

@Component({
  selector: 'ax-opening-hours-date-view',
  templateUrl: 'opening-hours-date-view.component.html',
  styleUrls: ['opening-hours-date-view.component.scss'],
})
export class OpeningHoursDateViewComponent {
  @Input() dates: Date[];
  @Input() text: string;

  get dateRange() {
    if (!this.dates || this.dates.length <= 2) {
      return this.dates;
    }
    return [this.dates[0], this.dates[this.dates.length - 1]];
  }
}
