import {ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges} from '@angular/core';

import {BadgeIconType} from '../badge-icon-type';

@Component({
  selector: 'ax-badge-icon',
  templateUrl: 'badge-icon.component.html',
  styleUrls: ['badge-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeIconComponent implements OnChanges {
  @Input() type: BadgeIconType;
  @Input() color?: string;

  @HostBinding('class') classes = 'badge';

  ngOnChanges() {
    const colorClass = this.colorClass;
    this.classes = 'badge' + (colorClass ? ` ${colorClass}` : '');
  }

  get iconClass(): string | undefined {
    switch (this.type) {
      case BadgeIconType.BLOCKED:
        return 'minus';
      case BadgeIconType.MEMBER:
        return 'user';
      case BadgeIconType.FOLLOWER:
        return 'heart';
      case BadgeIconType.PUBLIC:
        return 'eye';
      case BadgeIconType.PRIVATE:
        return 'eye-off';
      case BadgeIconType.CLOSED:
        return 'account-multiple';
      case BadgeIconType.AZTRIX:
        return 'custom:aztrixlogo';
      default:
        return undefined;
    }
  }

  get colorClass(): string | undefined {
    if (this.color) {
      return this.color + (this.color.endsWith('-bg') ? '' : '-bg');
    }
    switch (this.type) {
      case BadgeIconType.BLOCKED:
        return 'red-bg';
      case BadgeIconType.MEMBER:
        return 'green-bg';
      case BadgeIconType.FOLLOWER:
        return 'green-bg';
      case BadgeIconType.PUBLIC:
        return 'green-bg';
      case BadgeIconType.PRIVATE:
        return 'grey-bg';
      case BadgeIconType.CLOSED:
        return 'green-bg';
      default:
        return undefined;
    }
  }
}
