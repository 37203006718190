<ng-template #option let-result>
  <ax-agreement-cell-view *ngIf="result" [agreement]="result" [proposal]="proposal">
    <div class="status" *ngIf="result.agreementData?.confirmed">
      <span>
        {{ result.active ? ('agreement.active' | translate) : ('agreement.expired' | translate) }}
      </span>
      <div class="status-indicator" [ngClass]="{'is-expired': result.expirationTimestamp}"></div>
    </div>
    <div class="status" *ngIf="!result.agreementData?.confirmed">
      <span>{{ 'agreement.status.not-confirmed' | translate }}</span>
      <div class="status-indicator is-pending"></div>
    </div>
  </ax-agreement-cell-view>
</ng-template>

<ng-template #selectedViewTemplate let-result>
  <ax-agreement-cell-view
    class="selected"
    *ngIf="result"
    [agreement]="result"
    [noAvatar]="true"
    [proposal]="proposal"
  ></ax-agreement-cell-view>
</ng-template>

<ax-select-search
  [form]="form"
  [options]="agreements"
  [placeholder]="(multiple ? 'label.agreements' : 'label.agreement') | translate"
  [optionTemplate]="option"
  [selectedValueTemplate]="selectedViewTemplate"
  [multiple]="multiple"
  [valueCallback]="valueCallback"
  [label]="label ? label : ((multiple ? 'label.agreements' : 'label.agreement') | translate)"
></ax-select-search>
