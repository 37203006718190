<ax-icon
  *ngIf="showIcon"
  class="icon type-icon"
  [name]="PropertyType.GENDER | propertyIcon"
></ax-icon>
<div>
  <div class="header">
    <div class="title">
      <label class="label"
        >{{ customLabel === '' ? (form.getRawValue() | propertyTypeLabel) : customLabel
        }}<span *ngIf="required">&nbsp;*</span></label
      >
    </div>
    <ax-clear-button
      matSuffix
      [form]="valueControl"
      *ngIf="hasValue && !valueControl.disabled && !readonly"
    ></ax-clear-button>
  </div>
  <mat-radio-group
    [name]="getName()"
    class="radio"
    [ngClass]="{readonly: readonly}"
    [formControl]="valueControl"
  >
    <mat-radio-button value="M" class="radio-item" type="radio">
      {{ 'label.male' | translate }}
    </mat-radio-button>
    <mat-radio-button value="F" class="radio-item" type="radio">
      {{ 'label.female' | translate }}
    </mat-radio-button>
  </mat-radio-group>
  <div class="hint-wrapper">
    <mat-hint
      [ngClass]="{collapsed}"
      (click)="collapsed = !collapsed"
      class="hint collapsible"
      align="end"
      #hintElement
    >
      <div><ax-icon *ngIf="isOverflowing()" class="icon" name="chevron-down"></ax-icon></div>
      <span [ngClass]="{overflowing: isOverflowing()}">{{ hint }}</span>
    </mat-hint>
  </div>
  <ax-property-error *ngIf="valueControl.touched" [form]="form"></ax-property-error>
</div>
