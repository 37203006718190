import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {subPropertyOfType} from '@aztrix/helpers';
import {MutableProperty, Property, PropertyType} from '@aztrix/models';
import {FormControl, FormGroup} from '@ngneat/reactive-forms';
import {ReplaySubject, startWith, Subscription, switchMap} from 'rxjs';

import {AbstractPropertyEdit} from '../abstract-property-edit';

@Component({
  selector: 'ax-document-edit',
  templateUrl: './document-edit.component.html',
  styleUrls: ['./document-edit.component.scss'],
})
export class DocumentEditComponent
  extends AbstractPropertyEdit
  implements OnInit, OnChanges, OnDestroy
{
  constructor() {
    super();
  }

  fileControl = new FormControl<any>();

  subscriptions = new Subscription();
  formSubscription = new Subscription();

  private _form$ = new ReplaySubject<FormGroup<any>>(1);
  private _formEnabled$ = this._form$.pipe(switchMap((form) => form.enabled$));

  override ngOnInit() {
    super.ngOnInit();

    this.subscriptions = new Subscription();
    this.subscriptions.add(
      this.fileControl.valueChanges.subscribe((file) => {
        const property = this.form.value;
        const fileKeyProperty = subPropertyOfType(property, PropertyType.FILE_KEY);

        if (fileKeyProperty?.value !== file?.id) {
          (<MutableProperty>fileKeyProperty).value = file?.id;
          this.form.patchValue({...this.form.value});
        }
      })
    );

    this.subscriptions.add(
      this._formEnabled$.subscribe((enabled) => {
        if (enabled) {
          this.fileControl.enable({emitEvent: false});
        } else {
          this.fileControl.disable({emitEvent: false});
        }
      })
    );
  }

  override ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && this.form) {
      this.formSubscription.unsubscribe();
      this.formSubscription = this.form.valueChanges
        .pipe(startWith(this.form.value))
        .subscribe((property: Property) => {
          const fileKeyProperty = subPropertyOfType(property, PropertyType.FILE_KEY);

          if (this.fileControl.value?.id !== fileKeyProperty?.value) {
            this.fileControl.setValue({
              ...this.fileControl.value,
              id: fileKeyProperty?.value,
            });
          }
        });

      this._form$.next(this.form);
    }

    super.ngOnChanges(changes);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();

    this.subscriptions.unsubscribe();
  }

  get formType(): PropertyType {
    return this.form.value.type;
  }

  get documentNameValueControl(): FormControl<any> {
    return <FormControl<any>>(
      this.propertiesControl.controls
        .find((c) => c.get('type')?.value === PropertyType.DOCUMENT_NAME)
        ?.get('value')
    );
  }

  get fileNameValueControl(): FormControl<any> {
    return <FormControl<any>>(
      this.propertiesControl.controls
        .find((c) => c.get('type')?.value === PropertyType.FILE_NAME)
        ?.get('value')
    );
  }

  get fileKeyValueControl(): FormControl<any> | undefined {
    return this.propertiesControl.controls
      .find((c) => c.get('type')?.value === PropertyType.FILE_KEY)
      ?.get('value');
  }

  setFileValue() {
    this.form.updateValueAndValidity();
    this.form.markAllAsDirty();
  }

  clearFileValue() {
    this.fileKeyValueControl?.setValue(undefined);
    this.fileNameValueControl?.setValidators(null);
    this.form.updateValueAndValidity();
  }
}
