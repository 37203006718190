import * as i0 from '@angular/core';
import { isDevMode, Injectable } from '@angular/core';
import { match } from 'css-mediaquery';
const MEDIA_QUERY_TEMPLATE_RULES = Symbol('__mediaQueryTemplateRules');
class ObserveResizeService {
  _ngZone;
  _targets = [];
  _observedElements = [];
  constructor(_ngZone) {
    this._ngZone = _ngZone;
  }
  _resizeObserver = this.windowResizeObserver(entries => {
    for (const entry of entries) {
      const element = entry.target;
      const {
        width,
        height
      } = this._entrySize(entry);
      if (width === 0 && height === 0) {
        this._remove(entry.target);
        continue;
      }
      const instanceRules = this._getRulesForElement(element);
      for (const instanceRule of instanceRules) {
        const isMatch = match(instanceRule.rule?.query, {
          width: width + 'px',
          height: height + 'px'
        });
        if (instanceRule.target.instance[instanceRule.rule?.key] !== isMatch) {
          this._ngZone.run(() => {
            instanceRule.target.instance[instanceRule.rule?.key] = isMatch;
            if (instanceRule.target.changeDetector) {
              instanceRule.target.changeDetector.markForCheck();
              instanceRule.target.changeDetector.detectChanges();
            } else {
              console.error(`ContainerMediaQuery: changeDetector not registered for '${instanceRule.target.element.tagName.toLowerCase()}'`);
            }
          });
        }
      }
    }
  });
  windowResizeObserver(func) {
    const _window = window;
    if (_window?.ResizeObserver) {
      return new _window.ResizeObserver(func);
    }
    return {
      observe: () => {},
      unobserve: () => {}
    };
  }
  /**
   * Registers the component on which media queries need to be calculated.
   *
   * ```ts
   * @Component({ selector: 'app-parent', template: '<app-child></app-child>' })
   * class ParentComponent {}
   *
   * @Component({ selector: 'app-child' })
   * class ChildComponent {
   *   @MediaQuery('app-parent:(max-width: 20rem)') @HostBinding('class.parentSmall') parentSmall = false;
   *   @MediaQuery('(max-width: 20rem)') @HostBinding('class.childSmall') childSmall = false;
   *
   *   constructor(
   *     resize: ObserveResizeService,
   *     elementRef: ElementRef,
   *     changeDetector: ChangeDetectorRef
   *   ) {
   *     resize.register(this, elementRef, changeDetector);
   *   }
   * }
   * ```
   *
   * @param instance the current component instance (this)
   * @param injector the Injector of the current component
   */
  register(instance, elementRef, changeDetector) {
    this._targets.push({
      element: elementRef.nativeElement,
      instance,
      changeDetector
    });
    const rules = Reflect.get(instance, MEDIA_QUERY_TEMPLATE_RULES);
    for (const rule of rules) {
      if (rule.selector) {
        let parent;
        if (rule.selector === '$parent') {
          parent = elementRef.nativeElement.parentElement;
        } else {
          parent = elementRef.nativeElement.closest(rule.selector);
        }
        if (parent) {
          this._observe(parent);
        } else {
          console.error(`ContainerMediaQuery: unable to find parent '${rule.selector}' for '${elementRef.nativeElement?.tagName?.toLowerCase()}'`);
        }
      } else {
        this._observe(elementRef?.nativeElement);
      }
    }
  }
  _remove(element) {
    const index = this._targets.findIndex(target => target.element === element);
    this._targets.splice(index, 1);
    this._unobserve(element);
    this._removeElementsWithoutListeners();
  }
  _removeElementsWithoutListeners() {
    for (const element of this._observedElements) {
      let hasListeners = false;
      for (const target of this._targets) {
        const rules = Reflect.get(target.instance, MEDIA_QUERY_TEMPLATE_RULES);
        for (const rule of rules) {
          if (rule.selector) {
            const parentElement = target.element.closest(rule.selector);
            if (parentElement === element) {
              hasListeners = true;
              break;
            }
          } else if (element === target.element) {
            hasListeners = true;
            break;
          }
        }
        if (hasListeners) {
          break;
        }
      }
      if (!hasListeners) {
        this._unobserve(element);
      }
    }
  }
  _getRulesForElement(element) {
    const parentRules = [];
    for (const target of this._targets) {
      const rules = Reflect.get(target.instance, MEDIA_QUERY_TEMPLATE_RULES);
      if (!rules) {
        if (isDevMode()) {
          console.warn(`ContainerMediaQuery: '${target.element.tagName}' is registered but does not have a @MediaQuery`);
        }
        continue;
      }
      for (const rule of rules) {
        if (rule.selector) {
          const parentElement = target.element.closest(rule.selector);
          if (parentElement === element) {
            parentRules.push({
              target,
              rule
            });
          }
        } else if (element === target.element) {
          parentRules.push({
            target,
            rule
          });
        }
      }
    }
    return parentRules;
  }
  _entrySize(entry) {
    let width = entry.contentRect.width;
    let height = entry.contentRect.height;
    if ('borderBoxSize' in entry && entry.borderBoxSize.length > 0) {
      width = entry.borderBoxSize[0].inlineSize;
      height = entry.borderBoxSize[0].blockSize;
    }
    return {
      width,
      height
    };
  }
  _unobserve(element) {
    const index = this._observedElements.findIndex(el => el === element);
    if (index >= 0) {
      this._observedElements.splice(index, 1);
    }
    this._resizeObserver.unobserve(element);
  }
  _observe(element) {
    const index = this._observedElements.findIndex(el => el === element);
    if (index < 0) {
      this._observedElements.push(element);
      this._resizeObserver.observe(element);
    }
  }
  static ɵfac = function ObserveResizeService_Factory(t) {
    return new (t || ObserveResizeService)(i0.ɵɵinject(i0.NgZone));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ObserveResizeService,
    factory: ObserveResizeService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ObserveResizeService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i0.NgZone
  }], null);
})();

/**
 * MediaQuery Decorator
 *
 * ```ts
 * @Component({ ... })
 * class ChildComponent {
 *   @MediaQuery('app-parent:(max-width: 20rem)') @HostBinding('class.parentSmall') parentSmall = false;
 *   @MediaQuery('(max-width: 20rem)') @HostBinding('class.childSmall') childSmall = false;
 *
 *   constructor(
 *     resize: ObserveResizeService,
 *     elementRef: ElementRef,
 *     changeDetector: ChangeDetectorRef
 *   ) {
 *     resize.register(this, elementRef, changeDetector);
 *   }
 * }
 * ```
 * */
function MediaQuery(rule) {
  return (target, key) => {
    const rules = Reflect.get(target, MEDIA_QUERY_TEMPLATE_RULES) || [];
    const queryStartIndex = rule.indexOf('(');
    if (queryStartIndex >= 0) {
      let selector = rule.substring(0, queryStartIndex);
      selector = selector.trim();
      selector = selector.substring(0, selector.length - 1);
      const query = rule.substring(queryStartIndex);
      rules.push({
        selector,
        query,
        key
      });
    } else {
      rules.push({
        query: rule,
        key
      });
    }
    Reflect.set(target, MEDIA_QUERY_TEMPLATE_RULES, rules);
  };
}

/*
 * Public API Surface of angular-container-media-query
 */

/**
 * Generated bundle index. Do not edit.
 */

export { MediaQuery, ObserveResizeService };
