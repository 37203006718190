import {Component, Input} from '@angular/core';
import {ProductRepresentation} from '@aztrix/sdk';

import {AbstractSelectSearch} from '../abstract-select-search';

@Component({
  selector: 'ax-product-select-search',
  templateUrl: './product-select-search.component.html',
  styleUrls: ['./product-select-search.component.scss'],
})
export class ProductSelectSearchComponent extends AbstractSelectSearch {
  @Input() products: ProductRepresentation[];
}
