import {Component, Input} from '@angular/core';

import {ValueEdit} from '../../value-edit';

@Component({
  selector: 'ax-text-edit',
  templateUrl: './text-edit.component.html',
  styleUrls: [
    './text-edit.component.scss',
    '../../../property-edit/show-icon.scss',
    '../../placeholder.scss',
  ],
})
export class TextEditComponent extends ValueEdit {
  @Input() inputType: 'text' | 'url' | 'email' | 'number' = 'text';
  @Input() numberConfig?: {min?: number; max?: number; step?: number};

  get defaultAutocompleteAttr() {
    switch (this.inputType) {
      case 'email':
        return 'email';
      case 'url':
        return 'url';
      default:
        return 'on';
    }
  }
}
