import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AgreementCellViewModule} from '@aztrix/components/row-cell-view';
import {TranslatePipe} from '@aztrix/translate';

import {SelectSearchModule} from '../select-search/select-search.module';
import {ProposalAgreementsSelectSearchComponent} from './proposal-agreements-select-search.component';
import {ProposalByIdPipe} from './proposal-by-id.pipe';

@NgModule({
  declarations: [ProposalAgreementsSelectSearchComponent, ProposalByIdPipe],
  imports: [CommonModule, SelectSearchModule, TranslatePipe, AgreementCellViewModule],
  exports: [ProposalAgreementsSelectSearchComponent],
})
export class ProposalAgreementsSelectSearchModule {}
