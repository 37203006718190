<img
  loading="lazy"
  *ngIf="fallbackSource && ((error$ | async) || !_source)"
  class="image"
  [style.border-radius]="round || rounded ? (round ? '50%' : '10px') : undefined"
  [src]="_fallbackSource"
  [attr.alt]="alt"
/>
<ng-container *ngIf="(error$ | async) || !_source">
  <ng-content></ng-content>
</ng-container>
<img
  *ngIf="_source"
  loading="lazy"
  class="image origin"
  [style.border-radius]="round || rounded ? (round ? '50%' : '10px') : undefined"
  [ngClass]="{'actual-size': actualSize}"
  [class.visible]="_source && (error$ | async) === false"
  [src]="_source"
  [attr.alt]="alt"
  (error)="error$.next(true)"
/>
