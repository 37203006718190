import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {equals} from '@aztrix/helpers';
import {
  Agreement,
  AgreementConfirmation,
  AgreementStatus,
  AgreementVerification,
  Proposal,
} from '@aztrix/models';
import {ProposalsService} from '@aztrix/sdk';
import {distinctUntilChanged, map, Observable, ReplaySubject, shareReplay, switchMap} from 'rxjs';

import {AbstractSelectSearch} from '../abstract-select-search';

@Component({
  selector: 'ax-proposal-agreements-select-search',
  templateUrl: 'proposal-agreements-select-search.component.html',
  styleUrls: ['./proposal-agreements-select-search.component.scss'],
})
export class ProposalAgreementsSelectSearchComponent
  extends AbstractSelectSearch
  implements OnChanges
{
  @Input() proposals?: Proposal[];

  private _proposals$ = new ReplaySubject<Proposal[]>(1);

  constructor(private _proposalsService: ProposalsService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.proposals && this.proposals) {
      this._proposals$.next(this.proposals);
    }
  }

  searchCallback$ = (_options: Agreement[], query: string): Observable<Agreement[]> => {
    return this._proposals$.pipe(
      distinctUntilChanged((p1, p2) => equals(p1, p2)),
      switchMap((proposals) => {
        const searchOptions = {
          agreementStatus: AgreementStatus.AGREEMENTSTATUS_UNDEFINED,
          agreementConfirmation: AgreementConfirmation.AGREEMENTCONFIRMATION_UNDEFINED,
          agreementVerification: AgreementVerification.AGREEMENTVERIFICATION_UNDEFINED,
          limit: 20,
          query: query,
        };

        let observable = this._proposalsService.searchAgreements(searchOptions);

        if (proposals.length === 1) {
          observable = this._proposalsService.searchAgreementsOfProposal(
            proposals[0].id || '',
            searchOptions
          );
        }

        return observable.pipe(map((result) => <Agreement[]>result.results));
      }),
      shareReplay(1)
    );
  };
}
