<ax-row-cell-view class="row-cell-view">
  <div class="row-cell-view-left">
    <div class="avatar-wrapper">
      <ax-avatar-profile [profile]="group"></ax-avatar-profile>
    </div>
  </div>
  <div class="row-cell-view-middle">
    <span *ngIf="group" class="property-value">{{ group | displayName }}</span>
  </div>
  <div class="row-cell-view-right">
    <ng-content></ng-content>
  </div>
</ax-row-cell-view>
