import {Component, HostBinding} from '@angular/core';

import {AbstractPropertyEdit} from '../abstract-property-edit';

@Component({
  selector: 'ax-category-edit',
  templateUrl: './category-edit.component.html',
  styleUrls: ['category-edit.component.scss', '../show-icon.scss'],
})
export class CategoryEditComponent extends AbstractPropertyEdit {
  @HostBinding('class.showIcon') get showIconClass() {
    return this.showIcon;
  }
}
