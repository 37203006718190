<ax-icon
  *ngIf="showIcon"
  class="icon type-icon"
  [name]="form.get('type').value | propertyIcon"
></ax-icon>
<div>
  <ax-file-value-edit
    [fileControl]="fileControl"
    [nameControl]="nameControl"
    [label]="label | translate"
    [hint]="hint"
    [acceptTypes]="acceptTypes"
    [maxFileSize]="maxFileSize"
    [uploadLink]="uploadLink"
    [required]="required"
    [preview]="preview"
    [previewUrl]="previewUrl"
    [demo]="demo"
  ></ax-file-value-edit>
</div>
