import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {hasValue, TomtomService} from '@aztrix/helpers';
import {Property, PropertyType, TomTomIdxType} from '@aztrix/models';
import {PropertyRepresentation} from '@aztrix/sdk';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from 'rxjs';

import {AutocompletionComponent} from '../autocompletion.component';

export interface ResultsGroup {
  title: string;
  results$: Observable<Property[]> | Observable<string[]>;
}

export enum AutocompleteType {
  ADDRESS = 'ADDRESS',
  STREET = 'STREET',
  MANUAL = 'MANUAL',
}

@Component({
  selector: 'ax-address-autocompletion',
  templateUrl: './address-autocompletion.component.html',
  styleUrls: ['../autocompletion.component.scss'],
})
export class AddressAutocompletionComponent extends AutocompletionComponent implements OnChanges {
  @Input() properties: (Property | PropertyRepresentation)[];
  @Input() propertyType: PropertyType = PropertyType.ADDRESS;
  @Input() showManualOption = false;

  results$: Subject<Property[]> = new ReplaySubject(1);
  loading$ = new BehaviorSubject(false);

  showManualOption$ = new BehaviorSubject<boolean>(false);

  properties$ = new ReplaySubject<Property[]>(1);

  constructor(private _tomTom: TomtomService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.properties && this.properties) {
      this.properties$.next(this.properties.filter((p) => hasValue(p)));
    }
  }

  autocomplete({query, country}: {query: string; country?: string}) {
    this.loading$.next(true);
    if (!query || typeof query !== 'string') {
      this.loading$.next(false);
      this.results$.next([]);
      return;
    }

    const types =
      this.propertyType === PropertyType.ADDRESS
        ? [TomTomIdxType.POINT_ADDRESS, TomTomIdxType.RANGE_ADDRESS, TomTomIdxType.STREET]
        : [TomTomIdxType.GEOGRAPHY];

    this._tomTom.search(query, country || 'BE', types).subscribe({
      next: (results) => {
        this.results$.next(results);
        this.showManualOption$.next(this.showManualOption);
      },
      error: () => {
        this.results$.next([]);
        this.loading$.next(false);
      },
      complete: () => {
        this.loading$.next(false);
      },
    });
  }
}
