import {Component, Input} from '@angular/core';
import {Group} from '@aztrix/models';

@Component({
  selector: 'ax-group-cell-view',
  templateUrl: './group-cell-view.component.html',
  styleUrls: ['./group-cell-view.component.scss', '../cell-view.scss'],
})
export class GroupCellViewComponent {
  @Input() group: Group;
}
