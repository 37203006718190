import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {DateFnsDateAdapter, MAT_DATE_FNS_DATE_FORMATS} from '@aztrix/helpers';

@Component({
  selector: 'ax-date-input',
  templateUrl: './date-input.component.html',
  providers: [
    {provide: DateAdapter, useClass: DateFnsDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_DATE_FORMATS},
  ],
})
export class DateInputComponent {
  @Input() control: FormControl<any>;
  @Input() label: string;
  @Input() min: Date | undefined;
  @Input() max: Date | undefined;
}
