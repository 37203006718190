import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'ax-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagePreviewComponent {
  @Input() source: string | SafeUrl | null;
  @Input() name?: string;
}
