<ng-template #view>
  <ax-address-value-view
    *ngIf="property | propertyIsAddressRelated"
    [property]="property"
    [showVerificationLabel]="showVerificationLabel"
    [clickable]="false"
  ></ax-address-value-view>
  <ax-default-value-view
    *ngIf="!(property | propertyIsAddressRelated)"
    [property]="property"
    [showVerificationLabel]="showVerificationLabel"
    [clickable]="false"
  ></ax-default-value-view>
</ng-template>

<ng-container *ngIf="clickable; else view">
  <a
    class="c-card__property__link"
    [href]="property | propertyCommunicationHref: undefined : params || undefined"
    [target]="property | propertyCommunicationTarget"
    (click)="trackClick()"
  >
    <ng-container *ngTemplateOutlet="view"></ng-container>
  </a>
</ng-container>
