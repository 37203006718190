import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {OverlayService} from '@aztrix/components/overlay';
import {ImageCroppedEvent} from 'ngx-image-cropper';

import {AvatarCropperModalComponent} from '../avatar-cropper-modal/avatar-cropper-modal.component';
import {AvatarType} from '../avatar-type';

@Component({
  selector: 'ax-avatar-edit',
  templateUrl: 'avatar-edit.component.html',
  styleUrls: ['avatar-edit.component.scss'],
})
export class AvatarEditComponent {
  @Input() name: string;
  @Input() avatarURL?: string;
  @Input() canDelete = false;
  @Input() delete: () => void;
  @Input() type: AvatarType;
  @Input() update: (base64: string | undefined) => void;

  @ViewChild('fileInput') fileInputElement: ElementRef;

  error = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  AvatarType = AvatarType;

  constructor(
    private _overlay: OverlayService,
    private _elementRef: ElementRef
  ) {}

  fileSelected(event: Event): void {
    this.error = false;
    const acceptedFileTypes = ['image/jpeg', 'image/png'];
    const file: File = ((<HTMLInputElement>event.target).files || [])[0];
    if (!acceptedFileTypes.includes(file.type)) {
      this.error = true;
    } else {
      this._overlay.createModal(this._elementRef, AvatarCropperModalComponent, {
        title: 'modal.avatar.title',
        init: (avatarModal) => {
          avatarModal.isIndividual =
            this.type === AvatarType.INDIVIDUAL || this.type === AvatarType.ALIST;
          avatarModal.imageChangedEvent = event;
          avatarModal.didFinishCropping = (croppedImage: ImageCroppedEvent) => {
            this.update(croppedImage.base64 || undefined);
            this.fileInputElement.nativeElement.value = '';
          };
        },
      });
    }
  }
}
