<span class="bank">
  <span class="bank-label">IBAN:</span>
  <span class="h-ellipsis bank-content">{{
    propertyOfType(PropertyType.IBAN) | propertyDisplayValue
  }}</span>
  <ax-icon
    class="icon green-text"
    name="custom:verified"
    [matTooltip]="
      'property.validation.valid' | translate: {propertyType: PropertyType.IBAN | propertyTypeLabel}
    "
  >
  </ax-icon>
</span>

<span class="bank">
  <span class="bank-label">BIC:</span>
  <span class="h-ellipsis bank-content">{{
    propertyOfType(PropertyType.BIC) | propertyDisplayValue
  }}</span>
</span>
