<ng-template #option let-result>
  {{ result?.name }}
</ng-template>

<ax-select-search
  [form]="form"
  [options]="products"
  [placeholder]="'label.product' | translate"
  [optionTemplate]="option"
  [selectedValueTemplate]="option"
  [multiple]="multiple"
  [keys]="['name']"
  [valueCallback]="valueCallback"
  [disabled]="disabled"
  [label]="label ? label : ('label.product' | translate)"
></ax-select-search>
