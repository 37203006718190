<div class="proposal-value-view">
  <ax-row-cell-view class="row-cell-view">
    <div class="row-cell-view-left">
      <div class="avatar-wrapper">
        <ax-avatar
          [type]="AvatarType.PROPOSAL"
          [iconColor]="'avatar-bg--green'"
          icon="file-document-edit"
          [source]="avatarSource"
          [round]="false"
        ></ax-avatar>
      </div>
    </div>
    <div class="row-cell-view-middle">
      <span *ngIf="proposal" class="property-value">{{ proposal | displayName }}</span>
    </div>
    <div class="row-cell-view-right">
      <ng-content></ng-content>
    </div>
  </ax-row-cell-view>
</div>
