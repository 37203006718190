<mat-autocomplete
  class="autocomplete"
  #autocompletor="matAutocomplete"
  (optionSelected)="selectedResult($event)"
>
  <ng-container *ngIf="(results$ | async) && (loading$ | async) === false; else loading">
    <ng-container *ngIf="(results$ | async)?.length">
      <mat-option
        *ngFor="let result of results$ | async"
        class="autocomplete-option"
        [value]="result | propertyDisplayValue"
      >
        {{ result | propertyDisplayValue }}
      </mat-option>
    </ng-container>
  </ng-container>
</mat-autocomplete>

<ng-template #loading>
  <ax-spinner></ax-spinner>
</ng-template>
