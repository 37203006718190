import {Pipe, PipeTransform} from '@angular/core';

import {ValueByOptionPipe} from './value-by-option.pipe';

@Pipe({
  name: 'optionByValue',
})
export class OptionByValuePipe implements PipeTransform {
  transform(
    value: unknown,
    options: unknown[],
    valueCallback?: (option: unknown) => unknown
  ): unknown {
    if (!valueCallback) {
      return value;
    }
    const valueByOptionPipe = new ValueByOptionPipe();
    return (options || []).find(
      (option) => valueByOptionPipe.transform(option, valueCallback) === value
    );
  }
}
