<ax-value-verified-view
  [property]="property"
  [parentProperty]="parentProperty"
  [showVerificationLabel]="showVerificationLabel"
>
  <div class="day-ranges">
    <ng-container *ngFor="let openingDate of openingDates">
      <ax-opening-hours-date-view
        [dates]="openingDate.dates"
        [text]="openingDate.openingDays[0].text"
      ></ax-opening-hours-date-view>
      <div class="days" *ngIf="openingDate.openingDays !== []; else empty">
        <ng-container *ngFor="let openingDay of openingDate.openingDays">
          <ax-opening-hours-day-view
            class="day"
            [days]="[openingDay.day]"
          ></ax-opening-hours-day-view>
          <ng-container *ngIf="!openingDay.open">
            <span class="closed">{{ 'label.closed' | translate | uppercase }}</span>
          </ng-container>
          <ng-container *ngIf="openingDay.open">
            <ng-container
              *ngFor="let range of openingDay.ranges; let first = first; let last = last"
            >
              <span *ngIf="!first"></span>
              <span class="from" [ngClass]="{last: last}">{{ range.from | formatTime }}</span>
              <span class="spacer" [ngClass]="{last: last}"> - </span>
              <span class="to" [ngClass]="{last: last}">{{ range.to | formatTime }}</span>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <ng-template #empty>
        <span>{{ 'property.value.empty' | translate }}</span>
      </ng-template>
    </ng-container>
  </div>
</ax-value-verified-view>
