<ng-template #option let-result>
  <ax-property-value-view [property]="result"></ax-property-value-view>
  <ax-icon *ngIf="result?.visibility === 'PRIVATE'" class="icon" name="eye-off"></ax-icon>
</ng-template>
<ng-template #selectedViewTemplate let-result>
  <ax-property-value-view [property]="result"></ax-property-value-view>
</ng-template>

<ax-select-search
  [form]="form"
  [options]="properties"
  [placeholder]="
    placeholder && placeholder !== ''
      ? placeholder
      : ((multiple ? 'label.properties' : 'label.property') | translate)
  "
  [optionTemplate]="option"
  [selectedValueTemplate]="selectedViewTemplate"
  [multiple]="multiple"
  [valueCallback]="valueCallback"
  [canAddNew]="canAddNew"
  [label]="label ? label : ((multiple ? 'label.properties' : 'label.property') | translate)"
></ax-select-search>
