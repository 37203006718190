import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {Component, OnInit, ViewChild} from '@angular/core';

import {ValueEdit} from '../../value-edit';

@Component({
  selector: 'ax-textarea-edit',
  templateUrl: './textarea-edit.component.html',
  styleUrls: [
    './textarea-edit.component.scss',
    '../../../property-edit/show-icon.scss',
    '../../placeholder.scss',
  ],
})
export class TextareaEditComponent extends ValueEdit implements OnInit {
  @ViewChild('autoSizer') autoSizer: CdkTextareaAutosize;

  ngOnInit() {
    setTimeout(() => {
      this.autoSizer.resizeToFitContent(true);
    }, 500);
  }
}
