import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ButtonModule} from '@aztrix/components/button';
import {ImageSourceModule} from '@aztrix/components/image-source';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {ImagePreviewComponent} from './image-preview/image-preview.component';
import {ImagePreviewButtonComponent} from './image-preview-button.component';

@NgModule({
  declarations: [ImagePreviewButtonComponent, ImagePreviewComponent],
  imports: [CommonModule, ButtonModule, ImageSourceModule, TranslatePipe, IconsModule],
  exports: [ImagePreviewButtonComponent],
})
export class ImagePreviewButtonModule {}
