import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AgreementCellViewModule} from '@aztrix/components/row-cell-view';
import {TranslatePipe} from '@aztrix/translate';

import {SelectSearchModule} from '../select-search/select-search.module';
import {AgreementSelectSearchComponent} from './agreement-select-search.component';

@NgModule({
  declarations: [AgreementSelectSearchComponent],
  imports: [CommonModule, SelectSearchModule, TranslatePipe, AgreementCellViewModule],
  exports: [AgreementSelectSearchComponent],
})
export class AgreementSelectSearchModule {}
