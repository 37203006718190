import {ChangeDetectionStrategy, Component} from '@angular/core';
import {AddressFormats, AddressLineRepresentation, subPropertyOfType} from '@aztrix/helpers';
import {Property} from '@aztrix/models';

import {AbstractPropertyValueViewComponent} from '../../abstract-property-value-view.component';

@Component({
  selector: 'ax-address-value-view',
  templateUrl: 'address-value-view.component.html',
  styleUrls: [
    '../../abstract-property-value-view.component.scss',
    'address-value-view.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressValueViewComponent extends AbstractPropertyValueViewComponent {
  get addressLineProperties(): Property[][] {
    return <Property[][]>(
      AddressFormats.addressFormatForProperty(this.property).map(
        (line: AddressLineRepresentation) =>
          line
            .map((lineInfo) => subPropertyOfType(this.property, lineInfo.propertyType))
            .filter((prop) => !!prop)
      )
    );
  }
}
