import {Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {CanColor, CanColorCtor, mixinColor} from '@aztrix/components/button';

class ChipBase {
  constructor(public _elementRef: ElementRef) {}
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const _ChipMixinBase: CanColorCtor & typeof ChipBase = mixinColor(ChipBase);

@Directive({
  selector: '[axChip]',
})
export class ChipDirective extends _ChipMixinBase implements CanColor {
  @Input() override color: string;

  constructor(el: ElementRef, renderer: Renderer2) {
    super(el);
    renderer.addClass(el.nativeElement, 'ax-chip');
  }
}
