import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
} from '@angular/core';
import {AbstractControl, ValidationErrors} from '@angular/forms';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {CountrySelectorModalComponent} from '@aztrix/components/country-selector-modal';
import {OverlayService} from '@aztrix/components/overlay';
import {
  CountryCodeRepresentation,
  CustomValidators,
  DEFAULT_COUNTRY,
  getEuCountries,
  getRegexForProperty,
  hasValue,
  isEuCountry,
  PropertyFormControl,
  subPropertyOfType,
  VatCheckerApiService,
} from '@aztrix/helpers';
import {PropertyRepresentation} from '@aztrix/sdk';
import {ControlsOf, FormGroup} from '@ngneat/reactive-forms';
import {BehaviorSubject, NEVER, of, Subscription} from 'rxjs';
import {debounceTime, map, mergeMap, startWith} from 'rxjs/operators';

import {AbstractPropertyEdit} from '../abstract-property-edit';

@Component({
  selector: 'ax-legalid-edit',
  templateUrl: './legalid-edit.component.html',
  styleUrls: ['legalid-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegalIdEditComponent
  extends AbstractPropertyEdit
  implements OnInit, OnChanges, OnDestroy
{
  @Input() matAutocomplete: MatAutocomplete;

  legalIdForm = PropertyFormControl.createByType(PropertyRepresentation.TypeEnum.COMPANY_NUMBER);

  subscriptionsOnInit = new Subscription();
  subscriptionsOnChanges = new Subscription();
  vatState: 'VALID' | 'INVALID' | 'PENDING' | undefined = undefined;

  constructor(
    private _vatCheckerApiService: VatCheckerApiService,
    private _changeDetector: ChangeDetectorRef,
    private _overlay: OverlayService,
    private _elementRef: ElementRef,
    @Optional()
    @Inject(DEFAULT_COUNTRY)
    private _defaultCountry$: BehaviorSubject<CountryCodeRepresentation>
  ) {
    super();
  }

  static propertyRequired(control: AbstractControl): ValidationErrors | null {
    const {companyNumber} = LegalIdEditComponent.splitLegalId(control.value);
    return companyNumber
      ? null
      : {propertyRequired: {type: PropertyRepresentation.TypeEnum.LEGAL_ID}};
  }

  static propertyInvalid(control: AbstractControl): ValidationErrors | null {
    const {jurisdiction, companyNumber} = LegalIdEditComponent.splitLegalId(control.value);
    const legalidProperty = <PropertyRepresentation>{
      type: PropertyRepresentation.TypeEnum.LEGAL_ID,
      properties: [
        {
          type: PropertyRepresentation.TypeEnum.JURISDICTION,
          value: jurisdiction || null,
        },
        {
          type: PropertyRepresentation.TypeEnum.COMPANY_NUMBER,
          value: companyNumber || null,
        },
      ],
    };

    if (!hasValue(legalidProperty)) {
      return null;
    }

    const legalidForm = PropertyFormControl.createByType(PropertyRepresentation.TypeEnum.LEGAL_ID);
    PropertyFormControl.setValue(legalidForm, legalidProperty);
    const propertiesForm = <FormGroup<ControlsOf<PropertyRepresentation>>[]>(
      legalidForm.get('properties')?.controls
    );

    if (!propertiesForm) {
      return null;
    }

    const companyNumberControl = propertiesForm.find(
      (propertyForm) =>
        propertyForm.get('type')?.value === PropertyRepresentation.TypeEnum.COMPANY_NUMBER
    );
    return CustomValidators.propertyValid(companyNumberControl?.get('value'));
  }

  static splitLegalId(legalId: string | undefined): {jurisdiction?: string; companyNumber: string} {
    if (!legalId || legalId.length < 1) {
      return {companyNumber: ''};
    }

    const jurisdiction = legalId.substring(0, 2);
    if (isEuCountry(jurisdiction)) {
      return {jurisdiction, companyNumber: legalId.substring(2)};
    } else {
      return {companyNumber: legalId};
    }
  }

  override ngOnInit() {
    super.ngOnInit();

    this.subscriptionsOnInit?.unsubscribe();
    this.subscriptionsOnInit = new Subscription();

    this.subscriptionsOnInit.add(
      this.form.touch$.subscribe(() => {
        this.legalIdForm.markAsTouched();
        this._changeDetector.markForCheck();
      })
    );

    this.subscriptionsOnInit.add(
      this.legalIdForm.touch$.subscribe(() => {
        this.form.markAsTouched();
      })
    );

    if (this._defaultCountry$) {
      this.subscriptionsOnInit.add(
        this._defaultCountry$.subscribe((value) => {
          if (this.form.untouched) {
            this._setJurisdiction(value.countryCode);
          }
        })
      );
    }
  }

  override ngOnChanges(changes: SimpleChanges) {
    if (changes.form && this.form) {
      this.legalIdValueForm?.setValidators([
        this.required ? LegalIdEditComponent.propertyRequired : () => null,
        LegalIdEditComponent.propertyInvalid,
        CustomValidators.propertyValid,
      ]);

      if (hasValue(this.form.value)) {
        this.legalIdValueForm?.markAsTouched();
      }

      this.subscriptionsOnChanges?.unsubscribe();
      this.subscriptionsOnChanges = new Subscription();

      this.subscriptionsOnChanges.add(
        this.form.valueChanges
          .pipe(startWith(this.form.value))
          .subscribe((property: PropertyRepresentation) => {
            if (property) {
              const jurisdiction = subPropertyOfType(
                property,
                PropertyRepresentation.TypeEnum.JURISDICTION
              )?.value;
              const companyNumber = subPropertyOfType(
                property,
                PropertyRepresentation.TypeEnum.COMPANY_NUMBER
              )?.value;

              let legalId = companyNumber;
              if (jurisdiction && isEuCountry(jurisdiction)) {
                legalId = jurisdiction + (companyNumber || '');
              }

              if (legalId !== this.legalIdValueForm?.value) {
                this.legalIdValueForm?.setValue(legalId, {emitEvent: false});
              }
            }
          })
      );

      this.subscriptionsOnChanges.add(
        this.legalIdValueForm?.valueChanges.subscribe((value) => {
          const {jurisdiction, companyNumber} = LegalIdEditComponent.splitLegalId(value);

          this._setJurisdiction(jurisdiction, {emitEvent: false});
          this._setCompanyNumber(companyNumber);

          this.form.markAsDirty();
        })
      );

      this.subscriptionsOnChanges.add(
        this.legalIdValueForm?.valueChanges
          .pipe(
            map((value) => {
              const {jurisdiction, companyNumber} = LegalIdEditComponent.splitLegalId(value);

              if (jurisdiction !== null) {
                this.vatState = 'PENDING';
              }
              this._changeDetector.markForCheck();

              return {jurisdiction, companyNumber};
            }),
            debounceTime(500),
            mergeMap(({jurisdiction, companyNumber}) => {
              if (companyNumber && jurisdiction) {
                if (isEuCountry(jurisdiction)) {
                  if (this._validateEuLegalIdWithRegex(jurisdiction, companyNumber)) {
                    return this._vatCheckerApiService.checkVat(jurisdiction, companyNumber);
                  } else {
                    return of(false);
                  }
                } else {
                  if (jurisdiction !== null) {
                    return of(this._validateNonEuLegalIdWithRegex(jurisdiction, companyNumber));
                  }
                }
              }

              this.vatState = undefined;
              this._changeDetector.markForCheck();
              return NEVER;
            })
          )
          .subscribe((valid) => {
            this.vatState = valid ? 'VALID' : 'INVALID';
            this._changeDetector.markForCheck();
          })
      );
    }

    this.registerOnDisabledChange((isDisabled: boolean) => {
      if (isDisabled) {
        if (this.legalIdForm.enabled) {
          this.legalIdForm.disable({emitEvent: false});
        }
      } else {
        if (this.legalIdForm.disabled) {
          this.legalIdForm.enable({emitEvent: false});
        }
      }
    });

    super.ngOnChanges(changes);
  }

  override ngOnDestroy() {
    this.subscriptionsOnInit.unsubscribe();
    this.subscriptionsOnChanges.unsubscribe();
    super.ngOnDestroy();
  }

  get legalIdValueForm() {
    return this.legalIdForm.get('value');
  }

  openSearchSelectCountryModal(): void {
    if (this.readonly) {
      return;
    }

    this._overlay.createModal(this._elementRef, CountrySelectorModalComponent, {
      title: 'label.country',
      init: (countryModal) => {
        countryModal.showPrefix = false;
        countryModal.showPrefixCountries = getEuCountries();
        countryModal.showMobile = false;
        countryModal.didSelectCountry = (country: CountryCodeRepresentation | undefined) => {
          if (country?.countryCode) {
            this._countrySelected(country);
          }
        };
      },
    });
  }

  private _countrySelected(country: CountryCodeRepresentation) {
    this._setJurisdiction(country.countryCode);

    if (isEuCountry(country.countryCode)) {
      const {companyNumber} = LegalIdEditComponent.splitLegalId(this.legalIdForm.value.value);

      this.legalIdForm.setValue({
        ...this.legalIdForm.value,
        value: country.countryCode + companyNumber,
      });

      this._setCompanyNumber(companyNumber);
    }

    this.form.updateValueAndValidity();
    this._changeDetector.detectChanges();
  }

  private _validateEuLegalIdWithRegex(jurisdiction: string, companyNumber: string): boolean {
    const legalId = jurisdiction + companyNumber;
    const regex = new RegExp(getRegexForProperty(PropertyRepresentation.TypeEnum.LEGAL_ID));
    return legalId.match(regex) !== null ? true : false;
  }

  private _validateNonEuLegalIdWithRegex(jurisdiction: string, companyNumber: string): boolean {
    const jurisdictionRegex = new RegExp(
      getRegexForProperty(PropertyRepresentation.TypeEnum.JURISDICTION)
    );
    const companyNumberRegex = new RegExp(
      getRegexForProperty(PropertyRepresentation.TypeEnum.COMPANY_NUMBER)
    );
    const jurisdictionMatch = jurisdiction?.match(jurisdictionRegex) !== null ? true : false;
    const companyNumberMatch = companyNumber?.match(companyNumberRegex) !== null ? true : false;
    return (jurisdictionMatch && companyNumberMatch) || false;
  }

  /* Getters */
  get jurisdictionIcon() {
    return this.jurisdictionControl?.get('value')?.value || 'world';
  }

  get vatCheckIcon(): string {
    switch (this.vatState) {
      case 'VALID':
        return 'check-circle';
      case 'INVALID':
        return 'alert-circle';
      default:
        return 'circle-slice-5';
    }
  }

  get jurisdictionControl(): AbstractControl | undefined {
    return this.propertiesControl.controls.find(
      (c) => c.get('type')?.value === PropertyRepresentation.TypeEnum.JURISDICTION
    );
  }

  get companyNumberControl(): AbstractControl | undefined {
    return this.propertiesControl.controls.find(
      (c) => c.get('type')?.value === PropertyRepresentation.TypeEnum.COMPANY_NUMBER
    );
  }

  /* Setters */
  private _setJurisdiction(
    value: string | undefined,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }
  ) {
    this.jurisdictionControl?.get('value')?.setValue(value, options);
    this._changeDetector.markForCheck();
  }

  private _setCompanyNumber(
    value: string,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    }
  ) {
    this.companyNumberControl?.get('value')?.setValue(value, options);
  }
}
