import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PropertyViewModule} from '@aztrix/components/property-view';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {SelectSearchModule} from '../select-search/select-search.module';
import {PropertySelectSearchComponent} from './property-select-search.component';

@NgModule({
  declarations: [PropertySelectSearchComponent],
  imports: [CommonModule, TranslatePipe, PropertyViewModule, SelectSearchModule, IconsModule],
  exports: [PropertySelectSearchComponent],
})
export class PropertySelectSearchModule {}
