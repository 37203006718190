import {Pipe, PipeTransform} from '@angular/core';
import {Proposal} from '@aztrix/models';

@Pipe({
  name: 'proposalById',
})
export class ProposalByIdPipe implements PipeTransform {
  transform(proposals: Proposal[] | undefined, proposalId: string): Proposal | undefined {
    return proposals?.find((p) => p.id === proposalId);
  }
}
