import {ChangeDetectionStrategy, Component, Inject, Optional} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {OverlayService} from '@aztrix/components/overlay';
import {CountryCodeRepresentation, DEFAULT_COUNTRY} from '@aztrix/helpers';
import {BehaviorSubject} from 'rxjs';
import {startWith} from 'rxjs/operators';

@Component({
  selector: 'ax-country-selector-modal',
  templateUrl: './country-selector-modal.component.html',
  styleUrls: ['./country-selector-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountrySelectorModalComponent {
  didSelectCountry?: (rep: CountryCodeRepresentation | undefined) => void;
  countryForm = new UntypedFormControl('');
  showReset = false;
  showPrefix = false;
  showPrefixCountries: string[] = [];
  showDefaultCountry = true;
  countryCodes: string[] = [];
  showMobile = true;

  constructor(
    private _overlay: OverlayService,
    @Optional()
    @Inject(DEFAULT_COUNTRY)
    public defaultCountry$: BehaviorSubject<CountryCodeRepresentation>
  ) {}

  query$ = this.countryForm.valueChanges.pipe(startWith(''));

  selectCountry(country?: CountryCodeRepresentation): void {
    if (this.didSelectCountry) {
      this.didSelectCountry(country);
      this.didSelectCountry = undefined;
    }
    this._overlay.closeModal();
  }
}
