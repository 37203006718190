import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {SnackbarType} from '@aztrix/models';
import {BehaviorSubject, Observable, timer} from 'rxjs';

@Component({
  selector: 'ax-snackbar',
  templateUrl: 'snackbar.component.html',
  styleUrls: ['snackbar.component.scss'],
})
export class SnackbarComponent implements OnChanges {
  @Input() type: SnackbarType;
  @Input() timeout: number;
  @Input() dismissible = true;
  @Input() open = true;
  @Input() icon: string;
  @Input() color: 'green' | 'red' | 'grey' | 'orange' | undefined;
  @Output() didClose = new EventEmitter();

  openSubject$ = new BehaviorSubject(true);

  private _timer: Observable<number>;

  ngOnChanges() {
    this.openSubject$.next(this.open);
    if (this.timeout > 0) {
      this._timer = timer(this.timeout);
      this._timer.subscribe(() => this.close());
    }
  }

  close(): void {
    this.openSubject$.next(false);
    if (this.type) {
      const snackBarItem = this.snackbarVisbility(`snackbar-${this.type}`, false);
      if (!snackBarItem) {
        localStorage.setItem(`snackbar-${this.type}`, 'false');
      }
    }

    this.didClose.emit();
  }

  get isSnackbarAlreadyClosed(): boolean {
    return !!localStorage.getItem(`${'snackbar-'}${this.type}`)?.length;
  }

  get backgroundColorClass(): string | undefined {
    switch (this.color) {
      case 'green':
        return 'm-snackbar--green';
      case 'red':
        return 'm-snackbar--red';
      case 'grey':
        return 'm-snackbar--grey';
      case 'orange':
        return 'm-snackbar--orange';
      default:
        return undefined;
    }
  }

  snackbarVisbility(type: string, value?: boolean): boolean {
    if (value != null) {
      localStorage.setItem(type, JSON.stringify(value));
    }
    const currentValue = localStorage.getItem(type);
    return currentValue == null ? true : JSON.parse(currentValue);
  }
}
