import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import {FormControl, UntypedFormControl} from '@angular/forms';
import {fuzzySort} from '@aztrix/helpers';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {distinctUntilChanged, startWith, switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'ax-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
})
export class SelectSearchComponent implements OnChanges {
  @Input() form = new FormControl();
  @Input() options?: any[] | null;
  @Input() placeholder: string;
  @Input() optionTemplate: TemplateRef<unknown>;
  @Input() selectedValueTemplate: TemplateRef<unknown>;
  @Input() multiple? = false;
  @Input() label: string;
  @Input() keys?: string[];
  @Input() canAddNew = false;
  @Input() required = false;
  @Input() appearance: 'fill' | 'outline' = 'outline';
  @Input() errorsTemplate: TemplateRef<unknown>;
  @Input() prefixTemplate: TemplateRef<unknown>;
  @Input() disabled = false;
  @Input() valueCallback?: (option: any) => any;
  @Input() searchCallback$?: (options: any[], query: string) => Observable<(any | undefined)[]>;
  @Output() addNewValue = new EventEmitter();

  formFilterControl = new UntypedFormControl('');

  options$ = new BehaviorSubject<unknown[]>([]);

  searchedOptions: unknown[] = [];

  searchResults$ = combineLatest([
    this.options$,
    this.formFilterControl.valueChanges.pipe(startWith('')),
  ]).pipe(
    distinctUntilChanged(),
    switchMap(([options, query]) => {
      if (this.searchCallback$) {
        return this.searchCallback$(options, query);
      } else {
        return of(fuzzySort(options, query, this.keys || []));
      }
    }),
    tap((options) => {
      if (!this.options?.length) {
        this.searchedOptions = options;
        if (
          !this.searchedOptions.find((o) => {
            if (this.valueCallback) {
              return this.valueCallback(o) === this.form.value;
            } else {
              return o === this.form.value;
            }
          })
        ) {
          this.form.reset();
        }
      }
    })
  );

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && this.options && Array.isArray(this.options)) {
      this.options$.next(this.options);
      this.searchedOptions = this.options;
    }
  }

  removeSelection(value?: unknown) {
    if (Array.isArray(this.form.value)) {
      const selectedOptions = this.form.value;
      const newOptions = selectedOptions.filter((option) => option !== value);
      this.form.setValue(newOptions);
    } else {
      this.form.setValue(undefined);
    }
  }

  clearValue() {
    this.form.setValue(null);
  }
}
