<div>
  <ax-default-value-view
    [property]="propertyOfType(PropertyType.COMPANY_NUMBER)"
    [parentProperty]="property"
    [clickable]="clickable"
  ></ax-default-value-view>
</div>
<div *ngIf="propertyOfType(PropertyType.JURISDICTION)?.value">
  <ax-default-value-view
    [property]="propertyOfType(PropertyType.JURISDICTION)"
    [clickable]="clickable"
  ></ax-default-value-view>
</div>
