import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from '@angular/core';
import {OverlayService} from '@aztrix/components/overlay';
import {ImageCroppedEvent} from 'ngx-image-cropper';

@Component({
  selector: 'ax-avatar-cropper-modal',
  templateUrl: 'avatar-cropper-modal.component.html',
  styleUrls: ['avatar-cropper-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarCropperModalComponent {
  @Input() isIndividual = false;
  didFinishCropping: (croppedImage: ImageCroppedEvent) => void;

  imageChangedEvent: Event;
  private croppedImage: ImageCroppedEvent;

  constructor(private _changeDetector: ChangeDetectorRef, private _overlay: OverlayService) {}

  public imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
    this._changeDetector.markForCheck();
  }

  public save(): void {
    this._overlay.closeModal();
    this.didFinishCropping(this.croppedImage);
  }
}
