import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DAYS_OF_WEEK} from '@aztrix/helpers';
import {parse} from 'date-fns';

@Component({
  selector: 'ax-days-select',
  templateUrl: 'days-select.component.html',
  styleUrls: ['days-select.component.scss'],
})
export class DaysSelectComponent {
  @Input() days: string[] = DAYS_OF_WEEK.slice();
  @Output() daysChange = new EventEmitter<string[]>();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  DAYS_OF_WEEK = DAYS_OF_WEEK;

  getDayDateFormat(day: string): Date {
    return parse(day, 'EEEEEE', new Date());
  }

  selected(day: string): boolean {
    return !!this.days.find((d) => d === day);
  }

  toggle(day: string) {
    if (this.selected(day)) {
      this.days.splice(this.days.indexOf(day), 1);
    } else {
      this.days.push(day);
    }

    if (this.days.length <= 0) {
      this.days = DAYS_OF_WEEK.slice();
    }

    this.daysChange.emit(this.days);
  }
}
