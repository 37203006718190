<div class="social-wrapper">
  <ax-icon
    [inline]="true"
    *ngIf="property | propertyIsVerified"
    [ngClass]="verificationColor()"
    class="icon c-card__property-icon"
    name="custom:verified"
    [matTooltip]="property | propertyVerificationLabel"
  ></ax-icon>
</div>

<div class="c-card__property" [ngClass]="{'has-verify-icon': showVerificationLabel}">
  <ng-content></ng-content>
  <ax-value-verified-view [property]="property" [showVerificationLabel]="showVerificationLabel">
    <a
      class="social"
      [ngClass]="property?.type | propertyIcon"
      [href]="property | propertyCommunicationHref: CommunicationType.URL : params || undefined"
      target="_blank"
      (click)="click($event)"
    >
      <ax-icon class="icon social__logo" [name]="property?.type | propertyIcon"></ax-icon>
      <span class="social__name">{{ label || (property | propertySocialName) }}</span>
    </a>
  </ax-value-verified-view>
  <ax-icon
    [inline]="true"
    *ngIf="showVerificationLabel"
    [ngClass]="verificationColor()"
    class="c-card__property-icon"
    name="custom:verified"
    [matTooltip]="parentProperty || property | propertyVerificationLabel"
  ></ax-icon>
</div>
