<mat-form-field
  *ngIf="form"
  class="c-input"
  [ngClass]="{'remove-subtext': !showErrors, readonly: readonly}"
  appearance="outline"
  (click)="openSearchSelectCountryModal($event)"
  [hideRequiredMarker]="!required"
>
  <mat-label *ngIf="customLabel">{{ customLabel }}<span *ngIf="required">&nbsp;*</span></mat-label>
  <input
    [attr.name]="getName()"
    matInput
    autocomplete="off"
    readonly
    type="text"
    [ngModel]="form.value.value | countryCodeLabel"
    [disabled]="form.disabled"
    [readonly]="readonly"
  />
  <button type="button" axButton icon-button matSuffix [disabled]="form.disabled">
    <ax-icon class="icon flag" [name]="'flags:' + form.value?.value"></ax-icon>
  </button>
  <mat-error *ngIf="showErrors"><ax-property-error [form]="form"></ax-property-error></mat-error>
  <mat-hint
    [ngClass]="{collapsed}"
    (click)="collapsed = !collapsed"
    class="hint collapsible"
    align="end"
    #hintElement
  >
    <div><ax-icon *ngIf="isOverflowing()" class="icon" name="chevron-down"></ax-icon></div>
    <span [ngClass]="{overflowing: isOverflowing()}">{{ hint }}</span>
  </mat-hint>
</mat-form-field>
