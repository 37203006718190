import {Component, Input} from '@angular/core';
import {Group} from '@aztrix/models';

import {AbstractSelectSearch} from '../abstract-select-search';

@Component({
  selector: 'ax-group-select-search',
  templateUrl: './group-select-search.component.html',
})
export class GroupSelectSearchComponent extends AbstractSelectSearch {
  @Input() groups: Group[];
}
