<ax-date-input
  [control]="dateFrom"
  [label]="'label.from' | translate"
  [min]="today"
  [max]="dateTo.value"
></ax-date-input>
<span>-</span>
<ax-date-input
  [control]="dateTo"
  [label]="'label.to' | translate"
  [min]="dateFrom.value"
></ax-date-input>
