import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {AbstractPropertyEdit} from '../abstract-property-edit';

export const IMAGE_TYPES = ['png', 'jpg', 'jpeg', 'bmp', 'svg'];

@Component({
  selector: 'ax-image-edit',
  templateUrl: './image-edit.component.html',
  styleUrls: ['./image-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageEditComponent extends AbstractPropertyEdit {
  @Input() acceptTypes: string[] = IMAGE_TYPES;
  @Input() maxFileSize: number;
  @Input() uploadLink: string;
  @Input() demo = false;
  @Input() preview = false;
  @Input() previewUrl: string;
}
