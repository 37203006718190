<div class="header">
  <div class="title">
    <ax-icon
      [ngClass]="{showIcon: showIcon}"
      class="icon type-icon"
      [name]="PropertyType.OPENING_HOURS | propertyIcon"
    ></ax-icon>
    <span class="label">
      {{ customLabel === '' ? (form.getRawValue() | propertyTypeLabel) : customLabel }}
      <ng-container *ngIf="required">*</ng-container>
      <button
        class="help-button"
        axButton
        icon-button
        (click)="openHelpModal(help); $event.preventDefault()"
      >
        <ax-icon class="icon" name="help-circle-outline"></ax-icon>
      </button>
    </span>
  </div>
  <mat-checkbox [checked]="alwaysOpenForm.value" [formControl]="alwaysOpenForm">
    {{ 'opening-hours.always-open' | translate }}
  </mat-checkbox>
  <button axButton icon-button type="button" *ngIf="hasValue && form?.enabled" (click)="clear()">
    <ax-icon class="icon" name="close"></ax-icon>
  </button>
</div>

<div class="day-ranges">
  <ng-container
    *ngFor="let openingDate of openingDates.controls; let first = first; let index = index"
  >
    <ax-day-range-edit
      [form]="openingDate"
      [name]="(name ? name + ' ' : '') + 'dayRange' + index"
      [special]="!first"
      [cleared]="!form?.value?.value"
      (editClicked)="editSpecial(openingDate)"
    ></ax-day-range-edit>
    <div
      *ngIf="
        first && (form.enabled || openingDates.controls.length > 1) && specialOpeningHoursEnabled
      "
      class="header"
    >
      <ax-icon
        [ngClass]="{showIcon: showIcon}"
        class="icon type-icon"
        [name]="PropertyType.OPENING_HOURS | propertyIcon"
      ></ax-icon>
      <span class="label">{{ 'opening-hours.special' | translate }}</span>
    </div>
  </ng-container>
</div>

<button
  *ngIf="specialOpeningHoursEnabled"
  class="add-special"
  axButton
  type="button"
  icon-button
  outline
  color="primary"
  (click)="addSpecial()"
>
  <ax-icon class="icon" name="plus"></ax-icon>
</button>
<ax-property-error *ngIf="form.touched" [form]="form"></ax-property-error>
<mat-hint
  [ngClass]="{collapsed}"
  (click)="collapsed = !collapsed"
  class="hint collapsible"
  align="end"
  #hintElement
  *ngIf="valueControl.valid || form.untouched"
>
  <div><ax-icon *ngIf="isOverflowing()" class="icon" name="chevron-down"></ax-icon></div>
  <span [ngClass]="{overflowing: isOverflowing()}">{{ hint }}</span>
</mat-hint>

<ng-template #help>
  <p>{{ 'opening-hours.help.1' | translate }}</p>
  <p>
    {{ 'opening-hours.help.2' | translate }}
    <ax-icon class="icon" name="chevron-right"></ax-icon>
    {{ 'opening-hours.help.3' | translate }}
  </p>
</ng-template>
