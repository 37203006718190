// https://github.com/KillerCodeMonkey/ngx-quill-example/blob/master/src/app/mat-quill/mat-quill-base.ts
/* eslint-disable */
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {Directive, HostBinding, Input, OnChanges, OnDestroy, Optional, Self} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroupDirective,
  NgControl,
  NgForm,
  Validator,
} from '@angular/forms';
import {
  CanDisable,
  CanUpdateErrorState,
  ErrorStateMatcher,
  mixinDisabled,
  mixinErrorState,
} from '@angular/material/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {QuillEditorBase} from 'ngx-quill';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

// Boilerplate for applying mixins to _AztrixQuillBase
class AztrixQuillBase extends QuillEditorBase {
  stateChanges = new Subject<void>();

  constructor(
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    public _parentForm: NgForm,
    public _parentFormGroup: FormGroupDirective,
    public ngControl: NgControl
  ) {
    super();
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const _AztrixQuillMixinBase = mixinErrorState(mixinDisabled(AztrixQuillBase));

@Directive()
// eslint-disable-next-line @typescript-eslint/naming-convention
export abstract class _AztrixQuillBase
  extends _AztrixQuillMixinBase
  implements
    CanDisable,
    CanUpdateErrorState,
    ControlValueAccessor,
    MatFormFieldControl<any>,
    OnChanges,
    OnDestroy,
    Validator
{
  abstract controlType: string;
  focused = false;
  abstract id: string;
  private contentChangedSubscription: Subscription;
  private blurSubscription: Subscription;
  private focusSubscription: Subscription;

  constructor(
    defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() parentForm: NgForm,
    @Optional() parentFormGroup: FormGroupDirective,
    @Optional() @Self() public override ngControl: NgControl
  ) {
    super(defaultErrorStateMatcher, parentForm, parentFormGroup, ngControl);

    if (!!this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

    this.contentChangedSubscription = this.onContentChanged
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => {
        this.updateErrorState();
        this.stateChanges.next();
      });

    this.blurSubscription = this.onBlur.subscribe(() => {
      this.focused = false;
      if (this.ngControl?.control && !this.ngControl.control.touched) {
        this.ngControl.control.markAsTouched();
        this.updateErrorState();
      }
      this.stateChanges.next();
    });

    this.focusSubscription = this.onFocus.subscribe(() => {
      this.focused = true;
      this.stateChanges.next();
    });
  }

  override ngOnDestroy() {
    this.contentChangedSubscription.unsubscribe();
    this.blurSubscription.unsubscribe();
    this.focusSubscription.unsubscribe();
    super.ngOnDestroy();
  }
  /*
   * GETTERS & SETTERS
   */

  @Input()
  override disabled = false;

  get empty() {
    return !coerceBooleanProperty(this.value);
  }

  @Input()
  override placeholder: string = '';

  @Input()
  override readOnly = false;

  @Input()
  override required = false;

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get value(): any {
    try {
      return this.valueGetter(this.quillEditor, this.editorElem!);
    } catch (e) {
      return undefined;
    }
  }
  set value(value: any) {
    this.writeValue(value);
    this.stateChanges.next();
  }

  /*
   * METHODS
   */

  blur() {
    (<NodeListOf<HTMLElement>>this.editorElem.childNodes)[0]['blur']();
  }

  focus() {
    this.quillEditor.focus();
  }

  @HostBinding('attr.aria-describedby') _describedBy = '';
  setDescribedByIds(ids: string[]) {
    this._describedBy = ids.join(' ');
  }

  onContainerClick(_event: MouseEvent) {
    if (!this.focused) {
      this.quillEditor.focus();
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static ngAcceptInputType_required: boolean | string | null | undefined;
}
