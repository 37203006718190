import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {isValidUrl} from '@aztrix/helpers';
import {Subscription} from 'rxjs';
import {startWith} from 'rxjs/operators';

import {ValueEdit} from '../../value-edit';

@Component({
  selector: 'ax-url-edit',
  templateUrl: '../text-edit/text-edit.component.html',
  styleUrls: [
    './url-edit.component.scss',
    '../../../property-edit/show-icon.scss',
    '../../placeholder.scss',
  ],
})
export class UrlEditComponent extends ValueEdit implements OnChanges, OnInit {
  private _regexp = new RegExp('^([\\dA-Za-z\\.-]+)\\.([A-Za-z\\.]{2,6})([/\\w \\.-].*)*/?$');

  inputType = 'url';
  defaultAutocompleteAttr = 'url';
  numberConfig?: {min?: number; max?: number; step?: number} = undefined;

  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.form && this.form) {
      this._subscriptions.unsubscribe();
      this._subscriptions = new Subscription();
      this._subscriptions.add(
        this.form.valueChanges.pipe(startWith(this.form.value)).subscribe((value) => {
          if (this.prefix) {
            if (typeof value === 'string') {
              const prefix = this.prefix.replace('www.', '');
              this.form.setValue(
                value
                  .replace('https://', '')
                  .replace('http://', '')
                  .replace('www.', '')
                  .replace(prefix, ''),
                {emitEvent: false}
              );
            }
          } else {
            if (this._regexp.test(<string>value)) {
              this.form.setValue(`https://${value}`);
            }
          }
        })
      );
    }
  }

  ngOnInit() {
    if (!this.prefix) {
      this.form.addValidators([this._urlValidator()]);
    }
  }

  private _urlValidator(): ValidatorFn {
    const isValidUrlFn = (control: AbstractControl): ValidationErrors | null => {
      const url = control.value;

      if (!url) {
        return null;
      }

      if (!isValidUrl(url)) {
        return {linkInvalid: true};
      }

      return null;
    };

    return isValidUrlFn;
  }
}
