import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {AutofocusModule} from '@aztrix/components/autofocus';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {CountrySelectorModalComponent} from './country-selector-modal.component';
import {HelperPipesModule} from '@aztrix/helpers';

@NgModule({
  declarations: [CountrySelectorModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconsModule,
    HelperPipesModule,
    TranslatePipe,
    AutofocusModule,
  ],
  exports: [CountrySelectorModalComponent],
})
export class CountrySelectorModalModule {}
