<ng-template #errorsTemplate>
  <ax-property-error
    class="errors"
    [form]="form"
    [label]="customLabel === '' ? undefined : customLabel"
  ></ax-property-error>
</ng-template>

<ax-value-edit
  [form]="valueControl"
  [type]="valueType"
  [name]="getName()"
  [label]="customLabel === '' ? (type | propertyTypeLabel) : customLabel"
  [placeholder]="placeholder"
  [icon]="showIcon ? _icon : undefined"
  [hint]="hint"
  [autofocus]="!!autofocus"
  [required]="required"
  [autocompleteAttr]="autocompleteAttr || defaultAutocompleteAttr"
  [matAutocomplete]="matAutocomplete"
  [errorsTemplate]="showErrors ? errorsTemplate : undefined"
  [prefix]="prefix"
  [postfix]="postfix"
  [readonly]="readonly"
  (focused)="focused.emit()"
>
  <ng-content></ng-content>
</ax-value-edit>
