import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {IconsModule} from '@aztrix/icons';

import {ImageSourceComponent} from './image-source.component';

@NgModule({
  declarations: [ImageSourceComponent],
  imports: [CommonModule, IconsModule],
  exports: [ImageSourceComponent],
})
export class ImageSourceModule {}
