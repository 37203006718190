import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {AutofocusModule} from '@aztrix/components/autofocus';
import {ButtonModule} from '@aztrix/components/button';
import {ClearButtonModule} from '@aztrix/components/clear-button';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {ValueEditComponent} from './value-edit.component';
import {BooleanEditComponent} from './value-edits/boolean-edit/boolean-edit.component';
import {DateEditModule} from './value-edits/date-edit/date-edit.module';
import {PhoneEditComponent} from './value-edits/phone-edit/phone-edit.component';
import {RichTextEditModule} from './value-edits/rich-text-edit/rich-text-edit.module';
import {TextEditComponent} from './value-edits/text-edit/text-edit.component';
import {TextareaEditComponent} from './value-edits/textarea-edit/textarea-edit.component';
import {UrlEditComponent} from './value-edits/url-edit/url-edit.component';

@NgModule({
  declarations: [
    ValueEditComponent,
    BooleanEditComponent,
    TextEditComponent,
    TextareaEditComponent,
    UrlEditComponent,
    PhoneEditComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TranslatePipe,
    IconsModule,
    ButtonModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    ClearButtonModule,
    RichTextEditModule,
    DateEditModule,
    AutofocusModule,
  ],
  exports: [ValueEditComponent, DateEditModule],
})
export class ValueEditModule {}
