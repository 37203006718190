import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ButtonModule} from '@aztrix/components/button';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {ClearButtonComponent} from './clear-button.component';

@NgModule({
  declarations: [ClearButtonComponent],
  imports: [CommonModule, ButtonModule, IconsModule, TranslatePipe],
  exports: [ClearButtonComponent],
})
export class ClearButtonModule {}
