import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';

import {InfoModalComponent} from './info-modal.component';

@NgModule({
  declarations: [InfoModalComponent],
  imports: [CommonModule],
})
export class InfoModalModule {}
