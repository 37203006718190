import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {FILE_TYPES} from '@aztrix/components/file-value-edit';
import {subPropertyOfType} from '@aztrix/helpers';
import {MutableProperty, Property, PropertyType} from '@aztrix/models';
import {combineLatest, Subscription} from 'rxjs';
import {startWith} from 'rxjs/operators';

import {AbstractPropertyEdit} from '../abstract-property-edit';

@Component({
  selector: 'ax-file-edit',
  templateUrl: './file-edit.component.html',
  styleUrls: ['./file-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileEditComponent
  extends AbstractPropertyEdit
  implements OnInit, OnChanges, OnDestroy
{
  @Input() acceptTypes: string[] = FILE_TYPES;
  @Input() maxFileSize: number;
  @Input() uploadLink: string;
  @Input() preview = false;
  @Input() previewUrl: string;
  @Input() demo = false;

  @HostBinding('class.showIcon') get showIconClass() {
    return this.showIcon;
  }

  fileControl = new UntypedFormControl();
  nameControl = new UntypedFormControl();

  formSubscription = new Subscription();

  override ngOnInit() {
    super.ngOnInit();

    combineLatest([this.nameControl.valueChanges, this.fileControl.valueChanges]).subscribe(
      ([name, file]) => {
        const property = this.form.value;
        const fileNameProperty = subPropertyOfType(property, PropertyType.FILE_NAME);
        const fileKeyProperty = subPropertyOfType(property, PropertyType.FILE_KEY);

        if (fileNameProperty?.value !== name || fileKeyProperty?.value !== file?.id) {
          (<MutableProperty>fileNameProperty).value = name;
          (<MutableProperty>fileKeyProperty).value = file?.id;
          this.form.patchValue({...this.form.value});
        }
      }
    );
  }

  override ngOnChanges(changes: SimpleChanges) {
    if (changes.form && this.form) {
      this.formSubscription.unsubscribe();
      this.formSubscription = this.form.valueChanges
        .pipe(startWith(this.form.value))
        .subscribe((property: Property) => {
          const fileNameProperty = subPropertyOfType(property, PropertyType.FILE_NAME);
          const fileKeyProperty = subPropertyOfType(property, PropertyType.FILE_KEY);

          if (this.nameControl.value?.id !== fileNameProperty?.value) {
            this.nameControl.setValue(fileNameProperty?.value);
          }

          if (this.fileControl.value?.id !== fileKeyProperty?.value) {
            this.fileControl.setValue({
              ...this.fileControl.value,
              id: fileKeyProperty?.value,
            });
          }
        });
    }

    this.registerOnDisabledChange((isDisabled) => {
      if (isDisabled) {
        this.fileControl.disable({emitEvent: false});
        this.nameControl.disable({emitEvent: false});
      } else {
        this.fileControl.enable({emitEvent: false});
        this.nameControl.enable({emitEvent: false});
      }
    });

    super.ngOnChanges(changes);
  }

  override ngOnDestroy() {
    this.formSubscription.unsubscribe();
    super.ngOnDestroy();
  }

  get label() {
    const typeLabel = `property.${this.form?.value.type}.label`;
    return this.customLabel === '' ? typeLabel : this.customLabel;
  }
}
