import {HttpClient, HttpHeaders} from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {UrlTransformerService} from '@aztrix/environment';
import {isOverflowing} from '@aztrix/helpers';
import {Configuration} from '@aztrix/sdk';
import {ReplaySubject} from 'rxjs';
import {first, map, mergeMap, shareReplay, startWith} from 'rxjs/operators';

const MAX_FILE_SIZE = 10000000;
export const FILE_TYPES = ['pdf', 'doc', 'docx', 'xls', 'pages', 'numbers'];

@Component({
  selector: 'ax-file-value-edit',
  templateUrl: 'file-value-edit.component.html',
  styleUrls: ['file-value-edit.component.scss'],
})
export class FileValueEditComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fileControl: FormControl;
  @Input() nameControl?: FormControl;
  @Input() label: string;
  @Input() hint?: string;
  @Input() acceptTypes: string[] = FILE_TYPES;
  @Input() maxFileSize = MAX_FILE_SIZE;
  @Input() uploadLink: string;
  @Input() required = false;
  @Input() preview = false;
  @Input() previewUrl: string;
  @Input() demo = false;

  @Output() clearedValue = new EventEmitter<boolean>();
  @Output() uploadedFile = new EventEmitter<boolean>();

  @ViewChild('fileSelect') fileSelect: ElementRef;
  @ViewChild('hintElement') hintElement: ElementRef;

  uploading = false;
  error = false;
  collapsed = false;
  overflowing = false;

  fileControl$ = new ReplaySubject<AbstractControl>(1);

  key$ = this.fileControl$.pipe(
    mergeMap((fileControl) => fileControl.valueChanges.pipe(startWith(fileControl.value))),
    map((value) => {
      if (value) {
        return value.id;
      } else {
        return null;
      }
    }),
    shareReplay(1)
  );

  source$ = this.key$.pipe(map((key) => this.previewUrl + key));

  demoUrlObject: string;

  constructor(
    private _http: HttpClient,
    private _urlTransformer: UrlTransformerService,
    private _sanitizer: DomSanitizer,
    private _changeDetector: ChangeDetectorRef,
    @Optional() private _configuration: Configuration
  ) {}

  ngOnInit() {
    if (!this.fileControl) {
      throw Error('FileValueEditComponent should have a predefined FormControl as input property');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.maxFileSize) {
      if (this.maxFileSize > MAX_FILE_SIZE) {
        console.error(
          `input maxFileSize may not be larger than the backend limit (${MAX_FILE_SIZE})`
        );
        this.maxFileSize = MAX_FILE_SIZE;
      }
      if (!this.maxFileSize || this.maxFileSize <= 0) {
        this.maxFileSize = MAX_FILE_SIZE;
      }
    }

    if ((changes.nameControl || changes.acceptTypes || changes.maxFileSize) && this.nameControl) {
      if (!this.acceptTypes.length) {
        this.acceptTypes = FILE_TYPES;
      }
      this.nameControl.setValidators([
        () => (this.error ? {'file-upload-failed': {}} : null),
        this._fileTypeValidator(this.acceptTypes, this.types),
        this._maxFileSizeExceeded(this.maxFileSize),
      ]);

      if (this.required) {
        this.nameControl.addValidators([Validators.required]);
      }
    }

    if (changes.fileControl && this.fileControl) {
      this.fileControl$.next(this.fileControl);
    }
  }

  ngOnDestroy() {
    if (this.demoUrlObject) {
      URL.revokeObjectURL(this.demoUrlObject);
    }
  }

  selectFile() {
    if (!this.uploading) {
      this.fileSelect.nativeElement.click();
    }
  }

  fileSelected(event: Event): void {
    const file = ((<HTMLInputElement>event.target).files || [])[0];
    if (!file) {
      return;
    }

    this.fileControl.setValue({file});
    this.nameControl?.setValue(file.name);
    this.error = false;
    this._changeDetector.detectChanges();
    this.nameControl?.markAsTouched();
    this.nameControl?.updateValueAndValidity();

    if (this.fileControl.invalid || this.nameControl?.invalid) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    this.uploading = true;
    this.fileControl.updateValueAndValidity();

    if (this.demo) {
      this.fileControl.setValue({id: '0', file});
      this.uploading = false;

      if (this.demoUrlObject) {
        URL.revokeObjectURL(this.demoUrlObject);
      }
      this.demoUrlObject = URL.createObjectURL(file);
    } else {
      const transformedUrl = this._urlTransformer.transformUrl(this.uploadLink);

      let headers = new HttpHeaders();
      if (this._configuration.credentials) {
        const authToken = this._configuration.lookupCredential('AuthToken');
        if (authToken) {
          headers = headers.set('AuthToken', authToken);
        }
      }
      this._http
        .post(transformedUrl, formData, {
          observe: 'body',
          responseType: 'text',
          headers,
        })
        .pipe(first())
        .subscribe({
          next: (id) => {
            this.fileControl.setValue({id, file});
          },
          error: () => {
            this.uploading = false;
            this.error = true;
            this._changeDetector.detectChanges();
            this.fileControl.setValue({});
            this.fileControl.updateValueAndValidity();
            this.nameControl?.setValue(undefined);
            this.fileSelect.nativeElement.value = '';
            this.nameControl?.updateValueAndValidity();
          },
          complete: () => {
            this.uploading = false;
            this.uploadedFile.next(true);
            this.nameControl?.updateValueAndValidity();
          },
        });
    }
  }

  clearValue(event: MouseEvent) {
    event.preventDefault();
    this.fileControl.setValue(undefined);
    this.nameControl?.setValue(undefined);
    this.fileSelect.nativeElement.value = '';
    if (!this.nameControl?.value && this.required) {
      this.nameControl?.setErrors({required: true});
    } else {
      this.nameControl?.setErrors(null);
    }

    this.clearedValue.next(true);
  }

  get fileSizeError() {
    return this.nameControl?.getError('max-file-size-exceeded');
  }

  get fileTypeError() {
    return this.nameControl?.getError('incorrect-file-type');
  }

  get fileUploadFailed() {
    return this.nameControl?.getError('file-upload-failed');
  }

  get requiredError() {
    return this.nameControl?.getError('required');
  }

  get types() {
    return this.acceptTypes.map((t) => '.' + t).join(', ');
  }

  get accept() {
    return this.acceptTypes.map((t) => '.' + t).join(',');
  }

  get empty() {
    return !this.fileControl?.value?.id;
  }

  get demoUrl() {
    return this._sanitizer.bypassSecurityTrustUrl(this.demoUrlObject);
  }

  get hasError() {
    return this.nameControl?.touched && this.nameControl?.invalid;
  }

  toByteNotation(size: number): string {
    return size > 500000 ? Math.round(size / 100000) / 10 + ' MB' : Math.round(size / 1000) + ' kB';
  }

  private _maxFileSizeExceeded(maxFileSize: number): ValidatorFn {
    const fileControl = this.fileControl;
    const maxFileSizeExceededFn = (): ValidationErrors | null => {
      const file = fileControl?.value?.file;
      return !file || file.size < maxFileSize
        ? null
        : {'max-file-size-exceeded': {size: file.size, max: maxFileSize}};
    };

    return maxFileSizeExceededFn;
  }

  private _fileTypeValidator(types: string[], formattedTypes: string): ValidatorFn {
    const customFormImageTypesFn = (control: AbstractControl): ValidationErrors | null => {
      const fileName: string = control.value;

      if (!fileName) {
        return null;
      }

      const type = fileName.substring(fileName.lastIndexOf('.') + 1);
      if (types.includes(type.toLowerCase())) {
        return null;
      }

      return {'incorrect-file-type': {type, expectedTypes: formattedTypes}};
    };

    return customFormImageTypesFn;
  }

  isOverflowing() {
    if (!this.overflowing) {
      this.overflowing = isOverflowing(this.hintElement);
      return this.overflowing;
    }

    return this.overflowing;
  }
}
