<mat-form-field appearance="outline" class="c-input" style="width: 100%">
  <mat-select
    [formControl]="form"
    [placeholder]="form.value ? '' : ('label.language' | translate)"
    (openedChange)="openChanged($event)"
  >
    <mat-option>
      <ngx-mat-select-search
        [formControl]="formFilterControl"
        [placeholderLabel]="'label.search' | translate"
        [noEntriesFoundLabel]="'search.results.absence' | translate"
      >
        <ax-icon class="icon" name="close" ngxMatSelectSearchClear></ax-icon>
      </ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngFor="let result of searchResults$ | async | slice: 0:(opened ? undefined : 5)"
      [value]="result.languageCode"
    >
      <div class="option-wrapper">
        <ax-icon class="icon" [name]="result.languageCode"></ax-icon>
        <span>{{ result.languageCode | languageCodeLabel }}</span>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
