import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ClearButtonModule} from '@aztrix/components/clear-button';
import {IconsModule} from '@aztrix/icons';

import {DateEditComponent} from './date-edit.component';

@NgModule({
  declarations: [DateEditComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ClearButtonModule,
    MatDatepickerModule,
    IconsModule,
  ],
  exports: [DateEditComponent],
})
export class DateEditModule {}
