<ax-value-edit
  *ngIf="formType === PropertyType.DOCUMENT"
  [form]="documentNameValueControl"
  type="TEXT"
  [label]="'label.name' | translate"
></ax-value-edit>

<ax-file-value-edit
  [fileControl]="fileControl"
  [nameControl]="fileNameValueControl"
  [label]="'property.' + formType + '.label' | translate"
  uploadLink="r/fileUpload/uploadProfileDocument"
  [required]="true"
  [acceptTypes]="['pdf']"
  (clearedValue)="clearFileValue()"
  (uploadedFile)="setFileValue()"
></ax-file-value-edit>
