import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {
  CountryCodeLabelPipe,
  CountryCodeRepresentation,
  CountrySearchPipe,
  fuzzySort,
} from '@aztrix/helpers';
import {TranslateService} from '@aztrix/translate';
import {map, Observable, of, startWith} from 'rxjs';

@Component({
  selector: 'ax-country-select-search',
  templateUrl: './country-select-search.component.html',
  styleUrls: ['./country-select-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountrySelectSearchComponent {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() form = new UntypedFormControl();
  @Input() prefixIcon: boolean;
  @Input() appearance: 'fill' | 'outline' = 'outline';

  private _countrySearchPipe = new CountrySearchPipe(this._translate);

  countryCodes$ = this._translate.events$.pipe(
    startWith(undefined),
    map(() => CountrySearchPipe.allCountryCodeRepsSorted(this._translate, true))
  );

  searchCallback$ = (
    options: CountryCodeRepresentation[],
    query: string
  ): Observable<(CountryCodeRepresentation | undefined)[]> => {
    const searchOptions = options.map((countryCode) => ({
      code: countryCode.countryCode,
      label: this._translate.instant(
        CountryCodeLabelPipe.countryCodeLabel(countryCode.countryCode)
      ),
    }));

    return of(
      fuzzySort(searchOptions, query, ['code', 'label']).map((result) =>
        options.find((option) => option.countryCode === result.code)
      )
    );
  };

  constructor(private _translate: TranslateService) {}

  valueCallback(value: CountryCodeRepresentation): string {
    return value?.countryCode;
  }

  searchCallback(options: CountryCodeRepresentation[], query: string) {
    return of(
      this._countrySearchPipe.transform(
        query,
        true,
        options.map((o) => o.countryCode)
      )
    );
  }
}
