import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MatomoService} from '@aztrix/helpers';
import {CommunicationType} from '@aztrix/models';

import {AbstractPropertyValueViewComponent} from '../abstract-property-value-view.component';

@Component({
  selector: 'ax-social-value-view',
  templateUrl: 'social-value-view.component.html',
  styleUrls: ['../abstract-property-value-view.component.scss', 'social-value-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialValueViewComponent extends AbstractPropertyValueViewComponent {
  @Input() label: string;
  CommunicationType = CommunicationType;

  constructor(private _matomo: MatomoService) {
    super();
  }

  click(event: Event) {
    if (!this.clickable) {
      event.preventDefault();
    }
    this._matomo.trackEvent('Social value', 'click', this.property?.type?.toLowerCase());
  }
}
