<div class="modal-content-wrapper">
  <div class="c-modal__section">
    <div class="image-crop" *ngIf="imageChangedEvent">
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [output]="'base64'"
        [maintainAspectRatio]="true"
        [resizeToWidth]="250"
        [roundCropper]="isIndividual"
        (imageCropped)="imageCropped($event)"
      ></image-cropper>
    </div>
    <div class="h-button-wrapper">
      <button axButton (click)="save()" color="primary" fullWidth outline>
        {{ 'label.upload' | translate }}
      </button>
    </div>
  </div>
</div>
