import {Directive, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {FormControl} from '@angular/forms';

@Directive()
export abstract class AbstractSelectSearch {
  @Input() form: FormControl;
  @Input() multiple? = false;
  @Input() label: string;
  @Input() canAddNew: boolean;
  @Input() errorsTemplate: TemplateRef<unknown>;
  @Input() disabled = false;
  @Input() valueCallback?: (option: unknown) => unknown;
  @Output() addNewValue = new EventEmitter<void>();
}
