<div quill-editor-toolbar>
  <span class="ql-formats">
    <span class="ql-formats">
      <select class="ql-font" title="Font"></select>
      <select class="ql-header" title="Heading">
        <option value="1">Heading 1</option>
        <option value="2">Heading 2</option>
        <option value="3">Heading 3</option>
        <option value="4">Heading 4</option>
        <option value="5">Heading 5</option>
        <option value="6">Heading 6</option>
        <option value="">Normal</option>
      </select>
      <select class="ql-size" title="Size">
        <option selected>Default</option>
        <option value="8px">8px</option>
        <option value="9px">9px</option>
        <option value="10px">10px</option>
        <option value="12px">12px</option>
        <option value="14px">14px</option>
        <option value="16px">16px</option>
        <option value="20px">20px</option>
        <option value="24px">24px</option>
        <option value="32px">32px</option>
      </select>
    </span>

    <span class="ql-formats">
      <select class="ql-color" title="Color"></select>
      <select class="ql-background" title="Background color"></select>
    </span>

    <span class="ql-formats">
      <button class="ql-script" value="sub" title="Sub"></button>
      <button class="ql-script" value="super" title="Super"></button>
    </span>

    <span class="ql-formats">
      <select class="ql-align" title="Alignment">
        <option selected></option>
        <option value="center"></option>
        <option value="right"></option>
        <option value="justify"></option>
      </select>
    </span>

    <span class="ql-formats">
      <button type="button" class="ql-bold" title="Bold"></button>
      <button type="button" class="ql-italic" title="Italic"></button>
      <button type="button" class="ql-underline" title="Underline"></button>
      <button type="button" class="ql-strike" title="Strike"></button>
    </span>

    <span class="ql-formats">
      <button type="button" class="ql-blockquote" title="Blockquote"></button>
      <button type="button" class="ql-code-block" title="Code block"></button>
      <button type="button" class="ql-link" title="Link"></button>
    </span>

    <span class="ql-formats">
      <button type="button" class="ql-list" value="ordered" title="Numbered list"></button>
      <button type="button" class="ql-list" value="bullet" title="Bullet list"></button>
    </span>

    <span class="ql-formats">
      <button type="button" class="ql-indent" value="-1" title="Indent -1"></button>
      <button type="button" class="ql-indent" value="+1" title="Indent +1"></button>
    </span>

    <span class="ql-formats">
      <ng-content></ng-content>
    </span>

    <span class="ql-formats">
      <button type="button" class="ql-clean" title="Clean selected styling"></button>
    </span>
  </span>
</div>
<div quill-editor-element></div>
