<button
  axButton
  icon-button
  type="button"
  (click)="clearValue()"
  [disabled]="form.disabled"
  [ariaLabel]="'label.clear' | translate"
  tabindex="-1"
>
  <ax-icon class="icon" name="close"></ax-icon>
</button>
