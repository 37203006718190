import {ChangeDetectionStrategy, Component} from '@angular/core';
import {PropertyType} from '@aztrix/models';

import {AbstractPropertyValueViewComponent} from '../abstract-property-value-view.component';

@Component({
  selector: 'ax-legalid-value-view',
  templateUrl: 'legalid-value-view.component.html',
  styleUrls: [
    '../abstract-property-value-view.component.scss',
    'legalid-value-view.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegalidValueViewComponent extends AbstractPropertyValueViewComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  PropertyType = PropertyType;
}
