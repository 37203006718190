import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {
  getPrefixForPropertyType,
  getPropertyTypeForPrefixedValue,
  PropertyFormControl,
} from '@aztrix/helpers';
import {Property} from '@aztrix/models';
import {ProfilePropertyRepresentation, PropertyRepresentation} from '@aztrix/sdk';
import {ControlsOf, FormGroup} from '@ngneat/reactive-forms';
import {startWith, Subscription} from 'rxjs';

import {AbstractPropertyEdit} from '../abstract-property-edit';

@Component({
  templateUrl: './social-edit.component.html',
  selector: 'ax-social-edit',
  styleUrls: ['social-edit.component.scss', '../show-icon.scss'],
})
export class SocialEditComponent extends AbstractPropertyEdit implements OnChanges {
  @Input() matAutocomplete: MatAutocomplete;

  socialEditForm: FormGroup<ControlsOf<Property | ProfilePropertyRepresentation>> | null = null;

  private _subscriptions = new Subscription();

  override ngOnChanges(changes: SimpleChanges) {
    if (changes.form && this.form) {
      this.socialEditForm = PropertyFormControl.create(this.form.value);

      this._subscriptions = new Subscription();
      this._subscriptions.add(
        this.socialEditForm?.valueChanges
          .pipe(startWith(this.socialEditForm.value))
          .subscribe((property) => {
            if (property.value) {
              let value = property.value;
              const prefix = getPrefixForPropertyType(property.type);
              if (prefix) {
                value = `https://${prefix}${property.value}`;
              }

              if (
                value !== this.form.get('value')?.value &&
                property.type === this.form.get('type')?.value
              ) {
                this.form.get('value')?.setValue(value);
                this.form.markAllAsDirty();
              }
            } else if (this.form.get('value')?.value !== null) {
              this.form.get('value')?.setValue(null);
            }
            if (getPrefixForPropertyType(property.type)) {
              this.socialEditForm?.get('value')?.setErrors(this.form.get('value').errors);
            }
          })
      );

      this._subscriptions.add(
        this.form.valueChanges.subscribe((property: Property | ProfilePropertyRepresentation) => {
          const value = this._removePrefix(property);

          if (
            this.socialEditForm?.get('value')?.value !== value ||
            this.socialEditForm?.get('type')?.value !== property.type
          ) {
            this.socialEditForm
              ?.get('type')
              ?.setValue(<ProfilePropertyRepresentation.TypeEnum>property.type, {emitEvent: false});
            this.socialEditForm?.get('value')?.setValue(value);
          }
        })
      );

      this._subscriptions.add(
        this.form.touch$.pipe(startWith(this.form.touched)).subscribe((touched: boolean) => {
          if (touched) {
            if (this.socialEditForm?.untouched) {
              this.socialEditForm.markAllAsTouched();
            }
          } else {
            if (this.socialEditForm?.get('value')?.touched) {
              this.socialEditForm.get('value')?.markAsUntouched();
            }
          }
        })
      );

      this._subscriptions.add(
        this.socialEditForm
          .get('value')
          ?.touch$.pipe(startWith(this.socialEditForm.get('value')?.touched))
          .subscribe((touched) => {
            if (touched) {
              if (this.form.untouched) {
                this.form.markAllAsTouched();
              }
            } else {
              if (this.form.get('value').touched) {
                this.form.get('value').markAsUntouched();
              }
            }
          })
      );
    }
  }

  private _removePrefix(property: Property | PropertyRepresentation): string | undefined {
    const type = property.type || getPropertyTypeForPrefixedValue(property.value);
    const prefix = getPrefixForPropertyType(type);
    if (!prefix) {
      return property.value;
    }

    let intermediate = property.value;
    if (property.value?.indexOf('https://') === 0) {
      intermediate = intermediate?.substring(8);
    }

    if (intermediate?.indexOf(prefix) === 0) {
      intermediate = intermediate.substring(prefix.length);
    }
    return intermediate;
  }
}
