<div
  class="m-snackbar__wrapper"
  *ngIf="!isSnackbarAlreadyClosed"
  [class.is-active]="openSubject$ | async"
>
  <div [ngClass]="backgroundColorClass" class="m-snackbar">
    <div class="container m-snackbar__content">
      <ax-icon
        class="icon"
        [ngClass]="{'m-info-icon': icon === 'information-variant'}"
        *ngIf="icon"
        [name]="icon"
      ></ax-icon>
      <ng-content></ng-content>

      <button axButton *ngIf="dismissible" class="m-snackbar__close" icon-button>
        <ax-icon class="icon" (click)="close()" name="close"></ax-icon>
      </button>
    </div>
  </div>
</div>
