import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {ClearButtonModule} from '@aztrix/components/clear-button';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';

import {CategorySelectSearchComponent} from './category-select-search.component';
import {HelperPipesModule} from '@aztrix/helpers';

@NgModule({
  declarations: [CategorySelectSearchComponent],
  imports: [
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    IconsModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    HelperPipesModule,
    TranslatePipe,
    ClearButtonModule,
  ],
  exports: [CategorySelectSearchComponent],
})
export class CategorySelectSearchModule {}
