import {ChangeDetectionStrategy, Component, HostBinding} from '@angular/core';

import {AbstractPropertyEdit} from '../abstract-property-edit';

@Component({
  selector: 'ax-gender-edit',
  templateUrl: './gender-edit.component.html',
  styleUrls: ['./gender-edit.component.scss', '../show-icon.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenderEditComponent extends AbstractPropertyEdit {
  @HostBinding('class.c-property-edit') propertyEditClass = true;
  @HostBinding('class.showIcon') get showIconClass() {
    return this.showIcon;
  }
}
