<ax-snackbar style="margin-bottom: 1rem" *ngIf="error" color="red" [dismissible]="false">
  {{ 'modal.avatar.error' | translate }}
</ax-snackbar>
<div class="current-avatar">
  <ax-avatar
    [source]="avatarURL"
    [type]="type"
    [round]="type === AvatarType.INDIVIDUAL || type === AvatarType.ALIST"
    [rounded]="type === AvatarType.GROUP"
  ></ax-avatar>
</div>
<div class="h-button-wrapper h-button-wrapper--center">
  <button axButton *ngIf="canDelete" (click)="delete()" color="danger" outline>
    {{ 'label.delete' | translate }}
  </button>
  <label class="file-button" for="file">
    {{ 'modal.avatar.upload' | translate | uppercase }}
    <input
      #fileInput
      [attr.name]="name"
      type="file"
      id="file"
      (change)="fileSelected($event)"
      accept="image/*"
    />
  </label>
</div>
