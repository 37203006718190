import {Component} from '@angular/core';
import {subPropertyOfType, TomtomService} from '@aztrix/helpers';
import {Property, PropertyType, TomTomIdxType} from '@aztrix/models';
import {PropertyRepresentation} from '@aztrix/sdk';
import {BehaviorSubject, map, ReplaySubject, Subject} from 'rxjs';

import {AutocompletionComponent} from '../autocompletion.component';

@Component({
  selector: 'ax-zip-autocompletion',
  templateUrl: './zip-autocompletion.component.html',
})
export class ZipAutocompletionComponent extends AutocompletionComponent {
  results$: Subject<(Property | PropertyRepresentation)[]> = new ReplaySubject(1);
  loading$ = new BehaviorSubject(false);

  constructor(private _tomTom: TomtomService) {
    super();
  }

  autocomplete({query, country}: {query: string; country: string}) {
    this.loading$.next(true);
    if (!query) {
      this.loading$.next(false);
      this.results$.next([]);
      return;
    }

    if (typeof query !== 'string') {
      return;
    }

    this._tomTom
      .search(query, country, [TomTomIdxType.STREET], 1)
      .pipe(map((results) => results.map((r) => subPropertyOfType(r, PropertyType.ZIP))))
      .subscribe({
        next: (results) => {
          this.results$.next(<(Property | PropertyRepresentation)[]>results.filter((r) => !!r));
        },
        error: () => {
          this.results$.next([]);
          this.loading$.next(false);
        },
        complete: () => {
          this.loading$.next(false);
        },
      });
  }
}
