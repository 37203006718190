<ax-icon
  *ngIf="showIcon"
  class="icon type-icon"
  [name]="PropertyType.ADDRESS | propertyIcon"
></ax-icon>

<div>
  <mat-form-field
    class="c-input remove-subtext"
    [ngClass]="{readonly: readonly}"
    appearance="outline"
    style="display: block"
    floatLabel="always"
  >
    <mat-label
      >{{ customLabel === '' ? (form.getRawValue() | propertyTypeLabel) : customLabel
      }}<span *ngIf="required">&nbsp;*</span></mat-label
    >
    <input
      [name]="getName()"
      matInput
      #input
      class="input"
      [formControl]="addressControl"
      type="text"
      axAutofocus
      [autofocus]="!!autofocus"
      autocomplete="no-autofill"
      [matAutocomplete]="auto.matAutocomplete"
      [placeholder]="'label.search' | translate"
      [readonly]="readonly"
      (blur)="onBlur()"
    />
    <button
      axButton
      type="button"
      icon-button
      matSuffix
      aria-label="Clear"
      [disabled]="form.disabled"
      (click)="clearValue($event)"
      *ngIf="!addressControl.disabled && addressControl.value"
    >
      <ax-icon class="icon" name="close"></ax-icon>
    </button>
    <ax-address-autocompletion
      #auto
      [properties]="(addressControl.value !== '' ? autocompleteProperties : undefined) || []"
      [propertyType]="form.value?.type"
      [showManualOption]="showManualAddressOption"
      (didAutocomplete)="autocompleteAddress($event)"
    ></ax-address-autocompletion>
  </mat-form-field>
  <ng-container *ngIf="open">
    <ax-zip-autocompletion #autoZip></ax-zip-autocompletion>
    <div class="address-line" *ngFor="let addressLines of addressLineProperties$ | async">
      <div
        *ngFor="let addressLineProperty of addressLines"
        class="address-property"
        [ngClass]="bootstrapClass(addressLineProperty)"
      >
        <ng-container *ngIf="addressLineProperty.control as control">
          <ax-default-edit
            [name]="getName()"
            *ngIf="
              control.value.type !== PropertyType.COUNTRY &&
              (more || addressLineProperty.dummy || control.value.value)
            "
            [form]="control"
            [showErrors]="false"
            [required]="!!addressLineProperty.dummy?.required"
            [autocompleteAttr]="addressAutocompleteLabelByType(control.value.type)"
            [customLabel]="addressLabelByType(control.value.type) | translate"
            [matAutocomplete]="
              control.value.type === PropertyType.ZIP ? autoZip.matAutocomplete : undefined
            "
            [readonly]="readonly"
            (focused)="focused(control)"
          ></ax-default-edit>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ax-country-edit
    class="address-country"
    [name]="getName()"
    [form]="countryControl$ | async"
    [readonly]="readonly"
    (countryUpdated)="clearValue()"
  ></ax-country-edit>

  <button
    *ngIf="open && type === PropertyType.ADDRESS && (moreIsRedundant$ | async) === false"
    axButton
    type="button"
    (click)="more = !more"
  >
    {{ (more ? 'label.less' : 'label.more') | translate }}
  </button>
  <ax-property-errors [controls]="propertyControls"></ax-property-errors>
  <mat-hint
    [ngClass]="{collapsed}"
    (click)="collapsed = !collapsed"
    class="hint collapsible"
    align="end"
    #hintElement
    *ngIf="hintVisible"
  >
    <div>
      <ax-icon *ngIf="isOverflowing()" class="icon" name="chevron-down"></ax-icon>
    </div>
    <span [ngClass]="{overflowing: isOverflowing()}">{{ hint }}</span>
  </mat-hint>
</div>
