import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ButtonModule} from '@aztrix/components/button';
import {ImagePreviewButtonModule} from '@aztrix/components/image-preview-button';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {FileValueEditComponent} from './file-value-edit.component';

@NgModule({
  declarations: [FileValueEditComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    IconsModule,
    TranslatePipe,
    ButtonModule,
    ImagePreviewButtonModule,
  ],
  exports: [FileValueEditComponent],
})
export class FileValueEditModule {}
