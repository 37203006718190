import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatSelect} from '@angular/material/select';
import {fuzzySort} from '@aztrix/helpers';
import {TranslateService} from '@aztrix/translate';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'ax-language-select-search',
  templateUrl: './language-select-search.component.html',
  styleUrls: ['./language-select-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectSearchComponent {
  @Input() form = new FormControl<any>('');
  @Output() openedChange = new EventEmitter<boolean>();
  formFilterControl = new FormControl<any>('');
  opened = false;

  languages = this._languages();

  searchResults$ = this.formFilterControl.valueChanges.pipe(
    startWith(''),
    map((query) => fuzzySort(this.languages, query, ['label']))
  );

  @ViewChild(MatSelect, {static: true}) select: MatSelect;

  constructor(private _translate: TranslateService) {}

  openChanged(event: boolean) {
    this.opened = event;
    this.openedChange.emit(this.opened);
  }

  private _languages(exclude: any[] = []): {languageCode: string; label: string}[] {
    return this._translate
      .getLangs()
      .filter((lang) => !exclude.includes(lang))
      .map((languageCode) => ({
        languageCode,
        label: this._translate.instant(`languageCode.${languageCode}.label`),
      }));
  }
}
