<ng-container *ngFor="let dayRangesDays of dayRanges; first as first; last as last">
  <ng-container *ngFor="let day of dayRangesDays; first as firstDay; last as lastDay">
    <span *ngIf="firstDay || lastDay">
      <ng-container *ngIf="!firstDay && dayRangesDays.length > 2"> - </ng-container>
      <ng-container *ngIf="!firstDay && dayRangesDays.length <= 2">, </ng-container>
      <ng-container>{{ day | formatDay: false : (activeLanguage$ | async) }}</ng-container>
    </span>
  </ng-container>
  <span *ngIf="!last">, </span>
</ng-container>
