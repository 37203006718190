import {Component, Input} from '@angular/core';
import {fuzzySort, getLatestLanguage} from '@aztrix/helpers';
import {Proposal} from '@aztrix/models';
import {Observable, of} from 'rxjs';

import {AbstractSelectSearch} from '../abstract-select-search';

@Component({
  selector: 'ax-proposal-select-search',
  templateUrl: './proposal-select-search.component.html',
})
export class ProposalSelectSearchComponent extends AbstractSelectSearch {
  @Input() proposals: Proposal[];
  @Input() languageCode: string;

  searchCallback$ = (options: Proposal[], query: string): Observable<(Proposal | undefined)[]> => {
    const searchOptions = options.map((proposal) => ({
      id: proposal.id,
      name: getLatestLanguage(proposal, this.languageCode)?.name,
    }));

    return of(
      fuzzySort(searchOptions, query, ['name']).map((result) =>
        options.find((option) => option.id === result.id)
      )
    );
  };
}
