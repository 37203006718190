import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {OverlayService} from '@aztrix/components/overlay';
import {avatarUrl, groupIconClass, isDeflatedProfile, isProfile} from '@aztrix/helpers';
import {DeflatedProfile, Group, Profile} from '@aztrix/models';
import {ProfileRepresentation} from '@aztrix/sdk';

import {AvatarEditComponent} from '../avatar-edit/avatar-edit.component';
import {AvatarType} from '../avatar-type';
import {BadgeIconType} from '../badge-icon-type';

@Component({
  selector: 'ax-avatar-by-profile',
  templateUrl: 'avatar-by-profile.component.html',
  styleUrls: ['avatar-by-profile.component.scss'],
})
export class AvatarByProfileComponent implements OnChanges {
  @Input() profile: Profile | ProfileRepresentation | DeflatedProfile | Group | undefined | null;
  @Input() edit = false;
  @Input() badge?: BadgeIconType | null;
  @Input() badgeColor?: string | null;
  @Input() border = true;
  @Output() update = new EventEmitter<string | undefined>();

  source: string | undefined;
  type = AvatarType.INDIVIDUAL;
  fallback: string;

  icon: string;
  iconColor: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  AvatarType = AvatarType;

  constructor(
    private _overlay: OverlayService,
    private _elementRef: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.profile && this.profile) {
      if (isDeflatedProfile(this.profile)) {
        this.source = avatarUrl(this.profile);
        this.type = this.profile.profileContext
          ? AvatarType[this.profile.profileContext]
          : AvatarType.INDIVIDUAL;
        this.fallback = this._fallbackAvatarURL(this.source, this.type);
      } else if (isProfile(this.profile)) {
        this.source = avatarUrl(this.profile);
        this.type = this.profile.profileContext
          ? AvatarType[this.profile.profileContext]
          : AvatarType.INDIVIDUAL;
        this.fallback = this._fallbackAvatarURL(this.source, this.type);
      } else {
        this.source = (<Group>this.profile).avatar;
        this.type = AvatarType.GROUP;
        this.fallback = this._fallbackAvatarURL(this.source, this.type);

        this.icon = groupIconClass(<Group>this.profile);
        this.iconColor = this._iconColor();
      }
    }
  }

  openEditModal(): void {
    this._overlay.createModal(this._elementRef, AvatarEditComponent, {
      title: 'modal.avatar.title',
      init: (avatarEditModal) => {
        avatarEditModal.avatarURL = this.source || undefined;
        avatarEditModal.update = (base64: string | undefined) => {
          this.update.emit(base64);
          this.source = base64;
        };
        avatarEditModal.canDelete = !!this.source;
        avatarEditModal.delete = () => {
          this.update.emit(undefined);
          this.source = undefined;
        };
      },
    });
  }

  private _fallbackAvatarURL(source: string, type: string): string {
    if (type === AvatarType.ORGANISATION) {
      return `assets/images/avatar/default/default_avatar_organisation${
        source ? '' : '_placeholder'
      }.png`;
    }
    if (type === AvatarType.GROUP) {
      return `assets/images/avatar/default/default_avatar_group${source ? '' : '_placeholder'}.png`;
    }
    return `assets/images/avatar/default/default_avatar_individual${
      source ? '' : '_placeholder'
    }.png`;
  }

  private _iconColor(): string {
    if (isProfile(this.profile)) {
      return 'avatar-bg';
    } else {
      this.profile = <Group>this.profile;
      if (this.profile.importProvider === 'FACEBOOK') {
        return 'avatar-bg--facebook';
      }
      if (this.profile.importProvider === 'TWITTER') {
        return 'avatar-bg--twitter';
      }
      if (this.profile.importProvider === 'YAHOO') {
        return 'avatar-bg--yahoo';
      }
      if (this.profile.importProvider === 'MICROSOFT') {
        return 'avatar-bg--microsoft';
      }
      if (this.profile.importProvider === 'GOOGLE') {
        return 'avatar-bg--google';
      }
      return 'green-bg';
    }
  }
}
