import {Directive, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';

@Directive()
export abstract class AutocompletionComponent {
  @Input() disabled = false;
  @Output() didAutocomplete = new EventEmitter<any>();
  @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete;

  public open(): void {
    this.autocomplete('');
  }

  public abstract autocomplete(value: any): void;

  public selectedResult(event: MatAutocompleteSelectedEvent): void {
    this.didAutocomplete.emit(event.option.value);
  }
}
