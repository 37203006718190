<div class="page-value-view">
  <ax-row-cell-view class="row-cell-view">
    <div class="row-cell-view-left">
      <div class="avatar-wrapper">
        <ax-image-source class="page-icon" [source]="undefined">
          <ax-icon class="icon" [name]="templateIcon"></ax-icon>
        </ax-image-source>
      </div>
    </div>
    <div class="row-cell-view-middle">
      <span *ngIf="page" class="property-value">{{ page | displayName }}</span>
    </div>
    <div class="row-cell-view-right">
      <ng-content></ng-content>
    </div>
  </ax-row-cell-view>
</div>
