import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import {FormControl, ValidationErrors} from '@angular/forms';
import {InfoModalComponent} from '@aztrix/components/info-modal';
import {OverlayService} from '@aztrix/components/overlay';
import {
  createRangeForm,
  createRangesForm,
  DAYS_OF_WEEK,
  equals,
  isAlwaysOpen,
  OpeningDates,
  OpeningHoursParser,
  OpeningHoursSerializer,
  PropertyFormControl,
  TimeValidators,
} from '@aztrix/helpers';
import {Locale} from '@aztrix/models';
import {PropertyRepresentation} from '@aztrix/sdk';
import {FormArray, FormGroup} from '@ngneat/reactive-forms';
import {BehaviorSubject, of, Subscription} from 'rxjs';
import {first, startWith} from 'rxjs/operators';

import {AbstractPropertyEdit} from '../abstract-property-edit';
import {DatesDaysSelectComponent} from './dates-days-select/dates-days-select.component';
import {LOCALE} from '@aztrix/translate';

const ALWAYS_OPEN_HOURS =
  'Mo 00:00-23:59;Tu 00:00-23:59;We 00:00-23:59;Th 00:00-23:59;Fr 00:00-23:59;Sa 00:00-23:59;Su 00:00-23:59';
const DEFAULT_OPENING_HOURS =
  'Mo 09:00-17:00;Tu 09:00-17:00;We 09:00-17:00;Th 09:00-17:00;Fr 09:00-17:00;';

export const CLOSED_HOURS = 'Mo off;Tu off;We off;Th off;Fr off;Sa off;Su off';

@Component({
  selector: 'ax-opening-hours-edit',
  templateUrl: 'opening-hours-edit.component.html',
  styleUrls: ['opening-hours-edit.component.scss'],
})
export class OpeningHoursEditComponent
  extends AbstractPropertyEdit
  implements OnInit, OnChanges, OnDestroy
{
  @HostBinding('class.c-property-edit') propertyEditClass = true;
  @Input() isProposalSubscribeForm = false;

  openingDates = new FormArray<any>([]);

  subscriptions = new Subscription();
  formSubscription = new Subscription();

  alwaysOpenForm = new FormControl(false);
  cleared = false;

  private _disableValueChanges = false;
  private _disableOverride = false;

  constructor(
    private _overlay: OverlayService,
    private _changeDetector: ChangeDetectorRef,
    private _elementRef: ElementRef,
    @Inject(LOCALE)
    private _locale$: BehaviorSubject<Locale>
  ) {
    super();
    this._addDayControls(DAYS_OF_WEEK);
  }

  override ngOnInit() {
    super.ngOnInit();

    this.subscriptions.add(
      this.openingDates.valueChanges.subscribe((value) => {
        if (
          !this._disableValueChanges &&
          OpeningHoursSerializer.serialize(value) !== this.form.value.value
        ) {
          PropertyFormControl.setValue(this.form, {
            ...this.form.value,
            value: OpeningHoursSerializer.serialize(value),
          });
          this.markAsDirty();
        }
      })
    );

    this.subscriptions.add(
      this.alwaysOpenForm.valueChanges.subscribe((alwaysOpen) => {
        if (this.valueControl.enabled) {
          if (alwaysOpen) {
            this._disableOverride = true;
            this.openingDates.disable();
            if (this.valueControl.value !== ALWAYS_OPEN_HOURS) {
              this.valueControl.setValue(ALWAYS_OPEN_HOURS);
              this.markAsDirty();
            }
          } else {
            this._disableOverride = false;
            this.openingDates.enable();
            if (this.valueControl.value !== DEFAULT_OPENING_HOURS) {
              this.valueControl.setValue(DEFAULT_OPENING_HOURS);
              this.markAsDirty();
            }
          }

          this._changeDetector.markForCheck();
        }
      })
    );

    this.subscriptions.add(
      this.openingDates.touch$.subscribe((touched) => {
        if (touched) {
          this.form.markAsTouched();
        }
      })
    );

    this.valueControl.setAsyncValidators(() => of(this._getErrors(this.openingDates)));

    this.openingDates.valueChanges.pipe(first()).subscribe((value) => {
      if (isAlwaysOpen(value)) {
        this.alwaysOpenForm.setValue(true);
      }
    });
  }

  override ngOnChanges(changes: SimpleChanges) {
    if (changes.form && this.form) {
      this.formSubscription.unsubscribe();
      this.formSubscription = this.form.valueChanges
        .pipe(startWith(this.form.value))
        .subscribe((property: PropertyRepresentation) => {
          if (property && property.value) {
            const serialised = OpeningHoursSerializer.serialize(this.openingDates.value);
            if (property.value !== serialised) {
              let parsed = OpeningHoursParser.parse(property.value);

              parsed = parsed.map((dayRange) => ({
                ...dayRange,
                collapsed: true,
              }));

              this._addMissingControls(parsed);

              this.openingDates.setValue(parsed);
            }
          }

          if (this.form.touched && this.openingDates.untouched) {
            this.openingDates.markAllAsTouched();
          }
        });
    }

    this.registerOnDisabledChange((isDisabled) => {
      if (isDisabled || this._disableOverride) {
        this.openingDates.disable({emitEvent: false});
      } else {
        this.openingDates.enable({emitEvent: false});
      }
    });

    super.ngOnChanges(changes);
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.formSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  openHelpModal(help: TemplateRef<unknown>) {
    this._overlay.createModal(this._elementRef, InfoModalComponent, {
      title: 'property.OPENING_HOURS.label.info',
      init: (modal) => {
        modal.content = help;
      },
    });
  }

  addSpecial() {
    this._overlay.createModal(this._elementRef, DatesDaysSelectComponent, {
      title: 'opening-hours.special',
      init: (modal) => {
        modal.cancel.subscribe(() => {
          this._overlay.closeModal();
        });
        modal.create.subscribe(() => {
          this._overlay.closeModal();
          this._addDayControls(modal.days, modal.dateRange, modal.text);
          this._changeDetector.markForCheck();
        });
      },
    });
  }

  editSpecial(dateForm: any) {
    this._overlay.createModal(this._elementRef, DatesDaysSelectComponent, {
      title: 'label.edit',
      init: (modal) => {
        const dates = dateForm.controls.dates.value;
        const days = dateForm.controls.openingDays.value;

        modal.editMode = true;
        modal.days = days.map((d: any) => d.day);
        modal.dateRange = dates;
        modal.text = days[0].text;
        modal.open = days.some((d: any) => !!d.open);

        modal.cancel.subscribe(() => {
          this._overlay.closeModal();
        });

        modal.edit.subscribe(() => {
          this._overlay.closeModal();
          this._editDayControls(modal.days, dates, modal.dateRange, modal.text, modal.open);
          this._changeDetector.detectChanges();
        });

        modal.delete.subscribe(() => {
          this._overlay.closeModal();

          const index = this.openingDates.controls.findIndex((dr) => equals(dr.value.dates, dates));
          this.openingDates.removeAt(index);

          this._changeDetector.detectChanges();
        });
      },
    });
  }

  get specialOpeningHoursEnabled(): boolean {
    return (
      this.openingDates.enabled &&
      (!this.isProposalSubscribeForm ||
        (this.openingDates.controls.length > 1 && this.openingDates.controls[0].value !== null))
    );
  }

  private _editDayControls(
    days: readonly string[],
    previousDates: Date[],
    currentDates: Date[],
    text: string,
    open = true
  ) {
    const dayRangeControl = <FormGroup<any>>(
      this.openingDates.controls.find((dr) => equals(dr.value.dates, previousDates))
    );
    dayRangeControl.get('dates').setValue(currentDates);
    dayRangeControl.get('collapsed').setValue(false);

    const openingDays = <FormArray<any>>dayRangeControl.get('openingDays');
    const openingDayControls = openingDays.controls;

    for (const index of Object.keys(openingDayControls)) {
      const indexNumber = parseInt(index, 10);
      if (!days.includes(openingDayControls[indexNumber].value.day)) {
        openingDays.removeAt(indexNumber);
      }
    }

    for (const day of days) {
      const dayControl = <FormGroup<any>>openingDayControls.find((c) => c.value.day === day);
      if (dayControl) {
        dayControl.get('open').setValue(open);
        dayControl.get('text').setValue(text);
      } else {
        this._addDayControl(day, currentDates, text, open);
      }
    }
  }

  private _addDayControls(days: readonly string[], dates: Date[] = [], text?: string) {
    for (const day of days) {
      this._addDayControl(day, dates, text);
    }
  }

  private _getDayRange(dates: Date[]) {
    return this.openingDates.controls.find((dr) => equals(dr.value.dates, dates));
  }

  private _addDayControl(day: string, dates: Date[] = [], text?: string, open = false) {
    let dayRangeControl = <FormGroup<any>>this._getDayRange(dates);

    if (!dayRangeControl) {
      dayRangeControl = this._createDayRangeControl(dates);
      this.openingDates.push(dayRangeControl);
    }

    const openingDayControl = this._createDayControl(day, text, open);
    (<FormArray<any>>dayRangeControl.get('openingDays')).push(openingDayControl);
  }

  private _createDayRangeControl(dates: Date[] = []): FormGroup<any> {
    const datesControl = new FormControl();
    datesControl.setValue(dates);

    const openingDaysControl = new FormArray(
      [],
      TimeValidators.validTimeRangesInBetweenDates(this._locale$.value)
    );
    const dayRangeControl = new FormGroup({
      dates: datesControl,
      openingDays: openingDaysControl,
      collapsed: new FormControl(true),
    });

    return dayRangeControl;
  }

  private _createDayControl(day: string, text?: string, open = false): FormGroup<any> {
    const group = new FormGroup({
      day: new FormControl(day),
      open: new FormControl(open),
      ranges: new FormControl(),
      text: new FormControl(text),
    });

    group.setControl('ranges', createRangesForm(this._locale$.value));

    return group;
  }

  private _addMissingControls(openingDates: OpeningDates[]) {
    this._disableValueChanges = true;
    for (const openingDateIndex of Object.keys(openingDates)) {
      const openingDateIndexNumber = parseInt(openingDateIndex, 10);
      const dates = openingDates[openingDateIndexNumber].dates;
      const openingDays = openingDates[openingDateIndexNumber].openingDays;
      let openingDate = <FormGroup<any>>this.openingDates.at(parseInt(openingDateIndex, 10));

      if (!openingDate) {
        openingDate = this._createDayRangeControl(dates);
        this.openingDates.push(openingDate);
      }

      const openingDaysForm = <FormArray<any>>openingDate.get('openingDays');
      if (openingDaysForm) {
        for (const index of Object.keys(openingDays)) {
          const indexNumber = parseInt(index, 10);
          const {day, ranges, text} = openingDays[indexNumber];
          let openingDayControl = <FormGroup<any>>openingDaysForm.at(parseInt(index, 10));

          if (!openingDayControl) {
            openingDayControl = this._createDayControl(day, text);
            openingDaysForm.push(openingDayControl);
          }

          const groupDayRanges = <FormArray<any>>openingDayControl.get('ranges');
          while (ranges?.length > groupDayRanges.length) {
            groupDayRanges.push(createRangeForm());
          }

          while (ranges?.length < groupDayRanges.length) {
            groupDayRanges.removeAt(groupDayRanges.length - 1);
          }
        }
      }
    }

    this._disableValueChanges = false;
  }

  private _getErrors(
    form: FormControl<any> | FormGroup<any> | FormArray<any>
  ): ValidationErrors | null {
    if (form.errors || !('controls' in form)) {
      return form.errors;
    }

    for (const control of Object.values<any>(form['controls'])) {
      const errors = this._getErrors(control);
      if (errors) {
        return errors;
      }
    }
    return null;
  }

  clear() {
    if (this.alwaysOpenForm.value) {
      this.alwaysOpenForm.setValue(false);
    }

    let parsed = OpeningHoursParser.parse('Mo off;Tu off;We off;Th off;Fr off;Sa off;Su off');

    parsed = parsed.map((dayRange) => ({
      ...dayRange,
      collapsed: true,
    }));

    this._addMissingControls(parsed);

    this.openingDates.setValue(parsed);

    this.valueControl.setValue(undefined);
    this.form.markAsUntouched();
  }
}
