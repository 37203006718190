import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {QUILL_CONFIG_TOKEN, QuillConfig, QuillModule} from 'ngx-quill';

import {AztrixQuillButtonDirective} from './quill-button.directive';
import {AztrixQuillComponent} from './quill.component';

// Temporary use this quill config to fix the extra empty line in the editor on every save
const quillConfig: QuillConfig = {
  scrollingContainer: 'html',
  modules: {
    clipboard: {
      matchVisual: false,
    },
    toolbar: [
      [
        {
          size: [false, '8px', '9px', '10px', '12px', '14px', '16px', '20px', '24px', '32px'],
        },
      ],
    ],
  },
};

@NgModule({
  declarations: [AztrixQuillComponent, AztrixQuillButtonDirective],
  exports: [AztrixQuillComponent, AztrixQuillButtonDirective],
  imports: [FormsModule, ReactiveFormsModule, QuillModule, MatFormFieldModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: QUILL_CONFIG_TOKEN,
      useValue: quillConfig,
    },
  ],
})
export class AztrixQuillModule {}
