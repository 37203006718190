import {ChangeDetectorRef, Component, Inject, Input, Optional} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {CategoryLabelPipe, METADATA} from '@aztrix/helpers';
import {LANGUAGE_OVERRIDE, TranslateService} from '@aztrix/translate';
import {BehaviorSubject, map, startWith} from 'rxjs';

@Component({
  selector: 'ax-category-select-search',
  templateUrl: './category-select-search.component.html',
  styleUrls: ['./category-select-search.component.scss'],
})
export class CategorySelectSearchComponent {
  @Input() form = new UntypedFormControl();
  @Input() forProperty = false;
  @Input() hint?: string;
  @Input() label: string;
  @Input() required = false;
  @Input() readonly = false;

  formFilterControl = new UntypedFormControl('');
  private _categoryPipe = new CategoryLabelPipe(
    this._translate,
    this._changeDetector,
    this._languageOverride$
  );

  private _categories = ['ALL'].concat(METADATA.categories);

  categoryResults$ = this.formFilterControl.valueChanges.pipe(
    startWith(this.formFilterControl.value.toLowerCase()),
    map((query) =>
      this._categories.filter((category) =>
        this._categoryPipe.transform(category).toLowerCase().includes(query.toLowerCase())
      )
    )
  );

  constructor(
    private _translate: TranslateService,
    private _changeDetector: ChangeDetectorRef,
    @Optional()
    @Inject(LANGUAGE_OVERRIDE)
    private _languageOverride$?: BehaviorSubject<string | undefined>
  ) {}
}
