<ng-template #option let-result>
  <ax-group-cell-view [group]="result"></ax-group-cell-view>
</ng-template>
<ng-template #selectedViewTemplate let-result>
  <span>
    {{ result | displayName }}
  </span>
</ng-template>

<ax-select-search
  [form]="form"
  [options]="groups"
  [placeholder]="(multiple ? 'label.groups' : 'label.group') | translate"
  [optionTemplate]="option"
  [selectedValueTemplate]="selectedViewTemplate"
  [multiple]="multiple"
  [keys]="['name']"
  [valueCallback]="valueCallback"
  [label]="label ? label : ((multiple ? 'label.groups' : 'label.group') | translate)"
></ax-select-search>
