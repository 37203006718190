import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import {UntypedFormArray} from '@angular/forms';
import {createRangeForm} from '@aztrix/helpers';
import {FormGroup} from '@ngneat/reactive-forms';
import {MediaQuery, ObserveResizeService} from 'angular-container-media-query';
import {isValid} from 'date-fns';
import {of, ReplaySubject, Subscription} from 'rxjs';
import {map, shareReplay, startWith, switchMap} from 'rxjs/operators';

@Component({
  selector: 'ax-day-edit',
  templateUrl: 'day-edit.component.html',
  styleUrls: ['day-edit.component.scss'],
})
export class DayEditComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input() group: FormGroup<any>;
  @Input() name: string;
  @Input() cleared = false;

  @Output() add = new EventEmitter<FormGroup<any>>();
  @Output() remove = new EventEmitter<{group: FormGroup<any>; range: FormGroup<any>}>();

  @MediaQuery('ax-day-range-edit:(min-width: 26.25em)') @HostBinding('class.small') small = false;
  @MediaQuery('ax-day-range-edit:(min-width: 29.375em)') @HostBinding('class.medium') medium =
    false;
  @MediaQuery('ax-day-range-edit:(min-width: 39.375em)') @HostBinding('class.large') large = false;

  constructor(
    private _resize: ObserveResizeService,
    private _elementRef: ElementRef,
    private _changeDetector: ChangeDetectorRef
  ) {}

  subscription = new Subscription();

  private _group$ = new ReplaySubject<FormGroup<any>>(1);

  open$ = this._group$.pipe(
    map((group) => group.get('open')),
    switchMap((control) => {
      if (control) {
        return control.valueChanges.pipe(startWith(control.value));
      } else {
        return of(false);
      }
    }),
    shareReplay(1)
  );

  ngOnChanges(changes: SimpleChanges) {
    if (changes.group && this.group) {
      this._group$.next(this.group);

      this.subscription.unsubscribe();
      this.subscription = this.open$.subscribe((open) => {
        if (open) {
          if (this.rangeControls.length <= 0) {
            this.ranges.push(createRangeForm());
          } else if (this.rangeControls.length === 1) {
            const fromControl = this.rangeControls[0].get('from');
            const toControl = this.rangeControls[0].get('to');
            if (fromControl?.value === null && toControl?.value === null) {
              fromControl.setValue(new Date(1970, 0, 1, 9), {emitEvent: false});
              toControl.setValue(new Date(1970, 0, 1, 17), {emitEvent: false});
            }
          }
        } else {
          this.group.markAsUntouched();
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this._resize.register(this, this._elementRef, this._changeDetector);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  removeable() {
    const range = this.rangeControls[0].value;
    return isValid(range.from) || isValid(range.to) || this.rangeControls.length > 1;
  }

  get days() {
    const day = this.group.get('day')?.value;
    const days = this.group.get('days')?.value;
    return day ? [day] : days || [];
  }

  get ranges(): UntypedFormArray {
    return <UntypedFormArray>this.group?.get('ranges');
  }

  get rangeControls(): FormGroup<any>[] {
    return <FormGroup<any>[]>this.ranges.controls;
  }
}
