<mat-form-field
  [appearance]="forProperty ? 'outline' : 'fill'"
  [ngClass]="{'c-input': forProperty, readonly: readonly}"
  style="width: 100%"
>
  <mat-label *ngIf="forProperty">
    <span *ngIf="label">{{ label }}<span *ngIf="required">*</span></span>
  </mat-label>
  <mat-select
    disableOptionCentering
    [placeholder]="'label.category' | translate"
    [formControl]="form"
  >
    <mat-option>
      <ngx-mat-select-search
        [formControl]="formFilterControl"
        [placeholderLabel]="'label.search' | translate"
        [noEntriesFoundLabel]="'search.results.absence' | translate"
        class="select-search"
      >
        <ax-icon class="icon" name="close" ngxMatSelectSearchClear></ax-icon>
      </ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngFor="let category of categoryResults$ | async"
      [value]="category === 'ALL' ? undefined : category"
    >
      <div class="option-wrapper">
        <ax-icon class="icon" [name]="category | categoryIcon"></ax-icon>
        <span>{{ category | categoryLabel }}</span>
      </div>
    </mat-option>
  </mat-select>
  <mat-hint *ngIf="hint" align="end">
    <span>{{ hint }}</span>
  </mat-hint>
  <mat-error>
    <ax-icon name="alert-circle"></ax-icon>
    {{
      'property.validation.required'
        | translate: {propertyType: 'property.CATEGORY.label' | translate}
    }}
  </mat-error>
  <ax-clear-button
    *ngIf="forProperty && form.value && !readonly"
    [form]="form"
    matSuffix
  ></ax-clear-button>
</mat-form-field>
