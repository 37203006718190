import {Component, Input} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {DateFnsDateAdapter, MAT_DATE_FNS_DATE_FORMATS} from '@aztrix/helpers';

import {ValueEdit} from '../../value-edit';

@Component({
  selector: 'ax-date-edit',
  templateUrl: './date-edit.component.html',
  styleUrls: [
    './date-edit.component.scss',
    '../../../property-edit/show-icon.scss',
    '../../placeholder.scss',
  ],
  providers: [
    {provide: DateAdapter, useClass: DateFnsDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_DATE_FORMATS},
  ],
})
export class DateEditComponent extends ValueEdit {
  @Input() minDate = new Date(1900, 0, 1);
  @Input() maxDate = new Date(2100, 0, 1);
  @Input() startView: 'month' | 'year' | 'multi-year' = 'multi-year';
}
