<ng-template #errorsTemplate>
  <div class="c-property-edit__error" *ngIf="dateControl.errors?.required">
    <ax-icon class="icon" name="alert-circle"></ax-icon>
    <span>{{
      'property.validation.required'
        | translate: {propertyType: 'property.' + form.value.type + '.label' | translate}
    }}</span>
  </div>
</ng-template>

<ax-date-edit
  [form]="dateControl"
  [name]="getName()"
  [icon]="showIcon ? (PropertyType.BIRTHDAY | propertyIcon) : undefined"
  [label]="customLabel === '' ? (form.getRawValue() | propertyTypeLabel) : customLabel"
  [minDate]="minDate"
  [maxDate]="maxDate"
  [startView]="startView"
  [required]="required"
  [hint]="hint"
  [errorsTemplate]="errorsTemplate"
  [readonly]="readonly"
></ax-date-edit>
