import {Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TemplateParser} from '@aztrix/helpers';
import {FormControl} from '@ngneat/reactive-forms';
import Quill from 'quill';
import {startWith, Subscription} from 'rxjs';

import {ValueEdit} from '../../value-edit';

@Component({
  selector: 'ax-rich-text-edit',
  templateUrl: './rich-text-edit.component.html',
  styleUrls: ['rich-text-edit.component.scss', '../../../property-edit/show-icon.scss'],
})
export class RichTextEditComponent extends ValueEdit implements OnInit, OnChanges {
  // https://github.com/angular/angular/issues/20810
  @Output() quillEditorChange = new EventEmitter<any>();

  richTextForm = new FormControl<string>('');

  ngOnInit() {
    this.richTextForm.valueChanges.subscribe((value) => {
      const convertedValue = this._quillButtonsToTemplateVariables(value);
      if (this.form.value !== convertedValue) {
        this.form.setValue(convertedValue);
      }
    });
  }

  override ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.form && this.form) {
      this._subscriptions.unsubscribe();
      this._subscriptions = new Subscription();
      this._subscriptions.add(
        this.form.valueChanges.pipe(startWith(this.form.value)).subscribe((value) => {
          let convertedValue = this._templateVariablesToQuillButtons(<string>value);
          convertedValue = this._removePageStyling(convertedValue);
          if (!this._equalTemplates(this.richTextForm.value, convertedValue)) {
            this.richTextForm.setValue(convertedValue);
          }
        })
      );
    }
  }

  _quillButtonsToTemplateVariables(textTemplate: string): string {
    textTemplate = this._addPageStyling(textTemplate);
    const parentNode = TemplateParser.parentNode(textTemplate);

    for (const templateVariableNode of TemplateParser.nodes(parentNode)) {
      const requestedPropertyId = templateVariableNode.attributes.getNamedItem('data-index')?.value;

      const innerSpan = templateVariableNode.querySelector('span');
      if (innerSpan) {
        innerSpan.innerHTML = `{{${requestedPropertyId}}}`;
      } else {
        templateVariableNode.innerHTML = `{{${requestedPropertyId}}}`;
      }
    }
    return parentNode?.innerHTML ?? '';
  }

  _templateVariablesToQuillButtons(textTemplate: string): string {
    return this._clearTemplateVariables(textTemplate);
  }

  _addPageStyling(textTemplate: string): string {
    const parentNode = TemplateParser.parentNode(textTemplate);
    if (!parentNode) {
      return '';
    }

    const styleElements = parentNode.getElementsByTagName('style');

    const pageStyleElement = styleElements
      ? Object.values(styleElements).find((styleElement) => {
          return styleElement.innerHTML.includes('@page');
        })
      : null;

    if (!pageStyleElement && textTemplate?.length) {
      const style = document.createElement('style');
      style.appendChild(document.createTextNode('@page { margin: 1in; }'));
      parentNode.appendChild(style);
    }
    return parentNode.innerHTML;
  }

  _removePageStyling(textTemplate: string): string {
    const parentNode = TemplateParser.parentNode(textTemplate);
    if (!parentNode) {
      return '';
    }

    const styleElements = parentNode.getElementsByTagName('style');

    const pageStyleElement = styleElements
      ? Object.values(styleElements).find((styleElement) => {
          return styleElement.innerHTML.includes('@page');
        })
      : null;

    if (pageStyleElement) {
      parentNode.removeChild(pageStyleElement);
    }
    return parentNode.innerHTML;
  }

  _equalTemplates(textTemplate1: string, textTemplate2: string) {
    return (
      this._clearTemplateVariables(textTemplate1) === this._clearTemplateVariables(textTemplate2)
    );
  }

  private _clearTemplateVariables(textTemplate: string): string {
    const parentNode = TemplateParser.parentNode(textTemplate);
    for (const templateVariableNode of TemplateParser.nodes(parentNode)) {
      templateVariableNode.innerHTML = '';
    }
    return parentNode?.innerHTML ?? '';
  }

  editorChanged(event: any) {
    this.quillEditorChange.next(<Quill>event);
  }
}
