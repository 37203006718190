import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {IconsModule} from '@aztrix/icons';

import {FileSourceComponent} from './file-source.component';

@NgModule({
  declarations: [FileSourceComponent],
  imports: [CommonModule, IconsModule],
  exports: [FileSourceComponent],
})
export class FileSourceModule {}
