import {Directive, Input} from '@angular/core';
import {Params} from '@angular/router';
import {isVerified, subPropertyOfType, verificationIsRequested} from '@aztrix/helpers';
import {Property, PropertyType} from '@aztrix/models';
import {PropertyRepresentation} from '@aztrix/sdk';

@Directive()
export class AbstractPropertyValueViewComponent {
  @Input() property?: Property;
  @Input() parentProperty?: Property;
  @Input() showVerificationLabel: boolean;
  @Input() clickable: boolean;
  @Input() params: Params | null;

  public verificationColor(): string {
    if (!this.property || isVerified(this.property)) {
      return 'green-text';
    }
    if (verificationIsRequested(this.property)) {
      return 'orange-text';
    }
    return 'red-text';
  }

  propertyOfType(type: PropertyType): Property | PropertyRepresentation | undefined {
    return subPropertyOfType(this.property, type);
  }
}
