import {Component, Input} from '@angular/core';
import {AbstractControl, UntypedFormArray} from '@angular/forms';
import {getPropertyTypeDescription} from '@aztrix/helpers';
import {PropertyType} from '@aztrix/models';
import {ProposalLanguageRepresentation} from '@aztrix/sdk';

@Component({
  selector: 'ax-property-errors',
  templateUrl: 'property-errors.component.html',
  styleUrls: ['property-errors.component.scss'],
})
export class PropertyErrorsComponent {
  @Input() controls: AbstractControl[];
  @Input() proposalLanguage: ProposalLanguageRepresentation;

  propertyType = PropertyType;

  get propertyControls() {
    const allControls = [];
    for (const control of Object.values(this.controls)) {
      allControls.push(control);
      if ('properties' in control.value) {
        const propertiesForm = <UntypedFormArray>control.get('properties');
        for (const propertyControl of propertiesForm.controls) {
          allControls.push(propertyControl);
        }
      }
    }
    return allControls;
  }

  getLabel(control: AbstractControl): string | undefined {
    if (control.value.type === PropertyType.CUSTOM) {
      return this._getCustomLabel(control);
    } else {
      return undefined;
    }
  }

  private _getCustomLabel(control: AbstractControl): string | undefined {
    const errors = Object.values(control.get('value')?.errors || []);
    const error = errors[0];

    if (!error?.requestedPropertyId || !error?.type) {
      return undefined;
    }

    const description = getPropertyTypeDescription(
      this.proposalLanguage,
      error.requestedPropertyId,
      error.type
    );

    return description?.customFieldDescription?.label;
  }
}
