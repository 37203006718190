<mat-autocomplete #autocompletor="matAutocomplete" (optionSelected)="selectedResult($event)">
  <ng-container *ngIf="!disabled">
    <mat-option *ngFor="let country of results$ | async" [value]="country">
      <div class="c-select-option c-select-option--phone">
        <span class="c-select-option__text">
          {{ country['countryCode'] | countryCodeLabel }}
          <span class="c-select-option--phone__prefix">{{ country['phonePrefix'] }}</span>
        </span>
        <ax-icon class="icon" [name]="country['countryCode']"></ax-icon>
      </div>
    </mat-option>
  </ng-container>
</mat-autocomplete>
