import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Params} from '@angular/router';
import {AvatarType} from '@aztrix/components/avatar';
import {Property, PropertyType} from '@aztrix/models';

@Component({
  selector: 'ax-property-value-view',
  templateUrl: 'property-value-view.component.html',
  styleUrls: ['property-value-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyValueViewComponent {
  @Input() property: Property | undefined;
  @Input() showVerificationLabel: boolean;
  @Input() clickable: boolean;
  @Input() isIndividual = false;
  @Input() params: Params | null;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  PropertyType = PropertyType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  AvatarType = AvatarType;
}
