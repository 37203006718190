import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AvatarModule} from '@aztrix/components/avatar';
import {ButtonModule} from '@aztrix/components/button';
import {FileSourceModule} from '@aztrix/components/file-source';
import {ImagePreviewButtonModule} from '@aztrix/components/image-preview-button';
import {ImageSourceModule} from '@aztrix/components/image-source';
import {RowCellViewModule} from '@aztrix/components/row-cell-view';
import {TranslatePipe} from '@aztrix/translate';
import {IconsModule} from '@aztrix/icons';

import {BankaccountValueViewComponent} from './bankaccount-value-view/bankaccount-value-view.component';
import {CategoryValueViewComponent} from './category-value-view/category-value-view.component';
import {AddressValueViewComponent} from './communication-value-view/address-value-view/address-value-view.component';
import {CommunicationValueViewComponent} from './communication-value-view/communication-value-view.component';
import {DefaultValueViewComponent} from './default-value-view/default-value-view.component';
import {DocumentValueViewComponent} from './document-value-view/document-value-view.component';
import {FileValueViewComponent} from './file-value-view/file-value-view.component';
import {ImageValueViewComponent} from './image-value-view/image-value-view.component';
import {LegalidValueViewComponent} from './legalid-value-view/legalid-value-view.component';
import {OpeningHoursViewModule} from './opening-hours-view/opening-hours-view.module';
import {PropertyValueViewComponent} from './property-value-view.component';
import {SocialValueViewComponent} from './social-value-view/social-value-view.component';
import {ValueVerifiedViewModule} from './value-verified-view/value-verified-view.module';
import {HelperPipesModule} from '@aztrix/helpers';

const COMPONENTS = [
  PropertyValueViewComponent,
  AddressValueViewComponent,
  BankaccountValueViewComponent,
  DefaultValueViewComponent,
  SocialValueViewComponent,
  LegalidValueViewComponent,
  FileValueViewComponent,
  ImageValueViewComponent,
  DocumentValueViewComponent,
  CommunicationValueViewComponent,
  CategoryValueViewComponent,
];

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule,
    IconsModule,
    RowCellViewModule,
    HelperPipesModule,
    TranslatePipe,
    AvatarModule,
    ValueVerifiedViewModule,
    OpeningHoursViewModule,
    ImageSourceModule,
    FileSourceModule,
    ButtonModule,
    ImagePreviewButtonModule,
  ],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS, OpeningHoursViewModule],
})
export class PropertyViewModule {}
