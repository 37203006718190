import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {iconNameForPropertyType, isPhoneRelated, isSocialRelated} from '@aztrix/helpers';
import {PropertyType} from '@aztrix/models';

import {AbstractPropertyEdit} from '../abstract-property-edit';

@Component({
  selector: 'ax-default-edit',
  templateUrl: './default-edit.component.html',
  styleUrls: ['./default-edit.component.scss'],
})
export class DefaultEditComponent extends AbstractPropertyEdit {
  @Input() placeholder: string;
  @Input() matAutocomplete?: MatAutocomplete;

  @Output() focused = new EventEmitter<void>();

  get valueType() {
    if (isPhoneRelated(this.type)) {
      return 'PHONE';
    }

    if (isSocialRelated(this.type)) {
      return 'URL';
    }

    switch (this.type) {
      case PropertyType.NOTE:
        return 'TEXT_AREA';
      case PropertyType.EMAIL:
        return 'EMAIL';
      case PropertyType.WEBSITE:
        return 'URL';
      default:
        return 'TEXT';
    }
  }

  get defaultAutocompleteAttr() {
    switch (this.type) {
      case PropertyType.ORGANISATION_NAME:
        return 'organization';
      default:
        return undefined;
    }
  }

  get _icon() {
    if (this.icon) {
      return this.icon;
    } else {
      return iconNameForPropertyType(this.type);
    }
  }
}
