import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslatePipe} from '@aztrix/translate';

import {ValueVerifiedViewModule} from '../value-verified-view/value-verified-view.module';
import {OpeningHoursDateViewComponent} from './opening-hours-date-view/opening-hours-date-view.component';
import {OpeningHoursDayViewComponent} from './opening-hours-day-view/opening-hours-day-view.component';
import {OpeningHoursViewComponent} from './opening-hours-view.component';
import {HelperPipesModule} from '@aztrix/helpers';

@NgModule({
  declarations: [
    OpeningHoursViewComponent,
    OpeningHoursDateViewComponent,
    OpeningHoursDayViewComponent,
  ],
  imports: [CommonModule, HelperPipesModule, TranslatePipe, ValueVerifiedViewModule],
  exports: [OpeningHoursViewComponent, OpeningHoursDateViewComponent, OpeningHoursDayViewComponent],
})
export class OpeningHoursViewModule {}
