import {Component, Input} from '@angular/core';
import {Agreement, PropertyType, Proposal} from '@aztrix/models';

@Component({
  selector: 'ax-agreement-cell-view',
  templateUrl: './agreement-cell-view.component.html',
  styleUrls: ['./agreement-cell-view.component.scss', '../cell-view.scss'],
})
export class AgreementCellViewComponent {
  @Input() proposal: Proposal | undefined;
  @Input() agreement: Agreement;
  @Input() noAvatar = false;

  get agreementProperties() {
    return this.agreement?.agreementData?.agreementProperties || [];
  }

  get firstNameProperty() {
    return this.agreementProperties.find((prop) => prop?.property?.type === PropertyType.FIRST_NAME)
      ?.property;
  }

  get lastNameProperty() {
    return this.agreementProperties.find((prop) => prop?.property?.type === PropertyType.LAST_NAME)
      ?.property;
  }
}
