<mat-form-field class="c-input" appearance="outline">
  <mat-select [(ngModel)]="choice">
    <mat-option value="specific">{{ 'opening-hours.specific-date' | translate }}</mat-option>
    <mat-option value="range">{{ 'opening-hours.date-range' | translate }}</mat-option>
  </mat-select>
</mat-form-field>

<mat-slide-toggle class="check" name="openOrClosed" [formControl]="openControl">
  {{
    openControl.value
      ? ('label.open' | translate | uppercase)
      : ('label.closed' | translate | uppercase)
  }}</mat-slide-toggle
>

<ng-container [ngSwitch]="choice">
  <ng-container *ngSwitchDefault>
    <ax-date-input
      [label]="'opening-hours.specific-date' | translate"
      [control]="dateControl"
      [min]="today"
    ></ax-date-input>
  </ng-container>
  <ng-container *ngSwitchCase="'range'">
    <ax-date-range-select [group]="dateRangeGroup"></ax-date-range-select>
    <ax-days-select [(days)]="days" (daysChange)="daysChange.emit($event)"></ax-days-select>
  </ng-container>
</ng-container>

<mat-form-field class="c-input" appearance="outline">
  <mat-label>{{ 'opening-hours.special.label' | translate }}</mat-label>
  <input matInput autocomplete="off" [formControl]="textForm" />
  <mat-hint align="end">{{ 'opening-hours.special.hint' | translate }}</mat-hint>
</mat-form-field>

<ax-opening-hours-date-view
  [dates]="dateRange"
  [text]="textForm.value"
></ax-opening-hours-date-view>
<ax-opening-hours-day-view *ngIf="choice === 'range'" [days]="days"></ax-opening-hours-day-view>

<div class="h-button-wrapper">
  <button axButton type="button" (click)="cancel.emit()">
    {{ 'label.cancel' | translate }}
  </button>
  <button *ngIf="!editMode" axButton type="button" filled color="primary" (click)="_create()">
    {{ 'label.create' | translate }}
  </button>
  <button *ngIf="editMode" axButton type="button" filled color="danger" (click)="delete.emit()">
    {{ 'label.delete' | translate }}
  </button>
  <button *ngIf="editMode" axButton type="button" filled color="primary" (click)="_edit()">
    {{ 'label.save' | translate }}
  </button>
</div>
